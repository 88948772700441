export class Config {
    current_version: string;
    app_dev: boolean;
    app_produccion: boolean;
    email_de: string;
    email_de_nombre: string;
    server_email: string;
    server_port: number;
    server_email_de: string;
    server_email_password: string;
    casa_moda_lugar: string;
    token_bsale: string;
}