import { Component, OnInit } from '@angular/core';
import { ReportesService } from '../reportes.service';
import { GeneralesService } from '../../generales.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-stock-download',
  templateUrl: './stock-download.component.html',
  styleUrls: ['./stock-download.component.scss']
})
export class StockDownloadComponent implements OnInit {
  idMarca: string = 'N';
  loading: boolean = false;
  perfilUsuario: number = Number(localStorage.getItem('acceso')[0]);
  urlFile: string = '';

  constructor(private servicio: ReportesService, private servicioGeneral: GeneralesService) { }

  ngOnInit(): void {
    if (localStorage.getItem('marca_session')) {
      this.idMarca = localStorage.getItem('marca_session');
    }
  }

  downloadStock() {
    if (this.idMarca === 'N') {
      Swal.fire('¡Sin Marca!', 'No tienes una marca asignada, comunícate con Casa Moda.', 'info');
      return;
    }

    if (this.idMarca === '0' && this.perfilUsuario >= 2) {
      Swal.fire('¡Selecciona una marca!', 'Primero debes tener una marca seleccionada como administrador.', 'info');
      return;
      
    }

    this.loading = true;
    this.loadFileDownload();
  }

  loadFileDownload() {
    const nombreMarca = localStorage.getItem('marca_name');
    this.servicio.getStockByMarca(this.idMarca, nombreMarca)
      .subscribe(resp => {
        this.generateFile(resp);
        this.loading = false;
      }, error => {
        console.error('ERROR al descargar: ', error);
        Swal.fire('¡Error!', 'No se pudo descargar el archivo, intente nuevamente.', 'error');
        this.loading = false;
      }
    );
  }

  generateFile(tiposProductos: any[]) {
    const stockModelExport = [];
    tiposProductos.forEach(producto => {
      const stockMarca = {
        'Marca': producto.TipoDeProducto,
        'Producto': producto.Producto,
        'Variante': producto.Variante,
        'SKU': producto.SKU,
        'Sucursal': producto.Sucursal,
        'Stock': Number(producto.Stock)
      };
      stockModelExport.push(stockMarca);
    });
    this.servicioGeneral.exportAsExcelFile(stockModelExport, 'productos_stock');
  }
}
