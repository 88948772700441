export class Marca {
    id_marca: string;
    nombre: string;
    descripcion: string;
    id_bsale: number;
    direccion: string;
    id_comuna: number;
    id_region: number;
    cantidad_usuarios: number;
    fecha_registro: string;
    url_imagen: string = '/resources/img/logo-circulo-white.jpg';
    activo: number;
}

export class ReceptorTributario {
    id_receptor: number;
    rut: string;
    razon_social: string;
    actividad_economica: string;
    id_marca: string;
    activo: number;
}