import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CantidadVentas, RankingGeneral, VariacionVentas, VentasSucursal, VisitaDay } from 'src/app/models/dashboard.model';
import { environment } from 'src/environments/environment';
import { TopVentasMarcas } from '../../models/dashboard.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private url = environment.API_URL;
  private apiHeaders = {
    "Content-Type": "application/json",
    "access_token": localStorage.getItem('token')
  };

  constructor(private http: HttpClient) { }

  variacionVentasTotales(idMarca: string) {
    const authData = {
      solicitud: 'dashboard',
      metodo: 'variacion_total_venta',
      id_marca: idMarca
    };

    return this.http.post<VariacionVentas[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  totalVentaSucursal(idMarca: string, periodo: string) {
    const authData = {
      solicitud: 'dashboard',
      metodo: 'venta_total_sucursal',
      id_marca: idMarca,
      periodo: periodo
    };

    return this.http.post<VentasSucursal[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  rankingGeneral(idMarca: string, periodo: string) {
    const authData = {
      solicitud: 'dashboard',
      metodo: 'ranking_general',
      id_marca: idMarca,
      periodo: periodo
    };

    return this.http.post<RankingGeneral>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  cantidadVentasSucursal(idMarca: string, periodo: string) {
    const authData = {
      solicitud: 'dashboard',
      metodo: 'cantidad_ventas_sucursal',
      id_marca: idMarca,
      periodo: periodo
    };

    return this.http.post<CantidadVentas[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  // Consultas en modo de Administrador
  getVisitasDay() {
    const authData = {
      solicitud: 'dashboard',
      metodo: 'getVisitasDay'
    };

    return this.http.post<VisitaDay[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  getTopVentasMarcas(periodo: string) {
    const authData = {
      solicitud: 'dashboard',
      metodo: 'getTopVentasMarcas',
      periodo: periodo
    };

    return this.http.post<TopVentasMarcas>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  productosNoVendidos(periodo: string, idMarca: string) {
    const authData = {
      solicitud: 'dashboard',
      metodo: 'getProductosNoVendidos',
      id_marca: idMarca,
      periodo: periodo
    };

    return this.http.post<string[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }
}
