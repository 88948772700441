import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Usuario } from '../../models/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class AccesoService {
  private url = environment.API_URL;
  private apiHeaders = {
    "Content-Type": "application/json"
  };

  constructor(private http: HttpClient) { }

  login(user: Usuario) {
    const authData = {
      solicitud: 'usuario',
      metodo: 'login',
      usuario: {
        email: user.email,
        contrasena: user.contrasena
      }
    };

    return this.http.post<number>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  forgotpassword(correo: string) {
    const authData = {
      solicitud: 'usuario',
      metodo: 'forgotpassword',
      usuario: {
        email: correo
      }
    };

    return this.http.post<number>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  changePassword(contrasena: string, token: string) {
    const authData = {
      solicitud: 'usuario',
      metodo: 'change_password',
      usuario: {
        password: contrasena,
        token: token
      }
    };

    return this.http.post<number>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }
}
