import { Injectable } from '@angular/core';
import { CanActivate, Route, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GeneralesService } from './generales.service';

@Injectable({
  providedIn: 'root'
})
export class AccesoGuard implements CanActivate {

  constructor(private servicio: GeneralesService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.servicio.verificarUsuarioToken().pipe(
      tap( isAuthenticated => {
        if (!isAuthenticated) {
          localStorage.clear();
          this.router.navigate(['/acceso']);
        }
      })
    );
  }
}
