<div id="layoutAuthentication">
    <div id="layoutAuthentication_content">
        <main>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5 animated--fade-in-up">
                        <!-- Basic login form-->
                        <div class="card shadow-lg border-0 rounded-lg mt-5">
                            <div class="card-header bg-acceso-header" align="center">
                                <img src="assets/img/logo-blanco.png" class="animated--fade-in-up lift" alt="Logo Casa Moda" width="250">
                            </div>
                            <div class="card-body">
                                <h3 class="font-weight-light my-2">Iniciar Sesión</h3>
                                <!-- Login form-->
                                <form (ngSubmit)="sendLogin()" [formGroup]="formLogin">
                                    <!-- Form Group (email address)-->
                                    <div class="form-group">
                                        <label class="small mb-1" for="inputEmailAddress">Email</label>
                                        <input class="form-control {{validarCampo('email')}}" id="inputEmailAddress" formControlName="email" type="email" placeholder="Ingrese correo registrado" maxlength="50" />
                                        <div class="invalid-feedback">Ingrese un correo válido</div>
                                    </div>
                                    <!-- Form Group (password)-->
                                    <div class="form-group">
                                        <label class="small mb-1" for="inputPassword">Contraseña</label>
                                        <input class="form-control {{validarCampo('contrasena')}}" id="inputPassword" formControlName="contrasena" type="password" placeholder="Ingrese su contraseña" maxlength="15" />
                                        <div class="invalid-feedback">Mínimo 6 a 15 caracteres</div>
                                    </div>
                                    <!-- Form Group (remember password checkbox)-->
                                    <div class="form-group">
                                        <div class="custom-control custom-checkbox">
                                            <input class="custom-control-input" id="rememberPasswordCheck" type="checkbox" formControlName="rememberUser" />
                                            <label class="custom-control-label" for="rememberPasswordCheck">Mantener sesión activa</label>
                                        </div>
                                    </div>
                                    <!-- Form Group (login box)-->
                                    <div class="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                                        <a class="small clickable text-black" (click)="forgotpassword()">¿Olvidó su contraseña? Recupérala aquí</a>
                                        <button class="btn btn-black rounded-pill lift" [disabled]="standBy">
                                            <span class="mr-2" *ngIf="!standBy">Ingresar</span>
                                            <span class="mr-2" *ngIf="standBy">Cargando</span>
                                            <i class="" [className]="standBy ? 'fas fa-sync fa-spin fa-lg' : 'fas fa-caret-right fa-lg'"></i>
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div class="card-footer text-center bg-acceso-header">
                                <div class="small">
                                    Plataforma Intranet - Casa Moda
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
    <div id="layoutAuthentication_footer">
        <app-footer></app-footer>
    </div>
</div>