import { Component, OnInit } from '@angular/core';
import { Office } from 'src/app/models/listar.model';
import { ReportesService } from '../reportes.service';
import { Variants, Products } from '../../../models/listar.model';
import { GeneralesService } from '../../generales.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss']
})
export class StockComponent implements OnInit {

  loading: boolean = true;
  loadingVariantes: boolean = false;
  loadingBack: boolean = false;
  filterName: string;
  tiposProductos: Products[] = [];
  adicionalTitulo: string;
  lastCode: string[] = ['', '0'];
  perfilUsuario: number = Number(localStorage.getItem('acceso')[0]);

  constructor(private servicio: ReportesService, private servicioGeneral: GeneralesService) { }

  ngOnInit(): void {
    if (localStorage.getItem('marca_session')) {
      const id_marca = localStorage.getItem('marca_session');
      if (id_marca != '') {
        this.loadProductos(id_marca);
      } else {
        this.adicionalTitulo = ': Sin registros en modo Administrador';
        this.loading = false;
      }
    }
  }

  loadProductos(id: string) {
    if (this.perfilUsuario <= 1 && (id == '0' || id == null)) {
      Swal.fire('¡Sin Marca!', 'No tienes una marca asignada, comunícate con Casa Moda.', 'info');
      this.loading = false;
      return;
    }
    this.loadingVariantes = true;
    this.servicio.getAllStockByMarca(id, 0, 0, 40).subscribe(resp => {
      let indice = 0;
      let limite = 40;
      this.tiposProductos = resp;
      this.adicionalTitulo = ': ' + this.tiposProductos.length + ' Productos';
      this.loading = false;
      this.loadingVariantes = false;

      if (resp.length != 0) {
        indice = limite;
        limite = limite;
        this.loadStockProductos(id, 0, indice, limite);
      }
    });
  }

  obtenerVariantesStock(product: Products, openMore: boolean = true) {
    this.setColorDifferent(product.variants);
    if (openMore) {
      product.showMore = !product.showMore;
    }
  }

  getSucursalById(idx: number) {
    const sucursales: Office[] = JSON.parse(localStorage.getItem('sucursales'));
    const sucursal = sucursales.find( ({ id }) => id == idx );
    return sucursal.name;
  }

  setColorDifferent(variants: Variants[]) {
    let valor: string;
    let i: number = 0;
    const colors: string[] = ['black', 'blue', 'indigo', 'pink', 'red', 'orange', 'purple', 'yellow', 'green', 'teal', 'cyan', 'black', 'blue', 'indigo', 'pink', 'red', 'orange', 'purple', 'yellow', 'green', 'teal'];
    variants.forEach(item => {
      if (item.sku != valor) {
        i++;
        valor = item.sku;
      }
      item.color = colors[i];
    });
  }

  descargarExcel() {
    if (this.loadingVariantes) {
      Swal.fire('Cargando Información', 'Estamos cargando la información de stock, al terminar podrá descargar el informe.', 'info');
      return;
    }
    const stockModelExport = [];
    this.tiposProductos.forEach(stock => {
      const temp = (({id_registro, loadingVariants, showMore, ...a}) => a) (stock);
      
      temp.variants.forEach(item => {
        const stockMarca = {
          'N° Referencia': item.id_registro,
          'Producto': temp.nombre_producto,
          'Variante': item.variante,
          'SKU': item.sku,
          'Sucursal': item.nombre_sucursal,
          'Stock': Number(item.stock)
        };
        stockModelExport.push(stockMarca);
      });
    });
    this.servicioGeneral.exportAsExcelFile(stockModelExport, 'productos_stock');
  }

  loadStockProductos(id: string, stock: number, indice: number, limite: number) {
    this.loadingBack = true;
    this.servicio.getAllStockByMarca(id, 0, indice, limite).subscribe(resp => {
      this.tiposProductos = this.tiposProductos.concat(resp);
      
      this.adicionalTitulo = ': ' + this.tiposProductos.length + ' Productos';

      if (resp.length != 0) {
        indice = indice + 40;
        limite = limite;
        this.loadStockProductos(id, 0, indice, limite);
      } else {
        this.loadingBack = false;
      }
    });
  }
}
