import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AgregarService } from '../agregar.service';
import { Usuario } from '../../../../models/usuario.model';
import { Marca } from '../../../../models/marca.model';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailPattern } from 'src/app/pages/validators/validadores';
import { GeneralesService } from 'src/app/pages/generales.service';

@Component({
  selector: 'app-agregar',
  templateUrl: './agregar.component.html',
  styleUrls: ['./agregar.component.scss']
})
export class AgregarComponent implements OnInit {
  loading: boolean = true;

  formUsuario: FormGroup = this.fb.group({
    id_usuario: [],
    nombre: [ , [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
    apellido_paterno: [ , [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
    apellido_materno: [ , [Validators.minLength(3), Validators.maxLength(25)]],
    fecha_registro: [],
    email: [ , [Validators.required, Validators.pattern(emailPattern), Validators.maxLength(100)], [this.servicioGenerale]],
    telefono: [ , [Validators.minLength(7), Validators.maxLength(9)]],
    id_perfil: [1, [Validators.required]],
    perfil: [],
    activo: [],
    contrasena: [ , [Validators.minLength(6), Validators.maxLength(15)]]
  });

  @Output() onDebounce: EventEmitter<string> = new EventEmitter();
  nameMarchaSearch: string = '';
  debouncer: Subject<string> = new Subject();
  showAlertSearch: boolean = false;

  public idUsuario: string;
  public titulo: string = "Nuevo Usuario";
  public botonTitulo: string = "Crear Usuario";
  public marcaSelected: Marca = new Marca();
  public usuarioMarcas: Marca[] = [];
  public searchMarcaName: Marca[] = [];
  loadingForm: boolean = false;
  perfilUsuario: number = Number(localStorage.getItem('acceso')[0]);

  constructor(private routerModule: ActivatedRoute, private servicio: AgregarService, private fb: FormBuilder, private router: Router, private servicioGenerale: GeneralesService) { }

  ngOnInit(): void {
    this.debouncer
    .pipe(debounceTime(300))
    .subscribe(valor => {
      this.showAlertSearch = false;
      if (valor != '') {
        this.searchMarca();
      } else {
        this.searchMarcaName = [];
      }
    });
    this.routerModule.params.subscribe(({id}) => this.idUsuario = id);

    if (Number(this.idUsuario) > 0) {
      localStorage.setItem('edit_user', 'true');
      this.loadUsuarioForm(Number(this.idUsuario));
    } else {
      localStorage.removeItem('edit_user');
      this.loading = false;
    }
  }

  loadUsuarioForm(id: number) {
    this.servicio.getUsuario(id).subscribe(resp => {
      this.servicio.getUsuarioMarcas(id).subscribe(respMarcas => {
        this.usuarioMarcas = respMarcas;
  
        //Cargar títulos y data de Usuario
        this.titulo = 'Editando usuario: ' + resp.nombre + ' ' + resp.apellido_paterno;
        this.botonTitulo = 'Actualizar Usuario';
        this.formUsuario.setValue(resp);
        this.loading = false;
      });
    });
  }

  searchMarca() {
    this.servicio.getMarcaByName(this.nameMarchaSearch).subscribe(resp => {
      this.searchMarcaName = resp.splice(0, 3);
      if (this.searchMarcaName.length == 0) {
        this.showAlertSearch = true;
      }
    });
  }

  teclaPresionada() {
    this.debouncer.next(this.nameMarchaSearch);
  }

  searchMarcaSelected(id: string, name: string) {
    if (id.toString() != '' || name != '') {
      const marcaTemp: Marca = new Marca();
      marcaTemp.id_marca = id;
      marcaTemp.nombre = name;
      this.marcaSelected = marcaTemp;
      this.searchMarcaName = [];
      this.showAlertSearch = false;
    } else {
      Swal.fire('Oops...', '¡Debe seleccionar una marca!', 'error');
    }
  }

  incluirMarcaTemporal() {
    if (this.marcaSelected.nombre != null) {
      this.removeMarcaTemporal(this.marcaSelected.id_marca);
      this.usuarioMarcas.push(this.marcaSelected);
      this.marcaSelected = new Marca();
      this.showAlertSearch = false;
    } else {
      Swal.fire('Oops...', '¡Debe seleccionar una marca!', 'error');
    }
  }

  removeMarcaTemporal(id: string) {
    this.usuarioMarcas = this.usuarioMarcas.filter(({ id_marca }) => id_marca !== id);  
  }

  validarCampo(campo: string) {
    const controlTemp = this.formUsuario.controls[campo];
    if (controlTemp.errors && controlTemp.touched) {
      return 'is-invalid';
    } else if (!controlTemp.errors && controlTemp.touched && controlTemp.value != null) {
      return 'is-valid';
    }
  }

  sendNewUsuario() {
    if (this.formUsuario.invalid) {
      this.formUsuario.markAllAsTouched();
      return;
    }

    this.loadingForm = true;
    let usuarioTemp: Usuario = this.formUsuario.value;
    let tipoRequest: string = 'update';
    let bodyAlert:string = 'Se actualizó correctamente el usuario';
    if (this.titulo == 'Nuevo Usuario') {
      tipoRequest = 'create';
      bodyAlert = 'Se registro el nuevo usuario';
    }
    this.servicio.updateCreateUsuario(tipoRequest, usuarioTemp).subscribe(resp => {
      if (resp >= 1) {
        if (this.titulo == 'Nuevo Usuario') {
          usuarioTemp.id_usuario = resp;
        }
        this.servicio.setMarcasUsuario(this.usuarioMarcas, usuarioTemp.id_usuario).subscribe(respMarca => {
          this.loadingForm = false;
          if (respMarca == 0) {
            Swal.fire('¡Listo!', bodyAlert, 'success');
            if (this.titulo == 'Nuevo Usuario') {
              this.usuarioMarcas = [];
              this.formUsuario.reset();
            } else {
              this.router.navigate(['/listar/usuario']);
            }
          } else {
            Swal.fire('Error', 'No se pudo registrar las marcas, vuelva a intentarlo', 'error');
            //Cargar títulos y data de Usuario
            this.titulo = 'Editando usuario '+usuarioTemp.nombre;
            this.botonTitulo = 'Actualizar Usuario';
            this.loadUsuarioForm(usuarioTemp.id_usuario);
          }
        });
      } else {
        Swal.fire('Error', 'Vuelva a intentarlo más tarde', 'error');
      }
    });
  }

  get emailErrors(): string {
    const errors = this.formUsuario.get('email')?.errors;
    if (errors?.required) {
      return 'Ingrese un correo electrónico';
    } else if(errors?.pattern) {
      return 'Correo invalido';
    } else if(errors?.emailOcupado) {
      return 'Correo ya registrado';
    }
    
    return '';
  }
}
