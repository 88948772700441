import { Component, OnInit } from '@angular/core';
import { Config } from 'src/app/models/config.model';
import { GestionService } from './gestion.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-gestion',
  templateUrl: './gestion.component.html',
  styleUrls: ['./gestion.component.scss']
})
export class GestionComponent implements OnInit {
  loading: boolean = true;
  loadingForm: boolean = false;
  config: Config = new Config;

  constructor(private servicio: GestionService) { }

  ngOnInit(): void {
    this.loadConfiguracion();
  }

  guardarConfiguracion() {
    const config: Config = this.config;
    let version = Number(config.current_version.replace('.', '').replace('.', ''));
    version = version + 1;
    let xVersion = version.toString();
    config.current_version = xVersion[0]+'.'+xVersion[1]+'.'+xVersion[2];
    Swal.fire({
      title: '¿Desea guardar una nueva configuración?',
      text: 'Debe estar seguro de estos cambios, de lo contrario el sistema se verá entorpecido y fallará.',
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      confirmButtonColor: '#000',
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Guardando información...',
          text: 'Estamos guardando la nueva configuración.',
          didOpen: () => {
            Swal.showLoading();
          } 
        })
        this.servicio.saveConfiguracion(this.config).subscribe(resp => {
          if (resp == 1) {
            Swal.fire('¡Listo!', 'Hemos actualizado la configuración de la plataforma.', 'success');
            this.loadConfiguracion();
          } else {
            Swal.fire('¡Oops!', 'Ocurrió un problema al intentar guardar la configuración, inténtelo más tarde.', 'error');
          }
        });
      }
    });
  }

  loadConfiguracion() {
    this.servicio.getConfiguracion().subscribe(resp => {
      this.config = resp;
      this.loading = false;
    });
  }

  loadBackup() {
    Swal.fire({
      title: '¿Desea cargar la configuración de apoyo?',
      text: 'Debe estar seguro de estos cambios, de lo contrario el sistema se verá entorpecido y fallará.',
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      confirmButtonColor: '#000',
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Cargando información...',
          text: 'Estamos leyenda la configuración de apoyo.',
          didOpen: () => {
            Swal.showLoading();
          } 
        })
        this.servicio.getBackupConfig().subscribe(config => {
          this.servicio.saveConfiguracion(config).subscribe(resp => {
            if (resp == 1) {
              Swal.fire('¡Listo!', 'Hemos actualizado la configuración de la plataforma.', 'success');
              this.loadConfiguracion();
            } else {
              Swal.fire('¡Oops!', 'Ocurrió un problema al intentar guardar la configuración, inténtelo más tarde.', 'error');
            }
          });
        });
      }
    });
  }

  cambiarAmbiente(ambiente: string) {
    if (ambiente == 'dev') {
      this.config.app_produccion = !this.config.app_produccion;
    } else {
      this.config.app_dev = !this.config.app_dev;
    }
  }
}
