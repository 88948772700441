export class ListarMarcas {
    id: number;
    name: string;
    isEditable: number;
    state: number;
}

export class ProductTypes {
    count: number;
    nextUrl: string;
    items: Products[] = [];
}
export class Products {
    id_registro: number;
    nombre_producto: string;
    stock: number;
    variants: Variants[] = [];
    loadingVariants: boolean;
    showMore: boolean;
}

export class Variants {
    id_registro: number;
    variante: string;
    sku: string;
    stock: number;
    nombre_sucursal: string;
    color: string = 'black';
}

export class Stock {
    id: number;
    quantity: string;
    quantityAvailable: string;
    quantityReserved: string;
    office: Office;
}

export class Office {
    id: number;
    name: string;
}

export class Documento {
    id_documento_detalle: number;
    id_documento: number;
    tipo_de_movimiento: string;
    id_marca: string;
    sucursal: string;
    sucursal_origen: string;
    vendedor: string;
    cliente: string;
    rut_cliente: string;
    id_tipo_documento: string;
    tipo_documento: string;
    nro_documento: number;
    fecha_venta: string;
    fecha_documento: string;
    lista_de_precios: string;
    tipo_de_producto_servicio: string;
    sku: string;
    producto_servicio: string;
    variante: string;
    otros_atributos: string;
    precio_neto_unitario: number;
    cantidad: number;
    subtotal_impuestos: number;
    subtotal: number;
    metodo_pago: string;
    showMore: boolean;
    loadingDetalle: boolean;
    seleccionado: boolean = false;
}

export class BaseDocumento {
    id_documento_detalle: number;
    id_documento: number;
    tipo_documento: string;
    nro_documento: number;
    fecha_documento: string;
    monto_total: number;
    url_documento: string;
    documentos: Documento;
    showMore: boolean;
    loadingDetalle: boolean;
}