import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Office } from '../models/listar.model';
import { Notificacion, UsuarioSesion } from '../models/usuario.model';
import { Observable, of } from 'rxjs';
import { Marca } from '../models/marca.model';
import { AbstractControl, ValidationErrors, AsyncValidator } from '@angular/forms';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class GeneralesService implements AsyncValidator {
  private url = environment.API_URL;
  private apiHeaders = {
    "Content-Type": "application/json",
    "access_token": localStorage.getItem('token')
  };

  constructor(private http: HttpClient) { }

  loadSucursales() {
    if (localStorage.getItem('sucursales')) {
      return;
    }
    const authData = {
      solicitud: 'bsale',
      metodo: 'sucursales'
    };

    return this.http.post<Office[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).subscribe(resp => {
      const sucursales: Office[] = resp;
      localStorage.setItem('sucursales', JSON.stringify(sucursales));
      return;
    });
  }

  loadUsuarioSesion(token: string) {
    const authData = {
      solicitud: 'usuario',
      metodo: 'load',
      usuario: {
        token
      }
    };

    return this.http.post<UsuarioSesion>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  // Verificar si el token en session es válida
  public verificarUsuarioToken(): Observable<boolean> {
    const usertoken = localStorage.getItem('token');

    if (usertoken == null || usertoken == 'null') {
      return of (false);
    }

    const authData = {
      solicitud: 'usuario',
      metodo: 'verify_token',
      usuario: {
        token: usertoken
      }
    };

    return this.http.post<number | any>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        if (resp > 1) {
          localStorage.setItem('acceso', resp.toString());
          return true;
        }
        if (resp.error == 'Token invalido') {
          return false;
        }
        return false;
      })
    );
  }

  public getNotificacionUsuario(xId: number) {
    const authData = {
      solicitud: 'usuario',
      metodo: 'notificaciones',
      usuario: {
        id_usuario: xId
      }
    };

    return this.http.post<Notificacion[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  public getMarcaByName(name: string) {
    const authData = {
      solicitud: 'marca',
      metodo: 'getAllByName',
      marca: {
        nombre: name
      }
    };

    return this.http.post<Marca[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  public validate(control: AbstractControl): Observable<ValidationErrors | null> {
    const email: string = control.value;

    const authData = {
      solicitud: 'usuario',
      metodo: 'validar_email',
      email
    };

    return this.http.post<Number>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        if (localStorage.getItem('edit_user')) {
          return null;
        } else {
          return (resp === 0) ? null : {emailOcupado : true};
        }
      })
    );
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const myworkbook: XLSX.WorkBook = { Sheets: { 'reporte casa moda': myworksheet }, SheetNames: ['reporte casa moda'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_exported'+ EXCEL_EXTENSION);
    Swal.fire('¡Listo!', 'Comenzará a descargar el archivo', 'success');
  }
}
