import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Visita, Sucursal } from '../../models/visita.model';

@Injectable({
  providedIn: 'root'
})
export class AgendaService {
  private url = environment.API_URL;
  private apiHeaders = {
    "Content-Type": "application/json",
    "access_token": localStorage.getItem('token')
  };

  constructor(private http: HttpClient) { }

  updateCreateVisita(metodo: string, visita: Visita) {
    const authData = {
      solicitud: 'visita',
      metodo,
      visita
    };

    return this.http.post<number>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  getAllVisitas(xId: number, xTipo: string) {
    const authData = {
      solicitud: 'visita',
      metodo: 'getAll',
      visita: {
        id_usuario: xId,
        tipo: xTipo
      }
    };

    return this.http.post<Visita[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  readVisita(xid: number) {
    const authData = {
      solicitud: 'visita',
      metodo: 'read',
      visita: {
        id_visita: xid
      }
    };

    return this.http.post<Visita>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  deleteVisita(xVisita: Visita) {
    const authData = {
      solicitud: 'visita',
      metodo: 'delete',
      visita: xVisita
    };

    return this.http.post<number>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  getHoraDisponible(fecha: string, id_sucursal: string) {
    const authData = {
      solicitud: 'visita',
      metodo: 'getHoraDisponible',
      fecha,
      id_sucursal
    };

    return this.http.post<string[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  getSucursalesDisponibles() {
    const authData = {
      solicitud: 'visita',
      metodo: 'getSucursalesDisponibles'
    };

    return this.http.post<Sucursal[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  cancelarHora(fecha: string, horaInicio: string, horaFin: string, idSucursal: string, idUsuario: number) {
    const authData = {
      solicitud: 'visita',
      metodo: 'cancelarHora',
      fecha,
      hora_inicio: horaInicio,
      hora_fin: horaFin,
      id_sucursal: idSucursal,
      id_usuario: idUsuario
    };

    return this.http.post<number>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }
}
