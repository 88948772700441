<app-inicio NavTitleGlobal="gestion" NavTitle="Configuración">
    <header class="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
        <div class="container-fluid">
            <div class="page-header-content">
                <div class="row align-items-center justify-content-between pt-3">
                    <div class="col-auto mb-3">
                        <h1 class="page-header-title">
                            <div class="page-header-icon">
                                <i class="far fa-cogs"></i>
                            </div>
                            <span class="mr-2">Carga Masiva</span>
                            <button id="btnMostrarReporte" class="btn btn-red btn-sm mr-2" type="button" style="display: none;" data-toggle="modal" data-target="#reporteModal">Reporte</button>
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <!-- Main page content-->
    <div class="container mt-4">
        <app-loading *ngIf="loading"></app-loading>
        <div class="row justify-content-center" *ngIf="!loading">
            <div class="col-xl-6">
                <!-- Account details card-->
                <div class="card card-header-actions mb-4">
                    <div class="card-header">
                        <span>
                            <i class="far fa-upload"></i>
                            Cargar Masiva de Usuarios
                        </span>
                        <div>
                            <a href="https://intranet.casamoda.cl/api/resources/masivos/usuarios.xlsx">
                                <i class="fas fa-file-excel text-green"></i>
                                Descargar Excel Base
                            </a>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="form-row">
                            <div class="form-group col-md-9">
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="inputFileUsuarios" lang="es" (change)="onFileChanged($event, 'users')" accept=".xlsx">
                                    <label class="custom-file-label" for="inputFileUsuarios">{{nameFileLoadUsers}}</label>
                                    <small class="form-text text-muted">Archivo excel (.xlsx) Máximo 5 MB</small>
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <button class="btn btn-black rounded-pill px-4 mr-2 my-1" type="submit" [disabled]="loadingForm" (click)="guardarUsuarios()">
                                    <span *ngIf="!loadingForm">Guardar</span>
                                    <span *ngIf="loadingForm">
                                        <i class="fas fa-sync fa-spin fa-lg"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center" *ngIf="!loading">
            <div class="col-xl-6">
                <!-- Account details card-->
                <div class="card card-header-actions mb-4">
                    <div class="card-header">
                        <span>
                            <i class="far fa-upload"></i>
                            Cargar Masiva de Marcas
                        </span>
                        <div>
                            <a href="https://intranet.casamoda.cl/api/resources/masivos/marcas.xlsx">
                                <i class="fas fa-file-excel text-green"></i>
                                Descargar Excel Base
                            </a>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="form-row">
                            <div class="form-group col-md-9">
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="inputFileMarcas" lang="es" (change)="onFileChanged($event, 'marcas')" accept=".xlsx">
                                    <label class="custom-file-label" for="inputFileMarcas">{{nameFileLoadMarcas}}</label>
                                    <small class="form-text text-muted">Archivo excel (.xlsx) Máximo 5 MB</small>
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <button class="btn btn-black rounded-pill px-4 mr-2 my-1" type="submit" [disabled]="loadingForm">
                                    <span *ngIf="!loadingForm">Guardar</span>
                                    <span *ngIf="loadingForm">
                                        <i class="fas fa-sync fa-spin fa-lg"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Large modal -->
    <div class="modal fade" id="reporteModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header bg-black">
                    <span class="modal-title text-white">
                        <i class="far fa-database"></i>
                        Reporte de Carga Masiva
                    </span>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="list-group list-group-flush small">
                        <div class="list-group-item list-group-item-action" *ngFor="let item of reporteCargaMasiva">
                            <i class="fas fa-times-circle text-red mr-2" *ngIf="item[0] == true"></i>
                            <i class="fas fa-check-circle text-green mr-2" *ngIf="item[0] == false"></i>
                            {{item[1]}}
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-black-cs rounded-pill px-4 mr-2 my-1" type="button" data-dismiss="modal">
                        ¡Listo!
                    </button>
                </div>
            </div>
        </div>
    </div>
</app-inicio>