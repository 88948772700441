import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Config } from 'src/app/models/config.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GestionService {
  private url = environment.API_URL;
  private apiHeaders = {
    "Content-Type": "application/json",
    "access_token": localStorage.getItem('token')
  };

  constructor(private http: HttpClient) { }

  getConfiguracion() {
    const authData = {
      solicitud: 'config'
    };

    return this.http.post<Config>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  getBackupConfig() {
    const authData = {
      solicitud: 'backup_config'
    };

    return this.http.post<Config>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  saveConfiguracion(config: Config) {
    const authData = {
      solicitud: 'save_config',
      config: config
    };

    return this.http.post<number>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  uploadUsuariosMasivo(archivo: any) {
    const authData = {
      solicitud: 'usuario',
      metodo: 'carga_masiva',
      archivo: archivo
    };

    return this.http.post<string[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }
}
