<footer class="footer mt-auto footer-light">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 small">Copyright &copy; Casa Moda 2021</div>
            <div class="col-md-6 text-md-right small">
                <a href="#!">Política de Privacidad</a>
                &middot;
                <a href="#!">Términos &amp; Condiciones</a>
            </div>
        </div>
    </div>
</footer>