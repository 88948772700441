import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ListarMarcas } from 'src/app/models/listar.model';
import { Comuna, Region } from 'src/app/models/usuario.model';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { Marca, ReceptorTributario } from '../../../../models/marca.model';
import { AgregarService } from '../agregar.service';

@Component({
  selector: 'app-agregar-marca',
  templateUrl: './agregarMarca.component.html',
  styleUrls: ['./agregarMarca.component.scss']
})
export class AgregarMarcaComponent implements OnInit {
  loading: boolean = true;
  loadingForm: boolean = false;
  loadingSearchMarca: boolean = false;
  regionesTemporales: Region[] = [];
  comunasTemporales: Comuna[] = [];

  formMarca: FormGroup = this.fb.group({
    id_marca: [],
    nombre: [ , [Validators.required, Validators.minLength(4), Validators.maxLength(30)]],
    id_bsale: [ , [Validators.required, Validators.min(1), Validators.maxLength(8)]],
    id_region: [ 7, [Validators.required, Validators.min(1), Validators.maxLength(8)]],
    id_comuna: [ , [Validators.required, Validators.min(1), Validators.maxLength(8)]],
    direccion: [ , [Validators.required, Validators.minLength(4), Validators.maxLength(40)]],
    descripcion: [ , [Validators.minLength(4), Validators.maxLength(40)]],
    fecha_registro: [],
    activo: [1 , Validators.required],
    url_imagen: ['']
  });

  private url = environment.IMG_URL;
  public imageMarca: string = '/assets/img/no-imagen.png';
  public idMarca: string;
  public titulo: string = 'Nueva Marca';
  public botonTitulo: string = 'Crear Marca';
  selectedFile: any;
  nameFileLoad: any = 'Cargar imagen aquí';
  debouncer: Subject<string> = new Subject();
  public searchMarca: ListarMarcas[] = [];
  public searchMarcaName: ListarMarcas[] = [];
  showAlertSearch: boolean = false;
  receptoresTributarios: ReceptorTributario[] = [];
  tempReceptorTributario: ReceptorTributario = new ReceptorTributario();
  receptorSelected: boolean = false;

  constructor(private routerModule: ActivatedRoute, private fb: FormBuilder, private servicio: AgregarService, private router: Router) { }

  ngOnInit(): void {
    this.loadRegion();
    this.loadComuna(7);
    this.loadMarcasFromExternal();
    this.debouncer
    .pipe(debounceTime(300))
    .subscribe(valor => {
      this.showAlertSearch = false;
      if (valor != '') {
        this.searchMarcaByName(valor);
      } else {
        this.searchMarcaName = [];
      }
    });
    this.routerModule.params.subscribe(({id}) => this.idMarca = id);

    if (this.idMarca != '0' && this.idMarca != null) {
      this.loadMarcaForm(this.idMarca);
    } else {
      this.loading = false;
    }
  }

  onFileChanged(event) {
    const file = event.target.files[0];
    if (file.length === 0)
        return;

    const mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      Swal.fire('Oops...', '¡Formato erróneo de imagen!', 'error');
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file); 
    reader.onload = () => {
      this.nameFileLoad = file.name;
      this.imageMarca = reader.result as string;
      this.selectedFile = {
        fileName: file.name,
        fileType: file.type,
        fileExtension: file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2),
        value: this.imageMarca.split(',')[1]
      };
    }
  }

  loadMarcaForm(id: string) {
    this.servicio.getMarca(id).subscribe(resp => {
      //Cargar títulos y data de Marca
      this.titulo = 'Editando marca: '+resp.nombre;
      this.botonTitulo = 'Actualizar Marca';
      this.imageMarca = (resp.url_imagen) ? this.url+resp.url_imagen : this.imageMarca;
      this.formMarca.setValue(resp);
      this.loadComuna(resp.id_region);
      this.servicio.getReceptoresTributarios(id).subscribe(resp => {
        this.receptoresTributarios = resp;
        this.loading = false;
      });
    });
  }

  validarCampo(campo: string) {
    const controlTemp = this.formMarca.controls[campo];
    if (controlTemp.errors && controlTemp.touched) {
      return 'is-invalid';
    } else if (!controlTemp.errors && controlTemp.touched && controlTemp.value != null) {
      return 'is-valid';
    }
  }

  sendNewMarca() {
    if (this.formMarca.invalid) {
      this.formMarca.markAllAsTouched();
      return;
    }

    this.loadingForm = true;
    let marcaTemp: Marca = this.formMarca.value;
    let tipoRequest: string = 'update';
    let bodyAlert:string = 'Se actualizó correctamente la marca';
    if (this.titulo == 'Nueva Marca') {
      tipoRequest = 'create';
      bodyAlert = 'Se registro la nueva marca';
    }

    // Subir imagen y obtener url
    if (this.selectedFile != null) {
      this.servicio.uploadImagenMarca(this.selectedFile).subscribe(resp => {
        if (resp != '0') {
          marcaTemp.url_imagen = resp;
        } else {
          Swal.fire('Error al cargar imagen', 'Vuelva a intentarlo más tarde o intente con otra', 'error');
          return;
        }
      }, (error) => {
        console.log(error);
      }, () => {
        this.saveMarca(tipoRequest, bodyAlert, marcaTemp);
      });
    } else {
      this.saveMarca(tipoRequest, bodyAlert, marcaTemp);
    }
  }

  private saveMarca(tipoRequest: string, bodyAlert:string, marcaTemp: Marca) {
    this.servicio.updateCreateMarca(tipoRequest, marcaTemp).subscribe(resp => {
      if (resp != 0) {
        this.servicio.createUpdateReceptorTributario(tipoRequest, resp.toString(), this.receptoresTributarios).subscribe(resp => {
          this.loadingForm =  false;
          Swal.fire('¡Listo!', bodyAlert, 'success');
          if (this.titulo == 'Nueva Marca') {
            this.imageMarca = '/assets/img/no-imagen.png';
            this.nameFileLoad = 'Cargue imagen aquí';
            this.formMarca.reset();
          } else {
            this.router.navigate(['/listar/marca']);
          }
        });
      } else {
        Swal.fire('Error', 'Vuelva a intentarlo más tarde', 'error');
      }
    });
  }

  private loadMarcasFromExternal() {
    this.loadingSearchMarca = true;
    this.servicio.getMarcasFromExternal().subscribe(resp => {
      this.searchMarca = resp;
      this.loadingSearchMarca = false;
    });
  }

  teclaPresionada() {
    this.debouncer.next(this.formMarca.controls.nombre.value);
  }

  public searchMarcaByName(nombre: string) {
    this.searchMarcaName = this.searchMarca.filter(item => item.name.toLowerCase().includes(nombre.toLowerCase())).splice(0, 3);
    if (this.searchMarcaName.length <= 0) {
      this.showAlertSearch = true;
    }
  }

  public searchMarcaSelected (marca: ListarMarcas) {
    this.formMarca.controls.nombre.setValue(marca.name);
    this.formMarca.controls.id_bsale.setValue(marca.id);
    this.searchMarcaName = [];
  }

  guardarNuevoReceptor() {
    if (!this.tempReceptorTributario.rut || !this.tempReceptorTributario.razon_social || !this.tempReceptorTributario.actividad_economica) {
      Swal.fire('¡Oops!', 'Verifique que el rut, razón social y actividad económica del receptor tributario sean válidos', 'error');
      return;
    }
    if (this.tempReceptorTributario.rut.length <= 8 || this.tempReceptorTributario.razon_social.length <= 5 || this.tempReceptorTributario.actividad_economica.length < 25) {
      Swal.fire('¡Oops!', 'Verifique que le rut, razón social y actividad económica del receptor tributario sean válidos', 'error');
      return;
    }
    this.tempReceptorTributario.id_receptor = 0;
    this.receptoresTributarios.push(this.tempReceptorTributario);
    this.tempReceptorTributario = new ReceptorTributario();
    document.getElementById('btnCancelarRegistro').click();
  }

  public loadRegion() {
    this.servicio.getAllRegiones().subscribe(resp => {
      this.regionesTemporales = resp;
    });
  }

  private loadComuna(idRegion: number) {
    this.servicio.getComunasByRegion(idRegion).subscribe(resp => {
      this.comunasTemporales = resp;
    });
  }

  selectedReceptorTributario(idReceptor: number) {
    this.receptorSelected = true;
    return;
  }
}
