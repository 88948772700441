export class VariacionVentas {
    periodo: string;
    monto: number;
}

export class VentasSucursal {
    sucursal: string;
    monto: number;
}

export class RankingGeneral {
    menos: TopProducto[] = [];
    mas: TopProducto[] = [];
    reposicion: number;
    retiro: number;
}

export class TopProducto {
    index: number;
    producto: string;
    cantidad: number;
}

export class CantidadVentas {
    sucursal: string;
    mes_tipo: number;
    mes_cantidad: number;
    mes_pasado_porcentaje: number;
    dia_tipo: number;
    dia_cantidad: number;
    dia_pasado_porcentaje: number;
}

export class VisitaDay {
    id_visita: number;
    hora_visita: string;
    color_tipo_visita: string;
    id_tipo_visita: number;
    tipo_visita: string;
    marca: string;
    visitante: string;
}

export class TopVentasMarcas {
    mayor: Venta[];
    menor: Venta[];
}

export class Venta {
    monto: number;
    marca: string;
}