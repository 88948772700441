import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ListarService } from './listar.service';
import { Marca } from '../../../models/marca.model';
import { Usuario } from '../../../models/usuario.model';
import { ActivatedRoute } from '@angular/router';
import { GeneralesService } from '../../generales.service';

@Component({
  selector: 'app-listar',
  templateUrl: './listar.component.html',
  styleUrls: ['./listar.component.scss']
})
export class ListarComponent implements OnInit {
  marcas: Marca[] = [];
  usuarios: Usuario[] = [];
  spinIcon: boolean = true;
  titulo: string = 'Usuarios Registrados en Intranet';
  filterName: string;
  filterLastName: string;
  headerBase = [];
  headerUsuario = ['Nombre', 'Apellidos', 'Email', 'Estado', 'Fecha Registro', 'Perfil', 'Gestión'];
  headerMarca = ['ID Marca', 'Nombre', 'Fecha Registro', 'Estado', 'ID BSale', 'Cant. Usuarios', 'Gestión'];
  cantResultados: string;
  perfilUsuario: number = Number(localStorage.getItem('acceso')[0]);
  vista: string = 'usuario';
  
  constructor(private servicio: ListarService, private routerModule: ActivatedRoute, private servicioGeneral: GeneralesService) { }

  ngOnInit(): void {
    this.routerModule.params.subscribe(({tipo}) => this.vista = tipo);
    this.cargarTipoLista(this.vista);
  }

  // Obtener todos los productos registrados en BSale
  product_types() {
    this.spinIcon = true;
    this.servicio.productTypes().subscribe(resp => {
      this.marcas = resp;
      this.spinIcon = false;
    });
  }

  // Obtener todos los usuario registrados en la plataforma control
  get_all_users() {
    this.spinIcon = true;
    this.servicio.getUsersControl().subscribe(resp => {
      this.usuarios = resp;
      this.setColorDifferent(this.usuarios);
      this.cantResultados = ': '+this.usuarios.length+' usuarios';
      this.spinIcon = false;
    });
  }

  // Obtener todos los usuario registrados en la plataforma control
  get_all_marcas() {
    this.spinIcon = true;
    this.servicio.getMarcasControl().subscribe(resp => {
      this.marcas = resp;
      this.cantResultados = ': '+this.marcas.length+' marcas';
      this.spinIcon = false;
    });
  }

  cargarTipoLista(tipo: string) {
    this.filterName = '';
    this.filterLastName = '';
    switch (tipo) {
      case 'usuario':
        this.headerBase = this.headerUsuario;
        this.titulo = 'Usuarios Registrados en Intranet';
        this.marcas = [];
        this.get_all_users();
        break;
        
      default:
        this.headerBase = this.headerMarca;
        this.titulo = 'Marcas Registradas en Intranet';
        this.usuarios = [];
        this.get_all_marcas();
        break;
    }
  }

  // Volver a cargar la información según la vista y lista
  reloadData() {
    let dataLoad: string;
    if (this.marcas.length > 0) {
      dataLoad = 'marca';
    } else {
      dataLoad = 'usuario';
    }
    this.cargarTipoLista(dataLoad);
  }

  // Funciones Mantenedor Usuario
  deleteUser(id_user: number, name: string) {
    Swal.fire({
      title: `Eliminar Usuario`,
      text: `Se va ha eliminar el usuario: ${name}`,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      confirmButtonColor: '#000',
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.servicio.setActionObject('delete', id_user, 'usuario').subscribe(resp => {
          if (resp == 1) {
            Swal.fire('¡Listo!', `Se eliminó el usuario: ${name}`, 'success');
            this.reloadData();
          } else {
            Swal.fire('Error', 'Vuelva a intentarlo más tarde', 'error');
          }
        });
      }
    });
  }

  lockUser(id_user: number, name: string, accion: number) {
    const accionTitulo: string = (accion == 1) ? 'Activar' : 'Desactivar';
    const accionRealizar: string = (accion == 1) ? 'activará' : 'desactivará';
    const accionAPI: string = (accion == 1) ? 'active' : 'deactivate';
    const accionRealizada: string = (accion == 1) ? 'activo' : 'desactivo';

    Swal.fire({
      title: `${accionTitulo} Usuario`,
      text: `Se ${accionRealizar} el acceso de ${name}`,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      confirmButtonColor: '#000',
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.servicio.setActionObject(accionAPI, id_user, 'usuario').subscribe(resp => {
          if (resp >= 1) {
            Swal.fire('¡Listo!', `Se ${accionRealizada} el acceso de ${name}`, 'success');
            this.reloadData();
          } else {
            Swal.fire('Error', 'Vuelva a intentarlo más tarde', 'error');
          }
        });
      }
    });
  }

  lockMarca(id_marca: number, name: string, accion: number) {
    const accionTitulo: string = (accion == 1) ? 'Activar' : 'Desactivar';
    const accionRealizar: string = (accion == 1) ? 'activará' : 'desactivará';
    const accionAPI: string = (accion == 1) ? 'active' : 'deactivate';
    const accionRealizada: string = (accion == 1) ? 'activo' : 'desactivo';

    Swal.fire({
      title: `${accionTitulo} Marca`,
      text: `Se ${accionRealizar} el acceso de todos los usuario de la marca ${name}`,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      confirmButtonColor: '#000',
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.servicio.setActionObject(accionAPI, id_marca, 'marca').subscribe(resp => {
          if (resp >= 1) {
            Swal.fire('¡Listo!', `Se ${accionRealizada} el acceso de los usuarios de ${name}`, 'success');
            this.reloadData();
          } else {
            Swal.fire('Error', 'Vuelva a intentarlo más tarde', 'error');
          }
        });
      }
    });
  }

  // Funciones Mantenedor Usuario
  deleteMarca(id_marca: number, name: string) {
    Swal.fire({
      title: `Eliminar Marca`,
      text: `Se va ha eliminar la marca ${name} y usuarios asociados`,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      confirmButtonColor: '#000',
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.servicio.setActionObject('delete', id_marca, 'marca').subscribe(resp => {
          if (resp == 1) {
            Swal.fire('¡Listo!', `Se eliminó la marca ${name}`, 'success');
            this.reloadData();
          } else {
            Swal.fire('Error', 'Vuelva a intentarlo más tarde', 'error');
          }
        });
      }
    });
  }

  setColorDifferent(usuarios: Usuario[]) {
    let valor: number[] = [];
    let index: number;
    const colors: string[] = ['black', 'blue', 'indigo', 'pink', 'red', 'orange', 'purple', 'yellow', 'green', 'teal', 'cyan'];

    usuarios.forEach(item => {
      index = valor.indexOf(item.id_perfil);
      if (index == -1) {
        valor.push(item.id_perfil);
        index = valor.length -1;
      }
      
      item.contrasena = colors[index];
    });
  }

  descargarExcel() {
    if (this.marcas.length > 0) {
      this.servicioGeneral.exportAsExcelFile(this.marcas , 'marcas_intranet');
    }
    if (this.usuarios.length > 0) {
      this.servicioGeneral.exportAsExcelFile(this.usuarios , 'usuarios_intranet');
    }
  }
}
