<app-inicio NavTitleGlobal="ventas">
    <!-- Main page content-->
    <div class="container mt-4">
        <div class="alert alert-warning" role="alert" *ngIf="loadingBack">
            <i class="fa fa-spinner fa-pulse mr-2"></i>
            Estamos cargando más productos, por favor espera antes de descargar el informe. Esto puede tomar un par de minutos.
        </div>
        <!-- Example DataTable for Dashboard Demo-->
        <div class="card card-header-actions mb-4">
            <div class="card-header">
                <div align="left">
                    <span class="text-black">
                        <i class="far fa-list mr-2"></i>
                        Registro de Productos
                    </span>
                </div>
                <!-- Joined input group solid prepend example-->
                <div>
                    <div class="input-group input-group-joined input-group-solid input-group-sm">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fa fa-tshirt"></i>
                            </span>
                        </div>
                        <input class="form-control" [(ngModel)]="filterName" id="inputFiltroName" type="text" placeholder="Filtro por producto" aria-label="Search" maxlength="50">
                    </div>
                </div>
            </div>
            <app-loading *ngIf="loading"></app-loading>
            <div class="card-body" *ngIf="!loading">
                <div class="datatable dataTables_scrollBody">
                    <table class="table table-bordered table-hover" id="dataTable" width="100%" cellspacing="0">
                        <thead class="table-black">
                            <tr>
                                <th colspan="2">Identificador</th>
                                <th>Producto</th>
                                <th>Cantidad Variantes</th>
                                <th>Total Stock</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="5" class="text-center" *ngIf="tiposProductos.length == 0">
                                    Sin registros de inventario
                                </td>
                            </tr>
                            <ng-template ngFor let-i="index" let-c="count" let-item [ngForOf]="tiposProductos | tableFilterStock: filterName">
                                <tr class="clickable" (click)="obtenerVariantesStock(item)">
                                    <td class="text-center">
                                        <button class="btn btn-datatable btn-icon btn-transparent-dark">
                                            <i class="fas fa-expand" *ngIf="!item.showMore"></i>
                                            <i class="fas fa-compress-arrows-alt text-danger" *ngIf="item.showMore"></i>
                                        </button>
                                    </td>
                                    <td>{{item.id_registro}}</td>
                                    <td>{{item.nombre_producto}}</td>
                                    <td>{{item.variants.length}}</td>
                                    <td>{{item.stock}}</td>
                                </tr>
                                <tr *ngIf="item.showMore">
                                    <td colspan="5" class="text-center">
                                        <table class="table table-sm" width="100%" cellspacing="0">
                                            <thead>
                                                <tr>
                                                    <th>Variante</th>
                                                    <th>SKU</th>
                                                    <th>Stock</th>
                                                    <th>Sucursal</th>
                                                </tr>
                                            </thead>
                                            <tbody class="notHover">
                                                <tr *ngFor="let variante of item.variants">
                                                    <td>{{variante.variante}}</td>
                                                    <td>
                                                        <span class="badge badge-{{variante.color}}">
                                                            {{variante.sku}}
                                                        </span>
                                                    </td>
                                                    <td>{{variante.stock}}</td>
                                                    <td>{{variante.nombre_sucursal}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                </div>
                <div class="card-footer bg-black border-top-0 row-custom">
                    <div class="col">
                        <span class="text-xs d-flex">
                            Total de Resultados{{adicionalTitulo}}
                        </span>
                    </div>
                    <div class="col" align="right">
                        <button type="button" class="btn btn-sm btn-green" (click)="descargarExcel()" [disabled]="loadingBack">
                            <i class="fas fa-file-excel mr-2"></i>
                            Descargar
                        </button>
                        <small *ngIf="loadingVariantes">
                            Obteniendo el stock de los productos
                            <i class="fas fa-sync fa-spin"></i>
                        </small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-inicio>