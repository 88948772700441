import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AccesoComponent } from './pages/acceso/acceso.component';
import { HeaderComponent } from './pages/header/header.component';
import { FooterComponent } from './pages/footer/footer.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';

import { ChartsModule } from 'ng2-charts';
import { Error404Component } from './pages/errors/error404/error404.component';
import { AgregarComponent } from './pages/administracion/agregar/usuario/agregar.component';
import { ListarComponent } from './pages/administracion/listar/listar.component';
import { AgregarMarcaComponent } from './pages/administracion/agregar/marca/agregarMarca.component';

import localeES from '@angular/common/locales/es-CL';
import { registerLocaleData } from  '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingComponent } from './pages/loading/loading.component';

import { TableFilterPipe } from './pages/administracion/listar/table-filter.pipe';
import { TableFilterReportPipe } from './pages/reportes/table-filter-report.pipe';
import { TableAgendaFilterPipe } from './pages/agenda/agenda-table-filter.pipe';
import { StockComponent } from './pages/reportes/stock/stock.component';
import { VentasComponent } from './pages/reportes/ventas/ventas.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AgendaComponent } from './pages/agenda/agenda.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestionComponent } from './pages/gestion/gestion.component';
import { CuentaComponent } from './pages/administracion/cuenta/cuenta.component';

import { DpDatePickerModule } from 'ng2-date-picker';
import { CargaMasivaComponent } from './pages/gestion/carga-masiva/carga-masiva.component';
import { ComisionComponent } from './pages/reportes/comisiones/comision.component';  
import { TableFilterStockPipe } from './pages/reportes/stock/table-filter-stock.pipe';
import { ReporteLoginComponent } from './pages/reportes/login-reporte/login-reporte.component';
import { LoginTableFilterPipe } from './pages/reportes/login-reporte/login-table-filter.pipe';
import { DownloaderReporteComponent } from './pages/reportes/downloader-reporte/downloader-reporte.component';
import { StockDownloadComponent } from './pages/reportes/stock-download/stock-download.component';
import { VentasDownloadComponent } from './pages/reportes/ventas-download/ventas-download.component';

registerLocaleData(localeES);

@NgModule({
  declarations: [
    AppComponent,
    AccesoComponent,
    HeaderComponent,
    FooterComponent,
    InicioComponent,
    DashboardComponent,
    Error404Component,
    AgregarComponent,
    AgregarMarcaComponent,
    ListarComponent,
    LoadingComponent,
    TableFilterPipe,
    TableFilterReportPipe,
    TableAgendaFilterPipe,
    TableFilterStockPipe,
    StockComponent,
    StockDownloadComponent,
    VentasComponent,
    AgendaComponent,
    GestionComponent,
    CuentaComponent,
    CargaMasivaComponent,
    ComisionComponent,
    ReporteLoginComponent,
    LoginTableFilterPipe,
    DownloaderReporteComponent,
    VentasDownloadComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DpDatePickerModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: false }),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-CL' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
