<app-inicio NavTitleGlobal="nucleo" NavTitle="dashboard" #componenteInicio>
    <header class="page-header page-header-dark bg-inicio-header pb-10" *ngIf="!loading">
        <div class="container">
            <div class="page-header-content pt-4">
                <div class="row align-items-center justify-content-between">
                    <div class="col-auto mt-4">
                        <h1 class="page-header-title">
                            <div class="page-header-icon">
                                <i class="far fa-analytics text-black"></i>
                            </div>
                            Dashboard
                        </h1>
                        <div class="page-header-subtitle text-black">
                            Estadísticas generales de 
                            <span *ngIf="!componenteInicio.modoAdministrador">tu Marca</span>
                            <span *ngIf="componenteInicio.modoAdministrador">la Intranet</span>
                        </div>
                    </div>
                    <div class="col-12 col-xl-auto mt-4" *ngIf="!loading">
                        <button class="btn btn-black p-3 nav-link dropdown-toggle" id="navbarDropdownDocs" href="javascript:void(0);" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mr-2 text-white far fa-calendar"></i>
                            <span class="mr-2"><b>Periodo:</b></span>
                            <span class="mr-2">{{textTimeControl | date:'MMMM, y'}}</span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right py-0 mr-sm-n15 mr-lg-0 o-hidden animated--fade-in-up" aria-labelledby="navbarDropdownDocs">
                            <span class="dropdown-item py-2 clickable" *ngFor="let fecha of rangoFechas" (click)="updatePerido(fecha)">
                                {{fecha | date:'MMMM, y'}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <app-loading *ngIf="loading"></app-loading>
    <!-- Main page content-->
    <div class="container mt-n10" *ngIf="!componenteInicio.modoAdministrador && !loading">
        <div class="row">
            <div class="col-xxl-4 col-xl-12 mb-4">
                <div class="card card-waves h-100">
                    <div class="card-body h-100 d-flex flex-column justify-content-center py-5 py-xl-4">
                        <div class="row align-items-center">
                            <div class="col-xl-8 col-xxl-12">
                                <div
                                    class="text-center text-xl-left text-xxl-center px-4 mb-4 mb-xl-0 mb-xxl-4">
                                    <h1 class="text-back">Plataforma Intranet de Ventas</h1>
                                    <p class="text-gray-700 mb-0">Ahora podrás controlar tu stock de productos, tendencia de venta, comisiones y mucho más. En una sola plataforma y desde cualquier dispositivo.</p>
                                </div>
                            </div>
                            <div class="col-xl-4 col-xxl-12 text-center">
                                <img class="img-fluid" src="assets/img/illustrations/site-stats-pana.svg" style="max-width: 10rem" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-4 col-xl-6 mb-4">
                <div class="card card-collapsable">
                    <a class="card-header" href="#collapseCardMenorStock" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseCardMenorStock">
                        <span class="text-black">
                            <i class="far fa-chart-bar"></i>
                            Variación de Ventas Total
                        </span>
                        <div class="card-collapsable-arrow text-black">
                            <i class="fas fa-chevron-down"></i>
                        </div>
                    </a>
                    <div class="collapse show" id="collapseCardMenorStock">
                        <div class="card-body">
                            <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions" [legend]="barChartLegend" [chartType]="barChartType" [colors]="barChartColors"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-4 col-xl-6 mb-4">
                <div class="card card-collapsable">
                    <a class="card-header" href="#collapseCardMayorVenta" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseCardMayorVenta">
                        <span class="text-black">
                            <i class="far fa-shopping-cart"></i>
                            Total de Venta por Sucursal
                        </span>
                        <div class="card-collapsable-arrow text-black">
                            <i class="fas fa-chevron-down"></i>
                        </div>
                    </a>
                    <div class="collapse show" id="collapseCardMayorVenta">
                        <div class="card-body text-center">
                            <canvas *ngIf="doughnutChartData[0].length > 0" baseChart [data]="doughnutChartData" [labels]="doughnutChartLabels" [options]="doughnutChartOptions" [chartType]="doughnutChartType" [colors]="doughnutChartColors"></canvas>
                            <span *ngIf="doughnutChartData[0].length <= 0">Sin ventas registradas en {{textTimeControl | date:'MMMM, y'}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Example Colored Cards for Dashboard Demo-->
        <div class="row">
            <div class="col-xxl-3 col-md-6 col-lg-3">
                <div class="card bg-green text-white mb-4" title="{{masVendido}}">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="mr-3">
                                <div class="text-white-75 font-weight-bold">
                                    Más vendido
                                </div>
                                <div class="small">{{masVendido.substring(0, 12)+'...'}}</div>
                            </div>
                            <i class="fas fa-cowbell-more fa-2x text-white-50"></i>
                        </div>
                    </div>
                    <div class="card-footer d-flex align-items-center justify-content-between">
                        <a class="small text-white stretched-link" href="#" data-toggle="modal" data-target="#modalMasVendido">Ver 10 más vendidos</a>
                        <div class="small text-white"><i class="fas fa-angle-right"></i></div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-3 col-md-6 col-lg-3">
                <div class="card bg-pink text-white mb-4" title="{{menosVendido}}">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="mr-3">
                                <div class="text-white-75 font-weight-bold">
                                    Menos vendido
                                </div>
                                <div class="small">{{menosVendido.substring(0, 12)+'...'}}</div>
                            </div>
                            <i class="fas fa-cart-arrow-down fa-2x text-white-50"></i>
                        </div>
                    </div>
                    <div class="card-footer d-flex align-items-center justify-content-between">
                        <a class="small text-white stretched-link" href="#" data-toggle="modal" data-target="#modalMenosVendido">Ver 10 menos vendidos</a>
                        <div class="small text-white"><i class="fas fa-angle-right"></i></div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-3 col-md-6 col-lg-3">
                <div class="card bg-blue text-white mb-4">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="mr-3">
                                <div class="text-white-75 font-weight-bold">
                                    Visitas Reposición
                                </div>
                                <div class="small">
                                    {{rankingGeneral.reposicion}} veces
                                </div>
                            </div>
                            <i class="fas fa-box-check fa-2x text-white-50"></i>
                        </div>
                    </div>
                    <div class="card-footer d-flex align-items-center justify-content-between">
                        <a class="small text-white stretched-link" routerLink="/principal/agenda">Ver agenda</a>
                        <div class="small text-white"><i class="fas fa-angle-right"></i></div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-3 col-md-6 col-lg-3">
                <div class="card bg-yellow text-white mb-4">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="mr-3">
                                <div class="text-white-75 font-weight-bold">
                                    Visitas para Retiro
                                </div>
                                <div class="small">
                                    {{rankingGeneral.retiro}} veces
                                </div>
                            </div>
                            <i class="fas fa-dolly fa-2x text-white-50"></i>
                        </div>
                    </div>
                    <div class="card-footer d-flex align-items-center justify-content-between">
                        <a class="small text-white stretched-link" routerLink="/principal/agenda">Ver reporte</a>
                        <div class="small text-white"><i class="fas fa-angle-right"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Example DataTable for Dashboard Demo-->
        <div class="row">
            <div class="col-xl-3 col-md-6 mb-4" *ngFor="let venta of cantidadVentasSucursal">
                <!-- Dashboard info widget 2-->
                <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-black h-100">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div class="flex-grow-1">
                                <div class="font-weight-bold mb-1">
                                    <i class="fas fa-store"></i>
                                    {{venta.sucursal | titlecase}}
                                </div>
                                <div class="font-weight-bold text-black small">Total Ventas</div>
                                <div class="small">
                                    {{textTimeControl | date:'MMMM'}}: {{venta.mes_cantidad}} ventas
                                    <div class="text-xs font-weight-bold d-inline-flex {{venta.mes_tipo ? 'text-green' : 'text-red'}}">
                                        <i class="mr-1" class="far {{venta.mes_tipo ? 'fa-level-up-alt' : 'fa-level-down-alt'}}"></i>
                                        {{(venta.mes_cantidad - venta.mes_pasado_porcentaje)}} ventas
                                    </div>
                                    <div class="text-xs d-inline-flex {{venta.mes_tipo ? 'text-green' : 'text-red'}}">
                                        En comparación con {{mesPasadoAlSeleccionado | date:'MMMM'}}
                                    </div>
                                </div>
                                <div class="small">
                                    {{textTimeControl | date:'d MMMM'}}: {{venta.dia_cantidad}} ventas
                                    <div class="text-xs font-weight-bold d-inline-flex {{venta.dia_tipo ? 'text-green' : 'text-red'}}">
                                        <i class="mr-1" class="far {{venta.dia_tipo ? 'fa-level-up-alt' : 'fa-level-down-alt'}}"></i>
                                        {{(venta.dia_cantidad - venta.dia_pasado_porcentaje)}} ventas
                                    </div>
                                    <div class="text-xs d-inline-flex {{venta.dia_tipo ? 'text-green' : 'text-red'}}">
                                        En comparación con {{mesPasadoAlSeleccionado | date:'MMMM'}}
                                    </div>
                                </div>
                            </div>
                            <div class="ml-2 d-xl-none">
                                <i class="fas fa-tags fa-2x text-gray-200"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container mt-n10" *ngIf="componenteInicio.modoAdministrador && !loading">
        <div class="row">
            <div class="col-xxl-6 col-xl-6 mb-4">
                <div class="card card-waves h-100">
                    <div class="card-body h-100 d-flex flex-column justify-content-center py-5 py-xl-4">
                        <div class="row align-items-center">
                            <div class="col-xl-6 col-xxl-6 text-center">
                                <img class="img-fluid" src="assets/img/illustrations/data-report-pana.svg" style="max-width: 20rem" />
                            </div>
                            <div class="col-xl-6 col-xxl-6">
                                <h2 class="text-back text-center">Panel Administrador</h2>
                                <div class="text-center text-xl-left text-xxl-center mb-4 mb-xl-0 mb-xxl-4">
                                    <div class="text-gray-700 mb-0">
                                        Desde aquí podrás controlar
                                        <ul>
                                            <li>Ranking de ventas de las marcas.</li>
                                            <li>Visitas del día para reposición o retiro de productos.</li>
                                            <li>Documentos emitidos a las marcas.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-4 col-xl-6 mb-4">
                <div class="card card-header-actions h-100">
                    <div class="card-header">
                        <span class="text-black">
                            <i class="far fa-calendar-day"></i>
                            Reservas de Hora
                        </span>
                        <div class="dropdown no-caret">
                            <button class="btn btn-transparent-dark btn-icon dropdown-toggle" id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fas fa-ellipsis-v text-gray-500"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right animated--fade-in-up" aria-labelledby="dropdownMenuButton">
                                <h6 class="dropdown-header">Filtrar por:</h6>
                                <div class="dropdown-item clickable" (click)="filtrarAgendaDia(0)">
                                    <span class="badge badge-black text-white my-1">Ninguno</span>
                                </div>
                                <div class="dropdown-item clickable" (click)="filtrarAgendaDia(1)">
                                    <span class="badge badge-blue-soft text-blue my-1">Reposición de Productos</span>
                                </div>
                                <div class="dropdown-item clickable" (click)="filtrarAgendaDia(2)">
                                    <span class="badge badge-yellow-soft text-yellow my-1">Retiro de Productos</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="timeline timeline-xs">
                            <!-- Timeline Item 1-->
                            <div class="timeline-item" *ngFor="let visita of visitasDia">
                                <div class="timeline-item-marker">
                                    <div class="timeline-item-marker-text">{{visita.hora_visita}} hrs</div>
                                    <div class="timeline-item-marker-indicator bg-{{visita.color_tipo_visita}}"></div>
                                </div>
                                <div class="timeline-item-content">
                                    {{visita.tipo_visita}}
                                    <span class="font-weight-bold text-dark">{{visita.marca}}:</span>
                                    {{visita.visitante}}
                                </div>
                            </div>
                            <div class="text-center" *ngIf="visitasDia.length == 0">
                                Sin registro de visitas hoy
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-4 col-xl-6 mb-4">
                <div class="card card-collapsable">
                    <a class="card-header" href="#collapseCardTopMayor" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseCardTopMayor">
                        <span class="text-black">
                            <i class="far fa-chart-line"></i>
                            Top Marcas de Mayor Venta
                        </span>
                        <div class="card-collapsable-arrow text-black">
                            <i class="fas fa-chevron-down"></i>
                        </div>
                    </a>
                    <div class="collapse show" id="collapseCardTopMayor">
                        <div class="card-body">
                            <canvas baseChart [datasets]="barChartDataAdminMas" [labels]="barChartLabelsAdminMas" [options]="barChartOptions" [legend]="barChartLegend" [chartType]="barChartType" [colors]="barChartColors"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-4 col-xl-6 mb-4">
                <div class="card card-collapsable">
                    <a class="card-header" href="#collapseCardTopMenor" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseCardTopMenor">
                        <span class="text-black">
                            <i class="far fa-chart-line-down"></i>
                            Top Marcas de Menor Venta
                        </span>
                        <div class="card-collapsable-arrow text-black">
                            <i class="fas fa-chevron-down"></i>
                        </div>
                    </a>
                    <div class="collapse show" id="collapseCardTopMenor">
                        <div class="card-body">
                            <canvas baseChart [datasets]="barChartDataAdminMenos" [labels]="barChartLabelsAdminMenos" [options]="barChartOptions" [legend]="barChartLegend" [chartType]="barChartType" [colors]="barChartColors"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="modalMasVendido" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header bg-black">
                    <h5 class="modal-title text-white">Productos Más Vendidos: {{textTimeControl | date:'MMMM, y'}}</h5>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="list-group list-group-flush small">
                        <span class="text-center" *ngIf="rankingGeneral.mas.length == 0">Sin productos vendidos</span>
                        <div class="list-group-item list-group-item-action" *ngFor="let item of rankingGeneral.mas">
                            <span class="mr-2 text-blue">{{item.index}} <i class="fas fa-angle-right"></i></span>
                            <span class="mr-2">{{item.producto}}</span>
                            <span class="text-blue">(cant. {{item.cantidad}})</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-black rounded-pill px-4 mr-2 my-1" type="button" data-dismiss="modal">¡Listo!</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modalMenosVendido" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document" *ngIf="!vistaNoVendidos">
            <div class="modal-content">
                <div class="modal-header bg-black">
                    <h5 class="modal-title text-white">Productos Menos Vendidos: {{textTimeControl | date:'MMMM, y'}}</h5>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="list-group list-group-flush small">
                        <span class="text-center" *ngIf="rankingGeneral.menos.length == 0">Sin productos vendidos</span>
                        <div class="list-group-item list-group-item-action" *ngFor="let item of rankingGeneral.menos">
                            <span class="mr-2 text-blue"><i class="fas fa-angle-right"></i></span>
                            <span class="mr-2">{{item.producto}}</span>
                            <span class="text-blue">(cant. {{item.cantidad}})</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-black-cs rounded-pill px-4 mr-2 my-1" type="button" (click)="cambiarVista()">Ver no vendidos</button>
                    <button class="btn btn-black rounded-pill px-4 mr-2 my-1" type="button" data-dismiss="modal">¡Listo!</button>
                </div>
            </div>
        </div>
        <div class="modal-dialog" role="document" *ngIf="vistaNoVendidos">
            <div class="modal-content">
                <div class="modal-header bg-black">
                    <h5 class="modal-title text-white">Productos sin vender: {{textTimeControl | date:'MMMM, y'}}</h5>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="list-group list-group-flush small">
                        <div class="list-group-item list-group-item-action" *ngFor="let producto of productoNoVendidos">
                            <span class="mr-2 text-blue"><i class="fas fa-angle-right"></i></span>
                            <span class="mr-2">{{producto}}</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-black-cs rounded-pill px-4 mr-2 my-1" type="button" (click)="cambiarVista()">Ver menos vendidos</button>
                    <button class="btn btn-black rounded-pill px-4 mr-2 my-1" type="button" data-dismiss="modal">¡Listo!</button>
                </div>
            </div>
        </div>
    </div>
</app-inicio>