import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableFilterStock'
})
export class TableFilterStockPipe implements PipeTransform {

  transform(items: any[], nombre_producto) {
    if (!items && !nombre_producto) {
      return items;
    }

    return items.filter(item => {
      if (nombre_producto && item.nombre_producto.toLowerCase().indexOf(nombre_producto.toLowerCase()) === -1) {
        return false;
      }
      return true;
    });
  }
}