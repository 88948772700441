<app-inicio NavTitleGlobal="administracion" NavTitle="agregarMarca">
    <header class="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
        <div class="container-fluid">
            <div class="page-header-content">
                <div class="row align-items-center justify-content-between pt-3">
                    <div class="col-auto mb-3">
                        <h1 class="page-header-title">
                            <div class="page-header-icon">
                                <i class="far fa-store"></i>
                            </div>
                            {{titulo}}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <!-- Main page content-->
    <div class="container-fluid mt-4">
        <app-loading *ngIf="loading"></app-loading>
        <div class="row justify-content-center" *ngIf="!loading">
            <div class="col-xl-7">
                <div class="card mb-4">
                    <div class="card-header text-black">
                        <i class="fas fa-user-tag"></i>
                        Información de la Marca
                    </div>
                    <div class="card-body">
                        <form autocomplete="off" (ngSubmit)="sendNewMarca()" [formGroup]="formMarca">
                            <!-- Form Row-->
                            <div class="form-row">
                                <div class="form-group col-md-5">
                                    <label class="small mb-1" for="inputName">Nombre<span class="text-danger">*</span></label>
                                    <input class="form-control input-list-custom {{validarCampo('nombre')}}" id="inputName" formControlName="nombre" type="text" placeholder="Ingrese el nombre" maxlength="30" (input)="teclaPresionada()" />
                                    <ul class="list-group small" *ngIf="!formMarca.controls.nombre.pristine">
                                        <li class="text-center list-group-item-custom list-group-item-action" *ngIf="loadingSearchMarca">
                                            <i class="fas fa-sync fa-spin fa-lg"></i> Cargando
                                        </li>
                                        <li class="list-group-item-custom list-group-item-action clickable" *ngFor="let marca of searchMarcaName;" (click)="searchMarcaSelected(marca)">
                                            {{marca.name}}
                                        </li>
                                        <li class="text-red text-center list-group-item-custom list-group-item-action" *ngIf="showAlertSearch && !loadingSearchMarca">
                                            <i class="fas fa-engine-warning"></i>
                                            Sin resultado
                                        </li>
                                    </ul>
                                    <div class="invalid-feedback">Nombre de 3 a 20 caracteres</div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="small mb-1" for="inputIdBsale">ID BSale<span class="text-danger">*</span></label>
                                    <input class="form-control {{validarCampo('id_bsale')}}" id="inputIdBsale" formControlName="id_bsale" type="number" />
                                    <div class="invalid-feedback">Seleccione una marca</div>
                                </div>
                                <div class="form-group col-md-4">
                                    <label class="small mb-1" for="inputDescripcion">Descripción</label>
                                    <input class="form-control {{validarCampo('descripcion')}}" id="inputDescripcion" formControlName="descripcion" type="text" placeholder="Ingrese una descripción" />
                                    <div class="invalid-feedback">Texto de 0 a 40 caracteres</div>
                                </div>
                            </div>
                            <!-- Form Row-->
                            <div class="form-row">
                                <div class="form-group col-md-3">
                                    <label class="small mb-1" for="ddlRegion">Región<span class="text-danger">*</span></label>
                                    <select class="form-control {{validarCampo('id_region')}}" id="ddlRegion" formControlName="id_region" (change)="loadComuna($event.target.value)">
                                        <option value="{{region.id_region}}" *ngFor="let region of regionesTemporales" [selected]="region.id_region == 7">{{region.nombre}}</option>
                                    </select>
                                    <div class="invalid-feedback">Seleccione una opción</div>
                                </div>
                                <div class="form-group col-md-4">
                                    <label class="small mb-1" for="ddlComuna">Comuna<span class="text-danger">*</span></label>
                                    <select class="form-control {{validarCampo('id_comuna')}}" id="ddlComuna" formControlName="id_comuna">
                                        <option value="{{comuna.id_comuna}}" *ngFor="let comuna of comunasTemporales" [selected]="comuna.id_comuna== 1">{{comuna.nombre}}</option>
                                    </select>
                                    <div class="invalid-feedback">Seleccione una opción</div>
                                </div>
                                <div class="form-group col-md-5">
                                    <label class="small mb-1" for="inputDireccion">Dirección<span class="text-danger">*</span></label>
                                    <input class="form-control {{validarCampo('direccion')}}" id="inputDireccion" formControlName="direccion" type="text" placeholder="Ingrese dirección" />
                                    <div class="invalid-feedback">Texto de 0 a 40 caracteres</div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-9">
                                    <label class="small mb-1" for="validatedCustomFile">Imagen Marca</label>
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" id="validatedCustomFile" (change)="onFileChanged($event)" accept="image/png, .jpeg, .jpg">
                                        <label class="custom-file-label" for="validatedCustomFile">{{nameFileLoad}}</label>
                                        <small id="imageHelp" class="form-text text-muted">JPG o PNG, Máximo 5 MB.</small>
                                    </div>
                                </div>
                                <div class="form-group col-md-3 text-center">
                                    <div class="avatar avatar-load">
                                        <img class="avatar-img img-fluid" src={{imageMarca}} title="Previsualización">
                                    </div>
                                </div>
                            </div>
                            <!-- Save changes button-->
                            <div class="text-right">
                                <button class="btn btn-black rounded-pill px-4 mr-2 my-1" [disabled]="loadingForm">
                                    <span *ngIf="!loadingForm">{{botonTitulo}}</span>
                                    <span *ngIf="loadingForm">
                                        Guardando
                                        <i class="fas fa-sync fa-spin fa-lg"></i>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-xl-5">
                <div class="card card-header-actions mb-4">
                    <div class="card-header">
                        <i class="fas fa-folder-open"></i>
                        Receptores Tributarios
                        <div>
                            <button class="btn btn-green btn-sm btn-icon mr-2" type="button" data-toggle="modal" data-target="#nuevoReceptorTributarioModal">
                                <i class="fas fa-plus" title="Agregar un receptor tributario"></i>
                            </button>
                            <button class="btn btn-blue btn-sm btn-icon" *ngIf="receptorSelected">
                                <i class="fas fa-play" title="Establecer como predeterminado"></i>
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <!-- Form Row-->
                        <div style="font-size: 14px;" *ngFor="let receptor of receptoresTributarios">
                            <div class="clickable" (click)="selectedReceptorTributario(receptor.id_receptor)">
                                <span class="titulo-600">- Rut:</span> {{receptor.rut}}<br>
                                <span class="titulo-600">- Razón Social:</span> {{receptor.razon_social}}<br>
                                <span class="titulo-600">- Act. Económica:</span> {{receptor.actividad_economica}}<br>
                            </div>
                            <hr>
                        </div>
                        <div class="text-center" *ngIf="receptoresTributarios.length == 0">
                            Sin receptores asignados
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Large modal -->
    <div class="modal fade" id="nuevoReceptorTributarioModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header bg-black">
                    <span class="modal-title text-white">
                        <i class="far fa-user-plus"></i>
                        Agregar Receptor Tributario
                    </span>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true">×</span>
                    </button>
                </div>
                <form autocomplete="off" (ngSubmit)="guardarNuevoReceptor()">
                    <div class="modal-body">
                        <div class="form-row justify-content-center">
                            <div class="form-group col-md-4">
                                <label class="small mb-1" for="inputRutTributario">Rut Tributario<span class="text-danger">*</span></label>
                                <input class="form-control" id="inputRutTributario" name="inputRutTributario" [(ngModel)]="tempReceptorTributario.rut" type="text" placeholder="Ingrese rut" />
                                <small class="form-text text-muted">Sin puntos ni guión</small>
                            </div>
                            <div class="form-group col-md-10">
                                <label class="small mb-1" for="inputRazonSocial">Razón Social<span class="text-danger">*</span></label>
                                <input class="form-control" id="inputRazonSocial" name="inputRazonSocial" [(ngModel)]="tempReceptorTributario.razon_social" type="text" placeholder="Ingrese razón social" />
                                <small class="form-text text-muted">Texto mayor a 5 caracteres</small>
                            </div>
                            <div class="form-group col-md-12">
                                <label class="small mb-1" for="inputActividadEconomica">Actividad Económica<span class="text-danger">*</span></label>
                                <input class="form-control" id="inputActividadEconomica" name="inputActividadEconomica" [(ngModel)]="tempReceptorTributario.actividad_economica" type="text" placeholder="Ingrese la actividad económica registrado en el SII" />
                                <small class="form-text text-muted">Texto mayor a 40 caracteres</small>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="btnCancelarRegistro" class="btn btn-outline-black-cs rounded-pill px-4 mr-2 my-1" type="button" data-dismiss="modal">
                            Cancelar
                        </button>
                        <button class="btn btn-black rounded-pill px-4 mr-2 my-1" type="submit" [disabled]="loadingForm">
                            <span *ngIf="!loadingForm">Guardar</span>
                            <span *ngIf="loadingForm">
                                Guardando
                                <i class="fas fa-sync fa-spin fa-lg"></i>
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</app-inicio>