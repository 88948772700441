import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BaseDocumento, Documento, Products, ProductTypes, Variants } from 'src/app/models/listar.model';
import { Marca } from 'src/app/models/marca.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportesService {
  private url = environment.API_URL;
  private apiHeaders = {
    "Content-Type": "application/json",
    "access_token": localStorage.getItem('token')
  };

  constructor(private http: HttpClient) { }

  getAllStockByMarca(id_marca: string, stock: number, indice: number = 0, limite: number = 100) {
    const authData = {
      solicitud: 'marca',
      metodo: 'stock_disponible',
      parametros: {
        id_marca,
        stock,
        indice,
        limite
      }
    };

    return this.http.post<Products[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  getStockByVariants(idx: number) {
    const authData = {
      solicitud: 'bsale',
      metodo: 'product_variants',
      parametros: {
        id: idx
      }
    };

    return this.http.post<Variants[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  getDocumentoByMarca(id: string) {
    const authData = {
      solicitud: 'documento',
      metodo: 'getAllByMarca',
      documento: {
        id_marca: id
      }
    };

    return this.http.post<Documento[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  getDocumentoFechaByMarca(idx: string, rangoInicial: string, rangoFinal: string, servicioProducto: string = "") {
    const authData = {
      solicitud: 'documento',
      metodo: 'getAllFechaByMarca',
      documento: {
        id_marca: idx,
        producto_servicio: servicioProducto
      },
      fecha_inicial: rangoInicial,
      fecha_final: rangoFinal
    };

    return this.http.post<Documento[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  getDocumentoComisiones(idMarca: string) {
    const authData = {
      solicitud: 'documento',
      metodo: 'getAllComisionesFechaByMarca',
      id_marca: idMarca
    };

    return this.http.post<BaseDocumento[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  getAllMarcas(codificado: boolean) {
    const authData = {
      solicitud: 'marca',
      metodo: 'getAll',
      codificado
    };

    return this.http.post<Marca[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  setChangeMarcaMovimientos(idMarca: string, nombreMarca: string, idsMovimientos: number[]) {
    const authData = {
      solicitud: 'documento',
      metodo: 'change_marca_documentos',
      id_marca: idMarca,
      marca: nombreMarca,
      ids_documentos: idsMovimientos
    };

    return this.http.post<number>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  setReportDownloader(idUsuario: number) {
    const authData = {
      solicitud: 'usuario',
      metodo: 'set_historial_downloader',
      id_usuario: idUsuario
    };

    return this.http.post<number>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  getAllReporteLogin() {
    const authData = {
      solicitud: 'usuario',
      metodo: 'historial_login'
    };

    return this.http.post<any>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  getAllReportDownloader() {
    const authData = {
      solicitud: 'usuario',
      metodo: 'historial_downloader'
    };

    return this.http.post<any>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  changeSalesDocument(documento: any) {
    const authData = {
      solicitud: 'documento',
      metodo: 'edit_document',
      documento
    };

    return this.http.post<number>(
      this.url,
      authData,
      { headers: this.apiHeaders }
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  getRequestIdDownloaderBsaleFile(metodoDownload: string, idMarca: string, fechaInicio: string, fechaTermino: string, tipoFiltro: string, idSucursal: number, idRequest: number = 0) {
    const authData = {
      solicitud: 'bsale',
      metodo: metodoDownload,
      parametros: {
        id: idMarca,
        fecha_inicio: fechaInicio,
        fecha_termino: fechaTermino,
        tipo: tipoFiltro,
        sucursal: idSucursal,
        id_request: idRequest
      }
    };

    return this.http.post<string>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  getStockByMarca(idMarca: string, nombreMarca: string) {
    const authData = {
      marca: nombreMarca
    };

    return this.http.post<any[]>(
      `https://backend-cron-production.up.railway.app/stock/${idMarca}`,
      authData
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  getVentasByMarca(idMarca: string, nombreMarca: string, fechaInicio: string, fechaTermino: string, perfilUsuario: number = 0) {
    const authData = {
      marca: nombreMarca,
      fecha_inicio: fechaInicio,
      fecha_termino: fechaTermino
    };

    idMarca = (perfilUsuario > 1 && idMarca === '0' ? 'admin' : idMarca);

    return this.http.post<any[]>(
      `https://backend-cron-production.up.railway.app/venta/${idMarca}`,
      authData
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }
}
