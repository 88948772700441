<app-inicio>
    <div class="container mt-4">
        <!-- Example DataTable for Dashboard Demo-->
        <div class="card card-header-actions mb-4">
            <div class="card-header">
                <div align="left">
                    <i class="far fa-table mr-1"></i>
                    <span class="text-black mr-2"> Registro de Inicio de Sesión</span>
                    <button class="btn btn-outline-dark btn-icon btn-sm mr-2" type="button" title="Volver a Cargar" (click)="loadReport()">
                        <i [ngClass]="spinIcon ? 'fa-spin' : ''" class="fas fa-sync"></i>
                    </button>
                </div>
                <div class="row">
                    <div class="input-group input-group-joined input-group-solid input-group-sm col mr-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fas fa-user-circle fa-lg"></i>
                            </span>
                        </div>
                        <input class="form-control" [(ngModel)]="filterName" id="inputFiltroName" type="text" placeholder="Filtro por Nombre" aria-label="Search" maxlength="50">
                    </div>
                    <div class="input-group input-group-joined input-group-solid input-group-sm col mr-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fas fa-store"></i>
                            </span>
                        </div>
                        <input class="form-control" [(ngModel)]="filterMarca" id="inputFiltroLastName" type="text" placeholder="Filtro por Marca" aria-label="Search" maxlength="50">
                    </div>
                </div>
            </div>
            <app-loading *ngIf="spinIcon"></app-loading>
            <div class="card-body" *ngIf="!spinIcon">
                <div class="datatable dataTables_scrollBody">
                    <table class="table table-bordered table-hover" id="dataTable" width="100%" cellspacing="0">
                        <thead class="table-black">
                            <tr>
                                <td>N° Registro</td>
                                <td>Nombre</td>
                                <td>Email</td>
                                <td>Nombre Marca</td>
                                <td>Fecha Ingreso</td>
                                <td>Hora Ingreso</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let registro of allRegister | tableLoginFilter: filterName: filterMarca">
                                <td>{{registro.id_historial}}</td>
                                <td>{{registro.nombre_usuario}}</td>
                                <td>{{registro.email_usuario}}</td>
                                <td>{{registro.marca_usuario}}</td>
                                <td>{{registro.fecha_login}}</td>
                                <td>{{registro.hora_login}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="card-footer bg-black border-top-0">
                    <div class="row">
                        <div class="col">
                            <span class="text-xs d-flex">
                                Total de Resultados {{allRegister.length}}
                            </span>
                        </div>
                        <div class="col" align="right">
                            <button type="button" class="btn btn-sm btn-green" (click)="descargarExcel()">
                                <i class="fas fa-file-excel mr-2"></i>
                                Descargar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-inicio>