import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AccesoComponent } from './pages/acceso/acceso.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { Error404Component } from './pages/errors/error404/error404.component';
import { AgregarComponent } from './pages/administracion/agregar/usuario/agregar.component';
import { ListarComponent } from './pages/administracion/listar/listar.component';
import { AgregarMarcaComponent } from './pages/administracion/agregar/marca/agregarMarca.component';
import { StockComponent } from './pages/reportes/stock/stock.component';
import { VentasComponent } from './pages/reportes/ventas/ventas.component';
import { AgendaComponent } from './pages/agenda/agenda.component';
import { GestionComponent } from './pages/gestion/gestion.component';
import { CuentaComponent } from './pages/administracion/cuenta/cuenta.component';
import { CargaMasivaComponent } from './pages/gestion/carga-masiva/carga-masiva.component';
import { ComisionComponent } from './pages/reportes/comisiones/comision.component';
import { ReporteLoginComponent } from './pages/reportes/login-reporte/login-reporte.component';
import { DownloaderReporteComponent } from './pages/reportes/downloader-reporte/downloader-reporte.component';
import { StockDownloadComponent } from './pages/reportes/stock-download/stock-download.component';
import { AccesoGuard } from './pages/acceso.guard';
import { NonAuthGuard } from './pages/not-acceso.guard';
import { VentasDownloadComponent } from './pages/reportes/ventas-download/ventas-download.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'acceso'
  },
  {
    path: 'acceso',
    component: AccesoComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'forgotpassword/:token',
    component: AccesoComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'principal/dashboard',
    component: DashboardComponent,
    canActivate: [AccesoGuard]
  },
  {
    path: 'principal/agenda',
    component: AgendaComponent,
    canActivate: [AccesoGuard]
  },
  {
    path: 'cuenta',
    component: CuentaComponent,
    canActivate: [AccesoGuard]
  },
  {
    path: 'usuarios/agregar',
    component: AgregarComponent,
    canActivate: [AccesoGuard]
  },
  {
    path: 'usuarios/editar/:id',
    component: AgregarComponent,
    canActivate: [AccesoGuard]
  },
  {
    path: 'listar',
    component: ListarComponent,
    canActivate: [AccesoGuard]
  },
  {
    path: 'listar/:tipo',
    component: ListarComponent,
    canActivate: [AccesoGuard]
  },
  {
    path: 'reporte/login',
    component: ReporteLoginComponent,
    canActivate: [AccesoGuard]
  },
  {
    path: 'reporte/descargas',
    component: DownloaderReporteComponent,
    canActivate: [AccesoGuard]
  },
  {
    path: 'marcas/agregar',
    component: AgregarMarcaComponent,
    canActivate: [AccesoGuard]
  },
  {
    path: 'marcas/editar/:id',
    component: AgregarMarcaComponent,
    canActivate: [AccesoGuard]
  },
  {
    path: 'ventas/stock',
    component: StockComponent,
    canActivate: [AccesoGuard]
  },
  {
    path: 'ventas/stock-download',
    component: StockDownloadComponent,
    canActivate: [AccesoGuard]
  },
  {
    path: 'ventas/periodo',
    component: VentasComponent,
    canActivate: [AccesoGuard]
  },
  {
    path: 'ventas/periodo-download',
    component: VentasDownloadComponent,
    canActivate: [AccesoGuard]
  },
  {
    path: 'ventas/documentos',
    component: ComisionComponent,
    canActivate: [AccesoGuard]
  },
  {
    path: 'gestion/configuracion',
    component: GestionComponent,
    canActivate: [AccesoGuard]
  },
  {
    path: 'gestion/masivo',
    component: CargaMasivaComponent,
    canActivate: [AccesoGuard]
  },
  {
    path: '**',
    pathMatch: 'full',
    component: Error404Component
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
