<app-inicio NavTitleGlobal="ventas">
    <!-- Main page content-->
    <div class="container mt-4">
        <!-- Example DataTable for Dashboard Demo-->
        <div class="card card-waves mb-4 mt-5">
            <div class="card-body p-5">
                <div class="row align-items-center justify-content-between">
                    <div class="col">
                        <h2 class="text-black">¡Puedes descargar tu inventario en Excel!</h2>
                        <p class="text-gray-700">
                            Tu inventario se descargará en formato Excel, para que puedas visualizarlo en cualquier dispositivo y actualizado en tiempo real.
                        </p>
                        <button class="btn btn-green p-3" (click)="downloadStock()" [disabled]="loading">
                            <i class="fas fa-file-excel mr-2"></i>
                            <span *ngIf="!loading">
                                Descargar Inventario
                            </span>
                            <span *ngIf="loading">
                                Descargando
                                <i class="fa fa-spinner fa-pulse ml-1"></i>
                            </span>
                        </button>
                        <br>
                        <br>
                        <small>
                            <i class="fas fa-info-circle mr-1"></i>
                            Puede tomar un tiempo dependiendo de la cantidad de productos que tengas en tu inventario.
                        </small>
                    </div>
                    <div class="col d-none d-lg-block mt-xxl-n4">
                        <img class="img-fluid px-xl-4 mt-xxl-n5" src="assets/img/illustrations/statistics.svg">
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-inicio>