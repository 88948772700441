<app-inicio>
    <header class="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
        <div class="container-fluid">
            <div class="page-header-content">
                <div class="row align-items-center justify-content-between pt-3">
                    <div class="col-auto mb-3">
                        <h1 class="page-header-title">
                            <div class="page-header-icon">
                                <i class="far fa-user"></i>
                            </div>
                            Cuenta de Usuario
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <!-- Main page content-->
    <div class="container mt-4">
        <app-loading *ngIf="loading"></app-loading>
        <!-- Account page navigation-->
        <nav class="nav nav-borders" *ngIf="!loading">
            <a class="nav-link  ml-0 clickable" [ngClass]="vistaCuenta ? 'active' : ''" (click)="cambiarVista()">Cuenta</a>
            <a class="nav-link ml-0 clickable" [ngClass]="vistaCuenta ? '' : 'active'"  (click)="cambiarVista()">Contraseña</a>
        </nav>
        <hr class="mt-0 mb-4">
        <div class="row" *ngIf="!loading">
            <div class="col-xl-4">
                <!-- Profile picture card-->
                <div class="card">
                    <div class="card-header text-black">Marcas Asociadas</div>
                    <div class="card-body text-center">
                        <!-- Profile picture image-->
                        <img class="rounded-circle mb-2 mr-2" src="{{url_imagen + marca.url_imagen}}" alt="Imagen Marca" width="100" *ngFor="let marca of usuario.marcas">
                    </div>
                </div>
            </div>
            <div class="col-xl-8" *ngIf="vistaCuenta">
                <!-- Account details card-->
                <div class="card mb-4">
                    <div class="card-header text-black">Información de la Cuenta</div>
                    <div class="card-body">
                        <form (ngSubmit)="updateAccount()" [formGroup]="formUsuario" autocomplete="off">
                            <div class="form-row">
                                <!-- Form Group (first name)-->
                                <div class="form-group col-md-4">
                                    <label class="small mb-1" for="inputFirstName">Nombre<span class="text-danger">*</span></label>
                                    <input class="form-control {{validarCampo('nombre')}}" id="inputFirstName" formControlName="nombre" type="text" placeholder="Ingrese el nombre" maxlength="25" />
                                    <div class="invalid-feedback">Nombre de 3 a 25 caracteres</div>
                                </div>
                                <!-- Form Group (last name)-->
                                <div class="form-group col-md-4">
                                    <label class="small mb-1" for="inputLastName">Apellido Paterno<span class="text-danger">*</span></label>
                                    <input class="form-control {{validarCampo('apellido_paterno')}}" id="inputLastName" formControlName="apellido_paterno" type="text" placeholder="Ingrese apellido paterno" maxlength="25" />
                                    <div class="invalid-feedback">Apellido de 3 a 25 caracteres</div>
                                </div>
                                <!-- Form Group (organization name)-->
                                <div class="form-group col-md-4">
                                    <label class="small mb-1" for="inputOrgName">Apellido Materno</label>
                                    <input class="form-control {{validarCampo('apellido_materno')}}" id="inputOrgName" formControlName="apellido_materno" type="text" placeholder="Ingrese apellido materno" maxlength="25" />
                                    <div class="invalid-feedback">Apellido de 3 a 25 caracteres</div>
                                </div>
                            </div>
                            <!-- Form Row        -->
                            <div class="form-row">
                                <!-- Form Group (organization name)-->
                                <div class="form-group col-md-6">
                                    <label class="small mb-1" for="inputTelefono">Número de Teléfono</label>
                                    <input class="form-control {{validarCampo('telefono')}}" id="inputTelefono" formControlName="telefono" type="tel" placeholder="Ingrese número de teléfono" max="999999999">
                                    <div class="invalid-feedback">Numérico 7 a 9 dígitos</div>
                                </div>
                                <!-- Form Group (location)-->
                                <div class="form-group col-md-6">
                                    <label class="small mb-1" for="inputCorreo">Correo Electrónico</label>
                                    <input class="form-control {{validarCampo('email')}}" id="inputCorreo" formControlName="email" type="email" placeholder="Ingrese correo electrónico" maxlength="100">
                                    <div class="invalid-feedback">Correo no válido</div>
                                </div>
                            </div>
                            <!-- Save changes button-->
                            <div class="text-right">
                                <button class="btn btn-black rounded-pill px-4 mr-2 my-1" [disabled]="loadingForm">
                                    <span *ngIf="!loadingForm">Guardar Cambios</span>
                                    <span *ngIf="loadingForm">
                                        Guardando
                                        <i class="fas fa-sync fa-spin fa-lg"></i>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-xl-4" *ngIf="!vistaCuenta">
                <div class="card mb-4">
                    <div class="card-header text-black">Cambiar Contraseña</div>
                    <div class="card-body">
                        <form (ngSubmit)="updatePassword()" autocomplete="off">
                            <!-- Form Group (new password)-->
                            <div class="form-group">
                                <label class="small mb-1" for="newPassword">Nueva Contraseña<span class="text-danger">*</span></label>
                                <input class="form-control" id="newPassword" name="nuevaContrasena" type="password" [(ngModel)]="contrasenas[0]" placeholder="Ingrese su nueva clave">
                            </div>
                            <!-- Form Group (confirm password)-->
                            <div class="form-group">
                                <label class="small mb-1" for="confirmPassword">Repita Nueva Contraseña<span class="text-danger">*</span></label>
                                <input class="form-control" id="confirmPassword" name="repitaContrasena" type="password" [(ngModel)]="contrasenas[1]" placeholder="Confirme su nueva clave">
                            </div>
                            <div class="text-right">
                                <button class="btn btn-black rounded-pill px-4 mr-2 my-1" [disabled]="loadingForm">
                                    <span *ngIf="!loadingForm">Actualizar</span>
                                    <span *ngIf="loadingForm">
                                        Actualizando
                                        <i class="fas fa-sync fa-spin fa-lg"></i>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-inicio>