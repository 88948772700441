<app-inicio NavTitleGlobal="gestion" NavTitle="Configuración">
    <header class="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
        <div class="container-fluid">
            <div class="page-header-content">
                <div class="row align-items-center justify-content-between pt-3">
                    <div class="col-auto mb-3">
                        <h1 class="page-header-title">
                            <div class="page-header-icon">
                                <i class="far fa-cogs"></i>
                            </div>
                            <span class="mr-2">Configuración de la Plataforma:</span><span class="text-red">Versión {{config.current_version}}</span>
                        </h1>
                    </div>
                    <div class="col-auto mb-3 text-left" *ngIf="!loading">
                        <button id="btnBackupConfig" class="btn btn-black btn-sm mr-2" type="button" (click)="loadBackup()">
                            <i class="fa fa-history mr-1"></i>
                            Cargar Backup
                        </button>
                        <button id="btnGuardarConfig" class="btn btn-red btn-sm" type="button" (click)="guardarConfiguracion()">
                            <i class="fa fa-save mr-1"></i>
                            Guardar Configuración
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <!-- Main page content-->
    <div class="container mt-4">
        <app-loading *ngIf="loading"></app-loading>
        <div class="row justify-content-center" *ngIf="!loading">
            <div class="col-xl-8">
                <!-- Account details card-->
                <div class="card mb-4">
                    <div class="card-header text-black">
                        Configuración de Arranque
                    </div>
                    <div class="card-body">
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckQA" [(ngModel)]="config.app_dev" (change)="cambiarAmbiente('dev')">
                                    <label class="form-check-label" for="flexSwitchCheckQA">Plataforma ambiente Pruebas</label>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckProduccion" [(ngModel)]="config.app_produccion" (change)="cambiarAmbiente('prod')">
                                    <label class="form-check-label" for="flexSwitchCheckProduccion">Plataforma ambiente Producción</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center" *ngIf="!loading">
            <div class="col-xl-8">
                <!-- Account details card-->
                <div class="card mb-4">
                    <div class="card-header text-black">
                        Configuración de Correo
                    </div>
                    <div class="card-body">
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label class="small mb-1" for="inputEmailDe">EMAIL DE <span class="text-danger">*</span></label>
                                <input class="form-control" id="inputEmailDe" type="text" [(ngModel)]="config.email_de" placeholder="Ingrese el correo de salida"/>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="small mb-1" for="inputEmailDeNombre">EMAIL DE NOMBRE <span class="text-danger">*</span></label>
                                <input class="form-control" id="inputEmailDeNombre" type="text" [(ngModel)]="config.email_de_nombre" placeholder="Ingrese el nombre del correo de salida"/>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label class="small mb-1" for="inputServerEmailDe">SERVER EMAIL DE <span class="text-danger">*</span></label>
                                <input class="form-control" id="inputServerEmailDe" type="text" [(ngModel)]="config.server_email_de" placeholder="Ingrese correo de acceso"/>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="small mb-1" for="inputServerEmailPassword">SERVER EMAIL PASSWORD <span class="text-danger">*</span></label>
                                <input class="form-control" id="inputServerEmailPassword" type="text" [(ngModel)]="config.server_email_password" placeholder="Ingrese contraseña de correo acceso"/>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label class="small mb-1" for="inputServerEmail">SERVER EMAIL <span class="text-danger">*</span></label>
                                <input class="form-control" id="inputServerEmail" type="text" [(ngModel)]="config.server_email" placeholder="Ingrese servidor de correos"/>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="small mb-1" for="inputServerEmailPort">SERVER EMAIL PORT <span class="text-danger">*</span></label>
                                <input class="form-control" id="inputServerEmailPort" type="text" [(ngModel)]="config.server_port" placeholder="Ingrese puerto del servidor de correos"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center" *ngIf="!loading">
            <div class="col-xl-8">
                <!-- Account details card-->
                <div class="card mb-4">
                    <div class="card-header text-black">
                        Configuración Adicional
                    </div>
                    <div class="card-body">
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label class="small mb-1" for="inputLugarVisita">Lugar Visitas <span class="text-danger">*</span></label>
                                <input class="form-control" id="inputLugarVisita" type="text" [(ngModel)]="config.casa_moda_lugar" placeholder="Ingrese servidor de correos"/>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="small mb-1" for="inputTokenBsale">Token BSale <span class="text-danger">*</span></label>
                                <input class="form-control" id="inputTokenBsale" type="text" [(ngModel)]="config.token_bsale" placeholder="Ingrese puerto del servidor de correos"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-inicio>