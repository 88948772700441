import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { Marca } from '../../../models/marca.model';
import { Usuario } from '../../../models/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class ListarService {
  private url = environment.API_URL;
  private apiHeaders = {
    "Content-Type": "application/json",
    "access_token": localStorage.getItem('token')
  };

  constructor(private http: HttpClient) { }

  // Obtener los tipos de productos en BSale
  productTypes() {
    const authData = {
      solicitud: 'bsale',
      metodo: 'product_types'
    };

    return this.http.post<Marca[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  // Obtener todos los usuario activos en control
  getUsersControl() {
    const authData = {
      solicitud: 'usuario',
      metodo: 'getAll'
    };

    return this.http.post<Usuario[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  // Obtener todas las marcas registradas en control
  getMarcasControl() {
    const authData = {
      solicitud: 'marca',
      metodo: 'getAll'
    };

    return this.http.post<Marca[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  // Desactivar un usuario

  // Eliminar un usuario

  // Eliminar una marca
  setActionObject(metodo: string, id: number, object: string) {
    const authData = {
      solicitud: object,
      metodo: metodo,
      marca: {
        id_marca: id
      },
      usuario: {
        id_usuario: id
      }
    };

    return this.http.post<number>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }
}
