import { SweetAlertIcon } from 'sweetalert2';
import { Marca } from './marca.model';

const marcaTemp: Marca = new Marca;

export class Usuario {
    id_usuario: number;
    nombre: string;
    apellidos: string;
    apellido_paterno: string;
    apellido_materno: string;
    fecha_registro: string;
    email: string;
    telefono: number;
    id_perfil: number;
    activo: number;
    perfil: string;
    contrasena: string;
    token: string;
}

export class UsuarioSesion {
    id_usuario: number;
    nombre: string;
    apellido_paterno: string;
    apellido_materno: string;
    fecha_registro: string;
    email: string;
    telefono: number;
    id_perfil: number;
    activo: number;
    perfil: string;
    marcas: Marca[] = [marcaTemp];
    accesos: Acceso;
}

export class Acceso {
    ver: number;
    modificar: number;
    administrar: number;
}

export class Notificacion {
    tipo_notificacion: SweetAlertIcon;
    titulo: string;
    cuerpo: string;
    id_usuario: number;
    fecha_registro: string;
    activo: number;
}

export class Region {
    id_region: number;
    nombre: string;
}

export class Comuna {
    id_comuna: number;
    nombre: string;
}