import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { BaseDocumento, Documento } from '../../../models/listar.model';
import { ReportesService } from '../reportes.service';

@Component({
  selector: 'app-comision',
  templateUrl: './comision.component.html',
  styleUrls: ['./comision.component.scss']
})
export class ComisionComponent implements OnInit {

  loading: boolean = true;
  idMarcaSesion = localStorage.getItem('marca_session');
  adicionalTitulo: string;
  documentos: BaseDocumento[] = [];
  perfilUsuario: number = Number(localStorage.getItem('acceso')[0]);

  constructor(private servicio: ReportesService) { }

  ngOnInit(): void {
    this.loadComisiones();
  }

  loadComisiones() {
    if (this.perfilUsuario <= 1 && (this.idMarcaSesion == '0' || this.idMarcaSesion == null)) {
      Swal.fire('¡Sin Marca!', 'No tienes una marca asignada, comunicate con Casa Moda.', 'info');
      this.loading = false;
      return;
    }
    this.loading = true;
    this.servicio.getDocumentoComisiones(this.idMarcaSesion).subscribe(resp => {
      this.documentos = resp;
      this.loading = false;
    });
  }

  obtenerDetalleDocumento(documento: BaseDocumento) {
    documento.showMore = !documento.showMore;
  }
}
