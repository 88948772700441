import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CalendarEvent, CalendarEventAction, CalendarView } from 'angular-calendar';
import { startOfDay, isSameDay, isSameMonth, addHours, addMinutes, isWeekend } from 'date-fns';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { Visita, Sucursal } from '../../models/visita.model';
import { AgendaService } from './agenda.service';
import { UsuarioSesion } from '../../models/usuario.model';
import * as moment from 'moment';
import { GeneralesService } from '../generales.service';

const colors: any = {
  red: {
    primary: '#e81500',
    secondary: '#f1e0e3',
  },
  blue: {
    primary: '#0061f2',
    secondary: '#dae7fb',
  },
  yellow: {
    primary: '#f4a100',
    secondary: '#f2eee3',
  },
};

class HoraCancelar {
  fecha: string;
  horaInicio: string = '';
  horaFin: string = '';
  id_sucursal: string = '';
  id_usuario: number;
}

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent implements OnInit {
  loading: boolean = true;
  loadingForm: boolean = false;
  viewHistorial: boolean = false;
  tituloModal: string = 'Agendar Hora';
  usuario: UsuarioSesion = JSON.parse(localStorage.getItem('usuario'));
  view: CalendarView = CalendarView.Month;
  perfilUsuario: number = Number(localStorage.getItem('acceso')[0]);
  filterVisita: Visita = new Visita;
  historialVisitas: Visita[] = [];
  totalResultados: number = 0;
  rangoInicial: string;
  rangoFinal: string;
  dateHoy: string = moment().format('DD-MM-YYYY') ;
  horasDisponibles: string[] = [];
  sucursalesDisponibles: Sucursal[] = [];
  
  datePickerConfig = {
    firstDayOfWeek: 'mo',
    format: "DD-MM-YYYY",
    locale: 'es',
    min: this.dateHoy
  };

  datePickerConfig2 = {
    firstDayOfWeek: 'mo',
    format: "YYYY-MM-DD",
    locale: 'es'
  };

  // exclude weekends
  excludeDays: number[] = [0];

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt" title="Editar"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.editarUnaVisita(event);
      },
    },
    {
      label: '<i class="text-danger fas fa-fw fa-trash-alt" title="Borrar"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.eliminarVisita(event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  // Carga los eventos que ya están registrados
  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = false;

  formVisita: FormGroup = this.fb.group({
    id_visita: [],
    id_tipo: [1 , [Validators.required]],
    tipo: [],
    id_usuario: [],
    usuario: [],
    id_marca: [],
    marca: [],
    fecha_visita: [ , [Validators.required]],
    hora_visita: [ '', [Validators.required]],
    comentario: [ , [Validators.maxLength(200)]],
    id_sucursal: [ '', [Validators.required]],
    id_estado: [],
  });

  horaCancelar: HoraCancelar = new HoraCancelar();

  constructor(private fb: FormBuilder, private servicio: AgendaService, private servicioGeneral: GeneralesService) {}

  ngOnInit(): void {
    this.loadVisitas();
    this.loadSucursalesDisponibles();
  }

  // click a un día del calendario
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    this.tituloModal = 'Agendar Hora';
    this.formVisita.reset();
    if (isSameMonth(date, this.viewDate)) {
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  // Cambia la vista del calendario [mes, lista, diario]
  setView(view: CalendarView) {
    this.view = view;
  }

  // Cierra la vista de los eventos del día
  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  validarCampo(campo: string) {
    const controlTemp = this.formVisita.controls[campo];
    if (controlTemp.errors && controlTemp.touched) {
      return 'is-invalid';
    } else if (!controlTemp.errors && controlTemp.touched && controlTemp.value != null) {
      return 'is-valid';
    }
  }

  sendNewVisita() {
    if (this.formVisita.invalid) {
      this.formVisita.markAllAsTouched();
      return;
    }

    this.loadingForm = true;
    const visita: Visita = this.formVisita.value;
    let tipo: string = 'create';
    if (this.tituloModal == 'Modificando Visita') {
      tipo = 'update';
    } else {
      visita.id_marca = localStorage.getItem('marca_session');
      visita.id_usuario = this.usuario.id_usuario;
    }

    if (visita.id_marca != '0') {
      this.servicio.updateCreateVisita(tipo, visita).subscribe(resp => {
        if (resp > 0) {
          this.loadVisitas();
          Swal.fire('¡Listo!', 'Hemos registrado y notificado su visita', 'success');
          document.getElementById('btnCancelarVisita').click();
          this.formVisita.reset();
        } else {
          Swal.fire('Error', '¡Vuelva a intentarlo!, posiblemente la hora acaba de ser reservada', 'error');
        }
        this.loadingForm = false;
      });
    } else {
      Swal.fire('¡Seleccione una marca!', 'Para reservar una visita debe seleccionar una marca de representación.', 'error');
      this.loadingForm = false;
    }
  }

  loadVisitas() {
    let tipo: string = 'usuario';
    if (this.perfilUsuario >= 2) {
      tipo = 'administrativo';
    }
    this.servicio.getAllVisitas(this.usuario.id_usuario, tipo).subscribe(resp => {
      const eventos: any = [];
      resp.forEach(item => {
        const temp = item.hora_visita.split(':');
        const fecha: Date = new Date(item.fecha_visita.replace(/\-/gi, '/'));
        eventos.push({
          start: addMinutes(addHours(startOfDay(fecha), Number(temp[0])), Number(temp[1])),
          title: `${item.estado} en ${item.nombre_sucursal} - ${item.hora_visita}: ${item.tipo} - ${item.marca}: ${item.usuario}`,
          color: (item.id_estado == 2 || item.id_estado == 3) ? colors.red : (item.id_tipo == 1) ? colors.blue : colors.yellow,
          id_visita: item.id_visita,
          id_tipo: item.id_tipo,
          id_usuario: item.id_usuario,
          fecha_visita: item.fecha_visita,
          hora_visita: item.hora_visita,
          actions: this.actions
        });
      });
      this.events = eventos;
      this.loading = false;
    });
  }

  editarUnaVisita(event: CalendarEvent) {
    this.tituloModal = 'Modificando Visita';
    const visita: any = event;
    this.servicio.readVisita(visita.id_visita).subscribe(resp => {
      this.formVisita.setValue(resp);
      document.getElementById('btnAgregarVisita').click();
    });
  }

  eliminarVisita(event: CalendarEvent) {
    const visita: any = event;
    Swal.fire({
      title: `Eliminar y Cancelar`,
      text: `Se va ha cancelar y eliminar la visita seleccionada`,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      confirmButtonColor: '#000',
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        const xVisita = new Visita;
        xVisita.id_visita = visita.id_visita;
        xVisita.id_usuario = visita.id_usuario;
        xVisita.fecha_visita = visita.fecha_visita;
        xVisita.hora_visita = visita.hora_visita;
        if (this.perfilUsuario >= 2) {
          xVisita.comentario = 'administrativo';
        }
        this.servicio.deleteVisita(xVisita).subscribe(resp => {
          if (resp == 1) {
            Swal.fire('¡Listo!', 'Hemos eliminado y notificado su visita', 'success');
            this.events = this.events.filter((iEvent) => iEvent !== event);
          } else {
            Swal.fire('Error', '¡Vuelva a intentarlo en unos minutos', 'error');
          }
        });
      }
    });
  }

  historial() {
    this.loading = true;
    let idUsuario = this.usuario.id_usuario;
    let tipo: string = 'usuario';
    
    if (this.perfilUsuario >= 2) {
      idUsuario = 0;
      tipo = 'administrativo';
    }
    this.servicio.getAllVisitas(idUsuario, tipo).subscribe(resp => {
      this.historialVisitas = resp;
      this.totalResultados = this.historialVisitas.length;
      this.loading = false;
      this.viewHistorial = true;
    })
  }
  
  calendario() {
    this.viewHistorial = false;
  }

  updateSearchFecha() {
    this.filterVisita.fecha_visita = this.rangoInicial + ' ' + this.rangoFinal;
  }

  cambiarHoraVisita() {
    const visita: Visita = this.formVisita.value;
    if (!visita.fecha_visita || !visita.id_sucursal) {
      return;
    }
    this.servicio.getHoraDisponible(visita.fecha_visita, visita.id_sucursal).subscribe(resp => {
      this.horasDisponibles = resp;
    });
  }

  descargarExcel() {
    this.servicioGeneral.exportAsExcelFile(this.historialVisitas, 'visitas_historicas');
  }

  loadSucursalesDisponibles() {
    this.servicio.getSucursalesDisponibles().subscribe(resp => {
      this.sucursalesDisponibles = resp;
    });
  }

  cancelarHora() {
    const hora: HoraCancelar = this.horaCancelar;
    this.servicio.cancelarHora(hora.fecha, hora.horaInicio, hora.horaFin, hora.id_sucursal, this.usuario.id_usuario).subscribe(resp => {
      if (resp >= 1) {
        this.loadVisitas();
        document.getElementById('btnCancelarHoraCancelar').click();
        this.horaCancelar = new HoraCancelar();
        Swal.fire('¡Listo!', 'Hemos eliminado y notificado su visita', 'success');
      } else {
        Swal.fire('Error', '¡Vuelva a intentarlo', 'error');
      }
    });
  }
}
