<app-inicio NavTitleGlobal="ventas">
    <header class="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
        <div class="container-fluid">
            <div class="page-header-content">
                <div class="row align-items-center justify-content-between pt-3">
                    <div class="col-3 mb-3">
                        <h1 class="page-header-title">
                            <div class="page-header-icon">
                                <i class="far fa-list"></i>
                            </div>
                            Global de Ventas
                        </h1>
                    </div>
                    <div class="col-9 mb-3 text-left" *ngIf="!loading">
                        <div class="row">
                            <div class="col text-right">
                                <small>Fecha Desde:</small>
                            </div>
                            <div class="input-group input-group-joined input-group-solid input-group-sm col">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fa fa-hourglass-start"></i>
                                    </span>
                                </div>
                                <dp-date-picker theme="dp-material" class="date-picker form-control form-control-sm" [(ngModel)]="rangoInicial" id="inputFiltroFechaIni" [config]="datePickerConfig" placeholder="Seleccione fecha" aria-label="Search"></dp-date-picker>
                            </div>
                            <div class="col text-right">
                                <small>Fecha Hasta:</small>
                            </div>
                            <div class="input-group input-group-joined input-group-solid input-group-sm col">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fa fa-hourglass-end"></i>
                                    </span>
                                </div>
                                <dp-date-picker theme="dp-material" class="date-picker form-control form-control-sm" [(ngModel)]="rangoFinal" id="inputFiltroFechaFin" [config]="datePickerConfig" placeholder="Seleccione fecha" aria-label="Search"></dp-date-picker>
                            </div>
                            <div class="col">
                                <button id="btnAgregarVisita" class="btn btn-black btn-sm" type="button" (click)="loadVentasMarca()">
                                    <i class="fas fa-sync mr-1"></i>
                                    Cargar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <!-- Main page content-->
    <div class="container-fluid mt-4">
        <!-- Example DataTable for Dashboard Demo-->
        <div class="card card-header-actions mb-4">
            <div class="card-header">
                <div align="left">
                    <span class="text-black">Registro en Ventas</span>
                </div>
                <div class="row">
                    <div class="input-group input-group-joined input-group-solid input-group-sm col mr-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fa fa-tshirt"></i>
                            </span>
                        </div>
                        <input class="form-control" [(ngModel)]="filter.producto_servicio" id="inputFiltroName" type="text" [disabled]="loading" placeholder="Filtro por producto" aria-label="Search" maxlength="50">
                    </div>
                    <div class="input-group input-group-joined input-group-solid input-group-sm col mr-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fa fa-file-invoice-dollar"></i>
                            </span>
                        </div>
                        <select class="form-control" [(ngModel)]="filter.tipo_de_movimiento" id="inputFiltroMovimiento" [disabled]="loading">
                            <option value="" selected>Seleccione</option>
                            <option value="Venta">Venta</option>
                            <option value="Devolucion">Devolución</option>
                        </select>
                    </div>
                    <div class="input-group input-group-joined input-group-solid input-group-sm col mr-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fa fa-store"></i>
                            </span>
                        </div>
                        <select class="form-control" [(ngModel)]="filter.sucursal" id="inputFiltroSucursal" [disabled]="loading">
                            <option value="" selected>Seleccione</option>
                            <option value="Online">Online</option>
                            <option value="Vitacura">Vitacura</option>
                            <option value="Parque Arauco">Parque Arauco</option>
                            <option value="Viña Mall Marina">Viña Mall Marina</option>
                        </select>
                    </div>
                </div>
            </div>
            <app-loading *ngIf="loading"></app-loading>
            <div class="card-body" *ngIf="!loading">
                <div class="datatable">
                    <table class="table table-bordered table-hover" id="dataTable" width="100%" cellspacing="0">
                        <thead class="table-black">
                            <tr>
                                <th class="text-center"><i class="fas fa-eye"></i></th>
                                <th>Movimiento</th>
                                <th>Sucursal</th>
                                <th>Producto</th>
                                <th>Variante</th>
                                <th>SKU</th>
                                <th>Monto Neto</th>
                                <th>Cantidad</th>
                                <th>IVA</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="ventas.length == 0">
                                <td colspan="8" class="text-center">
                                    Sin registro {{filtroDeFecha}}
                                </td>
                            </tr>
                            <ng-template ngFor let-i="index" let-c="count" let-venta [ngForOf]="ventas | tableFilterReport: filter.producto_servicio: filter.tipo_de_movimiento: filter.sucursal">
                                <tr class="clickable " [ngClass]="venta.seleccionado ? 'selected' : ''" (click)="obtenerDetalleVenta(venta)">
                                    <td class="text-center">
                                        <button class="btn btn-datatable btn-icon btn-transparent-dark mr-2">
                                            <i class="fas fa-expand" *ngIf="!venta.showMore"></i>
                                            <i class="fas fa-compress-arrows-alt text-danger" *ngIf="venta.showMore"></i>
                                        </button>
                                    </td>
                                    <td>{{venta.TipoMovimiento}}</td>
                                    <td>{{venta.Sucursal | titlecase}}</td>
                                    <td>{{venta.Producto}}</td>
                                    <td>{{venta.Variante | uppercase }}</td>
                                    <td>{{venta.SKU}}</td>
                                    <td>{{venta.Precio | currency:'CLP'}}</td>
                                    <td>{{venta.Cantidad}}</td>
                                    <td>{{venta.IVA | currency:'CLP'}}</td>
                                    <td>{{venta.Total | currency:'CLP'}}</td>
                                </tr>
                                <tr *ngIf="venta.showMore">
                                    <td colspan="13" class="text-center">
                                        <table class="table table-sm" width="100%" cellspacing="0">
                                            <thead>
                                                <tr>
                                                    <th>Tipo Documento</th>
                                                    <th>Sucursal Origen</th>
                                                    <th>Fecha Venta</th>
                                                    <th>Método Pago</th>
                                                </tr>
                                            </thead>
                                            <tbody class="notHover">
                                                <tr>
                                                    <td>{{venta.TipoDocumento | titlecase}}</td>
                                                    <td>{{venta.Sucursal | titlecase}}</td>
                                                    <td>{{venta.FechaVenta}}</td>
                                                    <td>{{venta.MetodoPago }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                </div>
                <div class="card-footer bg-black border-top-0">
                    <div class="row">
                        <div class="col-4 text-left">
                            <span class="text-xs">
                                {{ventas.length}} registro(s) {{filtroDeFecha}}
                            </span>
                        </div>
                        <div class="col-6" align="center">
                            <button type="button" class="btn btn-sm btn-green mr-2" (click)="descargarExcel()">
                                <i class="fas fa-file-excel mr-2"></i>
                                Descargar Ventas
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-inicio>