import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { Marca, ReceptorTributario } from '../../../models/marca.model';
import { Comuna, Region, Usuario } from '../../../models/usuario.model';
import { ListarMarcas } from 'src/app/models/listar.model';

@Injectable({
  providedIn: 'root'
})
export class AgregarService {
  private url = environment.API_URL;
  private apiHeaders = {
    "Content-Type": "application/json",
    "access_token": localStorage.getItem('token')
  };

  constructor(private http: HttpClient) { }

  getMarca(id: string) {
    const authData = {
      solicitud: 'marca',
      metodo: 'read',
      marca: {
        id_marca: id
      }
    };

    return this.http.post<Marca>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  getUsuario(id: number) {
    const authData = {
      solicitud: 'usuario',
      metodo: 'read',
      usuario: {
        id_usuario: id
      }
    };

    return this.http.post<Usuario>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  getUsuarioMarcas(id: number) {
    const authData = {
      solicitud: 'usuario',
      metodo: 'getMarcasAsociadas',
      usuario: {
        id_usuario: id
      }
    };

    return this.http.post<Marca[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  getMarcaByName(name: string) {
    const authData = {
      solicitud: 'marca',
      metodo: 'getAllByName',
      marca: {
        nombre: name
      }
    };

    return this.http.post<Marca[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  updateCreateMarca(tipo: string, marca: Marca) {
    const authData = {
      solicitud: 'marca',
      metodo: tipo,
      marca: marca
    };

    return this.http.post<number>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  uploadImagenMarca(imagen: any) {
    const authData = {
      solicitud: 'marca',
      metodo: 'uploadMarca',
      imagen: imagen
    };

    return this.http.post<string>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  updateCreateUsuario(tipo: string, usuario: Usuario) {
    const authData = {
      solicitud: 'usuario',
      metodo: tipo,
      usuario: usuario
    };

    return this.http.post<number>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  setMarcasUsuario(marca: Marca[], id: number) {
    const authData = {
      solicitud: 'usuario',
      metodo: 'setMarcasAsociadas',
      usuario: {
        id_usuario: id,
        marcas: marca
      }
    };

    return this.http.post<number>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  getMarcasFromExternal() {
    const authData = {
      solicitud: 'bsale',
      metodo: 'product_types'
    };

    return this.http.post<ListarMarcas[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  getReceptoresTributarios(id: string) {
    const authData = {
      solicitud: 'marca',
      metodo: 'get_receptores_tributarios',
      id_marca: id
    };

    return this.http.post<ReceptorTributario[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  setReceptorTributario(receptor: ReceptorTributario) {
    const authData = {
      solicitud: 'marca',
      metodo: 'set_receptor_tributario',
      receptor: receptor
    };

    return this.http.post<number>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  getAllRegiones() {
    const authData = {
      solicitud: 'documento',
      metodo: 'get_all_regiones'
    };

    return this.http.post<Region[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  getComunasByRegion(idRegion: number) {
    const authData = {
      solicitud: 'documento',
      metodo: 'get_comunas_by_region',
      id_region: idRegion
    };

    return this.http.post<Comuna[]>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  createUpdateReceptorTributario(tipo: string, idMarca: string, receptores: ReceptorTributario[]) {
    if (tipo == 'update') {
      receptores = receptores.filter(rc => rc.id_receptor == 0);
    }
    const authData = {
      solicitud: 'marca',
      metodo: 'nuevo_receptor_tributario',
      id_marca: idMarca,
      receptor_tributario: receptores
    };

    return this.http.post<number>(
      this.url,
      authData,
      {headers: this.apiHeaders}
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }
}
