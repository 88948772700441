import { Component, OnInit } from '@angular/core';
import { GeneralesService } from '../../generales.service';
import { ReportesService } from '../reportes.service';

@Component({
  selector: 'app-downloader-reporte',
  templateUrl: './downloader-reporte.component.html',
  styleUrls: ['./downloader-reporte.component.scss']
})
export class DownloaderReporteComponent implements OnInit {
  filterName: string;
  filterMarca: string;
  spinIcon: boolean = false;

  allRegister: any = [];

  constructor(private servicioReporte: ReportesService, private servicioGeneral: GeneralesService) { }

  ngOnInit(): void {
    this.loadReport();
  }

  loadReport() {
    this.spinIcon = true;
    this.servicioReporte.getAllReportDownloader().subscribe(
      resp => {
        this.allRegister = resp;
        this.spinIcon = false;
      }, errors => {
        console.error(errors);
      }
    );
  }

  descargarExcel() {
    if (this.allRegister.length > 0) {
      this.servicioGeneral.exportAsExcelFile(this.allRegister , 'registro_login_intranet');
    }
  }
}
