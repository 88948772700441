<app-inicio NavTitleGlobal="administracion" NavTitle="agregarUsuario">
    <header class="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
        <div class="container-fluid">
            <div class="page-header-content">
                <div class="row align-items-center justify-content-between pt-3">
                    <div class="col-auto mb-3">
                        <h1 class="page-header-title">
                            <div class="page-header-icon">
                                <i class="far fa-user"></i>
                            </div>
                            {{titulo}}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <!-- Main page content-->
    <div class="container mt-4">
        <app-loading *ngIf="loading"></app-loading>
        <div class="row justify-content-center" *ngIf="!loading">
            <div class="col-xl-7">
                <!-- Account details card-->
                <div class="card mb-4">
                    <div class="card-header text-black">
                        <i class="far fa-id-card"></i>
                        Información de la cuenta de Usuario
                    </div>
                    <div class="card-body">
                        <form (ngSubmit)="sendNewUsuario()" [formGroup]="formUsuario">
                            <!-- Form Row-->
                            <div class="form-row">
                                <!-- Form Group (first name)-->
                                <div class="form-group col-md-4">
                                    <label class="small mb-1" for="inputFirstName">Nombre<span class="text-danger">*</span></label>
                                    <input class="form-control {{validarCampo('nombre')}}" id="inputFirstName" formControlName="nombre" type="text" placeholder="Ingrese el nombre" maxlength="25" />
                                    <div class="invalid-feedback">Nombre de 3 a 25 caracteres</div>
                                </div>
                                <!-- Form Group (last name)-->
                                <div class="form-group col-md-4">
                                    <label class="small mb-1" for="inputLastName">Apellido Paterno<span class="text-danger">*</span></label>
                                    <input class="form-control {{validarCampo('apellido_paterno')}}" id="inputLastName" formControlName="apellido_paterno" type="text" placeholder="Ingrese apellido paterno" maxlength="25" />
                                    <div class="invalid-feedback">Apellido de 3 a 25 caracteres</div>
                                </div>
                                <!-- Form Group (organization name)-->
                                <div class="form-group col-md-4">
                                    <label class="small mb-1" for="inputOrgName">Apellido Materno</label>
                                    <input class="form-control {{validarCampo('apellido_materno')}}" id="inputOrgName" formControlName="apellido_materno" type="text" placeholder="Ingrese apellido materno" maxlength="25" />
                                    <div class="invalid-feedback">Apellido de 3 a 25 caracteres</div>
                                </div>
                            </div>
                            <!-- Form Row-->
                            <div class="form-row">
                                <!-- Form Group (phone number)-->
                                <div class="form-group col-md-6">
                                    <label class="small mb-1" for="inputPhone">Número de Teléfono</label>
                                    <input class="form-control {{validarCampo('telefono')}}" id="inputPhone" formControlName="telefono" type="number" placeholder="Ingrese número de teléfono" max="999999999" />
                                    <div class="invalid-feedback">Numérico 7 a 9 dígitos</div>
                                </div>
                                <!-- Form Group (birthday)-->
                                <div class="form-group col-md-6">
                                    <label class="small mb-1" for="exampleFormControlSelect1">Perfil<span class="text-danger">*</span></label>
                                    <select class="form-control {{validarCampo('id_perfil')}}" id="exampleFormControlSelect1" formControlName="id_perfil">
                                        <option>Seleccione</option>
                                        <option value="1">Usuario</option>
                                        <option value="2" *ngIf="perfilUsuario >= 2">KAM</option>
                                        <option value="3" *ngIf="perfilUsuario >= 2">Jefa de Tienda</option>
                                        <option value="4" *ngIf="perfilUsuario >= 4">Gerencia</option>
                                    </select>
                                    <div class="invalid-feedback">Seleccione una opción</div>
                                </div>
                            </div>
                            <!-- Form Group (email address)-->
                            <div class="form-row">
                                <div class="form-group col-md-8">
                                    <label class="small mb-1" for="inputEmailAddress">Correo Electrónico<span class="text-danger">*</span></label>
                                    <input class="form-control {{validarCampo('email')}}" id="inputEmailAddress" formControlName="email" type="email" placeholder="Ingrese correo electrónico" maxlength="100" />
                                    <div class="invalid-feedback">{{emailErrors}}</div>
                                </div>
                                <div class="form-group col-md-4">
                                    <label class="small mb-1" for="inputPassword">Contraseña</label>
                                    <input class="form-control {{validarCampo('contrasena')}}" id="inputPassword" formControlName="contrasena" type="text" placeholder="Asignar Clave" maxlength="15" />
                                    <div class="invalid-feedback">Mínimo 6 a 15 caracteres</div>
                                </div>
                            </div>
                            <!-- Save changes button-->
                            <div class="text-right">
                                <button class="btn btn-black rounded-pill px-4 mr-2 my-1" [disabled]="loadingForm || formUsuario.pending">
                                    <span *ngIf="!loadingForm">{{botonTitulo}}</span>
                                    <span *ngIf="loadingForm">
                                        Guardando
                                        <i class="fas fa-sync fa-spin fa-lg"></i>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-xl-4">
                <!-- Account details card-->
                <div class="card mb-4">
                    <div class="card-header text-black">
                        <i class="far fa-store"></i>
                        Asociar Marcas
                    </div>
                    <div class="card-body">
                        <form autocomplete="off">
                            <!-- Form Row-->
                            <div class="form-row">
                                <!-- Form Group (first name)-->
                                <div class="col-md-8">
                                    <label class="small mb-1" for="inputNameMarca">Nombre Marca</label>
                                    <input class="form-control input-list-custom" id="inputNameMarca" name="nameMarchaSearch" type="text" placeholder="Ingrese la marca" [(ngModel)]="nameMarchaSearch" (keyup.enter)="searchMarca()" (input)="teclaPresionada()" value="{{marcaSelected.nombre}}" />
                                    <ul class="list-group small list-marca-temp">
                                        <li class="list-group-item-custom list-group-item-action clickable" *ngFor="let marca of searchMarcaName;" (click)="searchMarcaSelected(marca.id_marca, marca.nombre)">
                                            {{marca.nombre}}
                                        </li>
                                        <li class="text-red text-center list-group-item-custom list-group-item-action" *ngIf="showAlertSearch">
                                            <i class="fas fa-engine-warning"></i>
                                            Sin resultado
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-4">
                                    <button class="btn btn-black rounded-pill mr-2 my-4" (click)="incluirMarcaTemporal()">Agregar</button>
                                </div>
                            </div>
                            <div class="row justify-content-center" *ngIf="usuarioMarcas?.length > 0">
                                <span>Marcas Asociadas</span>
                            </div>
                            <div class="hr-custom" *ngIf="usuarioMarcas?.length > 0"></div>
                            <div class="alert-pad-03 alert-red text-center" role="alert" *ngIf="usuarioMarcas?.length <= 0 && idUsuario">
                                <i class="fas fa-engine-warning"></i>
                                No hay marcas asociadas
                            </div>
                            <div class="list-group list-group-flush small" *ngFor="let marca of usuarioMarcas;">
                                <div class="list-group-item list-group-item-action d-flex align-items-center justify-content-between border_bottom">
                                    <div class=" mr-2">
                                        <i class="fas fa-check text-success"></i>
                                        {{marca.nombre}}
                                    </div>
                                    <button class="btn btn-danger btn-icon btn-sm mr-2" (click)="removeMarcaTemporal(marca.id_marca)">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-inicio>