<app-inicio NavTitleGlobal="ventas">
    <header class="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
        <div class="container-fluid">
            <div class="page-header-content">
                <div class="row align-items-center justify-content-between pt-3">
                    <div class="col-3 mb-3">
                        <h1 class="page-header-title">
                            <div class="page-header-icon">
                                <i class="far fa-list"></i>
                            </div>
                            Global de Ventas
                        </h1>
                    </div>
                    <div class="col-9 mb-3 text-left" *ngIf="!loading">
                        <div class="row">
                            <div class="col text-right">
                                <small>Fecha Desde:</small>
                            </div>
                            <div class="input-group input-group-joined input-group-solid input-group-sm col">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fa fa-hourglass-start"></i>
                                    </span>
                                </div>
                                <dp-date-picker theme="dp-material" class="date-picker form-control form-control-sm" [(ngModel)]="rangoInicial" id="inputFiltroFechaIni" [config]="datePickerConfig" placeholder="Seleccione fecha" aria-label="Search"></dp-date-picker>
                            </div>
                            <div class="col text-right">
                                <small>Fecha Hasta:</small>
                            </div>
                            <div class="input-group input-group-joined input-group-solid input-group-sm col">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fa fa-hourglass-end"></i>
                                    </span>
                                </div>
                                <dp-date-picker theme="dp-material" class="date-picker form-control form-control-sm" [(ngModel)]="rangoFinal" id="inputFiltroFechaFin" [config]="datePickerConfig" placeholder="Seleccione fecha" aria-label="Search"></dp-date-picker>
                            </div>
                            <div class="col text-right" *ngIf="perfilUsuario != 1 && perfilUsuario != 3">
                                <button id="btnAgregarVisita" class="btn btn-blue btn-sm" type="button" (click)="cargarMovimientosSinTipo()">
                                    <i class="fas fa-dolly mr-1"></i>
                                    Sin Tipo
                                </button>
                            </div>
                            <div class="col">
                                <button id="btnAgregarVisita" class="btn btn-black btn-sm" type="button" (click)="cargarMovimientosFecha()">
                                    <i class="fas fa-sync mr-1"></i>
                                    Cargar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <!-- Main page content-->
    <div class="container-fluid mt-4">
        <!-- Example DataTable for Dashboard Demo-->
        <div class="card card-header-actions mb-4">
            <div class="card-header">
                <div align="left">
                    <span class="text-black">Registro en Ventas</span>
                </div>
                <div class="row">
                    <div class="input-group input-group-joined input-group-solid input-group-sm col mr-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fa fa-tshirt"></i>
                            </span>
                        </div>
                        <input class="form-control" [(ngModel)]="filter.producto_servicio" id="inputFiltroName" type="text" placeholder="Filtro por producto" aria-label="Search" maxlength="50" (ngModelChange)="cleanPaginacion()">
                    </div>
                    <div class="input-group input-group-joined input-group-solid input-group-sm col mr-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fa fa-file-invoice-dollar"></i>
                            </span>
                        </div>
                        <select class="form-control" [(ngModel)]="filter.tipo_de_movimiento" id="inputFiltroMovimiento" (ngModelChange)="cleanPaginacion()">
                            <option value="" selected>Seleccione</option>
                            <option value="Venta">Venta</option>
                            <option value="Devolución">Devolución</option>
                        </select>
                    </div>
                    <div class="input-group input-group-joined input-group-solid input-group-sm col mr-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fa fa-store"></i>
                            </span>
                        </div>
                        <select class="form-control" [(ngModel)]="filter.sucursal" id="inputFiltroSucursal" (ngModelChange)="cleanPaginacion()">
                            <option value="" selected>Seleccione</option>
                            <option value="Online">Online</option>
                            <option value="Vitacura">Vitacura</option>
                            <option value="Parque Arauco">Parque Arauco</option>
                            <option value="Viña Mall Marina">Viña Mall Marina</option>
                        </select>
                    </div>
                    <div class="col" *ngIf="documentosSeleccionadas.length >= 1">
                        <button class="btn btn-green btn-sm" type="button" data-toggle="modal" data-target="#modalCambiarMarca" (click)="loadMarcas()">
                            <i class="fas fa-sync mr-2"></i>
                            Cambiar Marca
                        </button>
                    </div>
                </div>
            </div>
            <app-loading *ngIf="loading"></app-loading>
            <div class="card-body" *ngIf="!loading">
                <div class="datatable">
                    <table class="table table-bordered table-hover" id="dataTable" width="100%" cellspacing="0">
                        <thead class="table-black">
                            <tr>
                                <th class="text-center"><i class="fas fa-eye"></i></th>
                                <th>Movimiento</th>
                                <th>Sucursal</th>
                                <th>Producto</th>
                                <th>Variante</th>
                                <th>SKU</th>
                                <th>Monto Neto</th>
                                <th>Cantidad</th>
                                <th>IVA</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="ventas.length == 0">
                                <td colspan="8" class="text-center">
                                    Sin registro {{filtroDeFecha}}
                                </td>
                            </tr>
                            <ng-template ngFor let-i="index" let-c="count" let-venta [ngForOf]="ventas | tableFilterReport: filter.producto_servicio: filter.tipo_de_movimiento: filter.sucursal: paginacion">
                                <tr class="clickable " [ngClass]="venta.seleccionado ? 'selected' : ''" (click)="obtenerDetalleVenta(venta)">
                                    <td class="text-center">
                                        <button class="btn btn-datatable btn-icon btn-transparent-dark mr-2">
                                            <i class="fas fa-expand" *ngIf="!venta.showMore"></i>
                                            <i class="fas fa-compress-arrows-alt text-danger" *ngIf="venta.showMore"></i>
                                        </button>
                                        <button class="btn btn-datatable btn-icon btn-transparent-dark" data-toggle="modal" data-target="#modalModificarVenta" (click)="editarMovimientoVenta(venta)" *ngIf="perfilUsuario != 1 && perfilUsuario != 3">
                                            <i class="fas fa-edit"></i>
                                        </button>
                                    </td>
                                    <td>{{venta.tipo_de_movimiento}}</td>
                                    <td>{{venta.sucursal | titlecase}}</td>
                                    <td>{{venta.producto_servicio}}</td>
                                    <td>{{ venta.variante | uppercase }}</td>
                                    <td>{{venta.sku}}</td>
                                    <td>{{venta.precio_neto_unitario | currency:'CLP'}}</td>
                                    <td>{{venta.cantidad}}</td>
                                    <td>{{venta.subtotal_impuestos | currency:'CLP'}}</td>
                                    <td>{{venta.subtotal | currency:'CLP'}}</td>
                                </tr>
                                <tr *ngIf="venta.showMore">
                                    <td colspan="13" class="text-center">
                                        <i class="fa fa-sync fa-spin fa-2x" *ngIf="venta.loadingDetalle"></i>
                                        <table class="table table-sm" *ngIf="!venta.loadingDetalle" width="100%" cellspacing="0">
                                            <thead>
                                                <tr>
                                                    <th>Tipo Documento</th>
                                                    <th>Sucursal Origen</th>
                                                    <th>Fecha Venta</th>
                                                    <th>Método Pago</th>
                                                </tr>
                                            </thead>
                                            <tbody class="notHover">
                                                <tr>
                                                    <td>{{venta.tipo_documento | titlecase}}</td>
                                                    <td>{{venta.sucursal_origen | titlecase}}</td>
                                                    <td>{{venta.fecha_venta | date:'fullDate'}}</td>
                                                    <td>{{venta.metodo_pago}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                </div>
                <div class="card-footer bg-black border-top-0">
                    <div class="row">
                        <div class="col-4 text-left">
                            <span class="text-xs">
                                {{totalResultados}}
                            </span>
                        </div>
                        <div class="col-6" align="center">
                            <button type="button" class="btn btn-sm btn-green mr-2" (click)="descargarExcel()">
                                <i class="fas fa-file-excel mr-2"></i>
                                Descargar
                            </button>
                            <button type="button" class="btn btn-sm btn-orange mr-2" *ngIf="perfilUsuario != 1 && perfilUsuario != 3" (click)="descargarExcelByBSALE()">
                                <i class="fas fa-file-excel mr-2"></i>
                                Descargar BSALE
                            </button>
                            <button class="btn btn-sm " [ngClass]="activarSelector ? 'btn-red' : 'btn-green'" (click)="changeActivarSelector()" *ngIf="perfilUsuario != 1 && perfilUsuario != 3">{{activarSelector ? 'Desactivar' : 'Activar'}} selector</button>
                        </div>
                        <div class="col text-right">
                            <span class="text-white clickable mr-2" (click)="nextAndPrevious(0)" *ngIf="paginacion > 0">
                                <i class="far fa-arrow-alt-circle-left fa-2x"></i>
                            </span>
                            <span class="text-white clickable" (click)="nextAndPrevious(1)" *ngIf="paginacion <= ventas.length && this.paginacionNext">
                                <i class="far fa-arrow-alt-circle-right fa-2x"></i>
                            </span>
                            <span class="text-xs">
                                Página {{ (paginacion/10) + 1}} / {{(this.ventas.length / 10) | number: '1.0-0'}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal cambiar de marca -->
     <div class="modal fade" id="modalCambiarMarca" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" *ngIf="perfilUsuario != 1 && perfilUsuario != 3">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header bg-black">
                    <span class="modal-title text-white">
                        <i class="fas fa-sync mr-2"></i>
                        Re-asignación de Marca
                    </span>
                    <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label for="txtCantidadDocumentos">Movimientos</label>
                        <input class="form-control" id="txtCantidadDocumentos" type="text" value="{{documentosSeleccionadas.length}} Seleccionados" disabled>
                    </div>
                    <div class="mb-3">
                        <label for="ddlChangeMarca">Seleccione marca</label>
                        <select class="form-control" id="ddlChangeMarca" [(ngModel)]="marcaSeleccionada">
                            <option value="{{marca.id_marca}}" *ngFor="let marca of marcasRegistradas">{{marca.nombre}}</option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="btn-close-modal-change-marca" class="btn btn-outline-black-cs rounded-pill px-4 mr-2 my-1" type="button" data-dismiss="modal">
                        Cancelar
                    </button>
                    <button class="btn btn-black rounded-pill px-4 mr-2 my-1" type="button" (click)="cambiarMarcaDelMovimiento()">
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal modificar movimiento de venta marca -->
     <div class="modal fade" id="modalModificarVenta" tabindex="-1" role="dialog" aria-labelledby="twoLargeModalLabel" aria-hidden="true" *ngIf="perfilUsuario != 1 && perfilUsuario != 3">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header bg-black">
                    <span class="modal-title text-white">
                        <i class="fas fa-edit mr-2"></i>
                        Modificar Movimiento Venta
                    </span>
                    <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="form-row">
                        <div class="col-md-4 mb-3">
                            <label for="ddlTipoMovimiento">Tipo de Movimiento</label>
                            <select class="form-control" id="ddlTipoMovimiento" [(ngModel)]="ventaMovimientoEditar.tipo_de_movimiento">
                                <option>Venta</option>
                                <option>Devolución</option>
                            </select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="ddlTipoDocumento">Tipo de Documento</label>
                            <select class="form-control" id="ddlTipoDocumento" [(ngModel)]="ventaMovimientoEditar.id_tipo_documento">
                                <option value="1">BOLETA ELECTRÓNICA</option>
                                <option value="5">FACTURA ELECTRÓNICA</option>
                                <option value="2">NOTA DE CRÉDITO ELECTRÓNICA</option>
                                <option value="17">NOTA DE DÉBITO ELECTRÓNICA</option>
                                <option selected>Otra</option>
                            </select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="txtCantidadDocumentos">Folio Documento</label>
                            <input class="form-control" id="txtCantidadDocumentos" type="text" value="" [(ngModel)]="ventaMovimientoEditar.nro_documento">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-4 mb-3">
                            <label for="ddlMarca">Marca</label>
                            <select class="form-control" id="ddlMarca" [(ngModel)]="ventaMovimientoEditar.id_marca" (change)="updateTipoProductoServicio()">
                                <option value="{{marca.id_marca}}" *ngFor="let marca of marcasRegistradas">{{marca.nombre}}</option>
                            </select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="ddlSucursal">Sucursal</label>
                            <select class="form-control" id="ddlSucursal" [(ngModel)]="ventaMovimientoEditar.sucursal">
                                <option>ONLINE</option>
                                <option>VITACURA</option>
                                <option>PARQUE ARAUCO</option>
                                <option>VIÑA MALL MARINA</option>
                            </select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="ddlMetodoPago">Método de Pago</label>
                            <select class="form-control" id="ddlMetodoPago" [(ngModel)]="ventaMovimientoEditar.metodo_pago">
                                <option>Transferencia Electronica</option>
                                <option>Tarjeta Débito</option>
                                <option>Tarjeta Crédito</option>
                                <option>Nota Crédito Devolución</option>
                                <option>Mercado Pago</option>
                                <option>Gift Card</option>
                                <option>Efectivo</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-5 mb-3">
                            <label for="txtNombreProducto">Producto</label>
                            <input class="form-control" id="txtNombreProducto" type="text" value="" [(ngModel)]="ventaMovimientoEditar.producto_servicio">
                        </div>
                        <div class="col-md-5 mb-3">
                            <label for="txtTipoProducto">Tipo Producto</label>
                            <input class="form-control" id="txtTipoProducto" type="text" value="" [(ngModel)]="ventaMovimientoEditar.tipo_de_producto_servicio" disabled>
                        </div>
                        <div class="col-md-2 mb-3">
                            <label for="txtSKU">SKU</label>
                            <input class="form-control" id="txtSKU" type="text" value="" [(ngModel)]="ventaMovimientoEditar.sku">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-4 mb-3">
                            <label for="txtVendedor">Vendedor</label>
                            <input class="form-control" id="txtVendedor" type="text" value="" [(ngModel)]="ventaMovimientoEditar.vendedor">
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="txtClient">Cliente</label>
                            <input class="form-control" id="txtClient" type="text" value="" [(ngModel)]="ventaMovimientoEditar.cliente">
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="txtRutClient">Rut Cliente</label>
                            <input class="form-control" id="txtRutClient" type="text" value="" [(ngModel)]="ventaMovimientoEditar.rut_cliente">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-3 mb-3">
                            <label for="txtVariante">Variante</label>
                            <input class="form-control" id="txtVariante" type="text" value="" [(ngModel)]="ventaMovimientoEditar.variante">
                        </div>
                        <div class="col-md-3 mb-3">
                            <label for="txtMontoPrecio">Precio</label>
                            <input class="form-control" id="txtMontoPrecio" type="number" value="" [(ngModel)]="ventaMovimientoEditar.precio_neto_unitario" (change)="updateMontoTotal()">
                        </div>
                        <div class="col-md-3 mb-3">
                            <label for="txtMontoIVA">IVA</label>
                            <input class="form-control" id="txtMontoIVA" type="number" value="" [(ngModel)]="ventaMovimientoEditar.subtotal_impuestos" (change)="updateMontoTotal()">
                        </div>
                        <div class="col-md-3 mb-3">
                            <label for="txtMontoTotal">Total</label>
                            <input class="form-control" id="txtMontoTotal" type="text" value="$ {{ ventaMovimientoEditar.subtotal | number }}" disabled>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="btn-close-modal-change-venta" class="btn btn-outline-black-cs rounded-pill px-4 mr-2 my-1" type="button" data-dismiss="modal" (click)="removeVentaMovimiento()">
                        Cancelar
                    </button>
                    <button class="btn btn-black rounded-pill px-4 mr-2 my-1" type="button" (click)="guardarMovimientoVenta()">
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    </div>
</app-inicio>