import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableFilter'
})
export class TableFilterPipe implements PipeTransform {

  transform(items: any[], filter: string, otherFilter: string ) {
    if (!items) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter(item => {
      if (filter && item.nombre.toLowerCase().indexOf(filter.toLowerCase()) === -1) {
        return false;
      }
      if (otherFilter && item.apellidos.toLowerCase().indexOf(otherFilter.toLowerCase()) === -1) {
        return false;
      }
      return true;
    });
  }
}