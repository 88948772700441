<app-inicio NavTitleGlobal="administracion" NavTitle="listarUsuarios">
    <!-- Main page content-->
    <div class="container mt-4">
        <!-- Example DataTable for Dashboard Demo-->
        <div class="card card-header-actions mb-4">
            <div class="card-header">
                <div align="left">
                    <button class="btn btn-outline-dark btn-icon btn-sm mr-2" title="Listar Usuarios" (click)="cargarTipoLista('usuario')">
                        <i class="fas fa-users"></i>
                    </button>
                    <button class="btn btn-outline-dark btn-icon btn-sm mr-2" title="Listar Marcas" (click)="cargarTipoLista('marca')">
                        <i class="fas fa-store"></i>
                    </button>
                    <span class="text-black mr-2"> {{titulo}}</span>
                    <button class="btn btn-outline-dark btn-icon btn-sm mr-2" type="button" (click)="reloadData()" title="Volver a Cargar">
                        <i [ngClass]="spinIcon ? 'fa-spin' : ''" class="fas fa-sync"></i>
                    </button>
                </div>
                <div class="row">
                    <div class="input-group input-group-joined input-group-solid input-group-sm col mr-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                        <input class="form-control" [(ngModel)]="filterName" id="inputFiltroName" type="text" placeholder="Filtro por Nombre" aria-label="Search" maxlength="50">
                    </div>
                    <div class="input-group input-group-joined input-group-solid input-group-sm col mr-2" *ngIf="usuarios.length > 0">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                        <input class="form-control" [(ngModel)]="filterLastName" id="inputFiltroLastName" type="text" placeholder="Filtro por Marca" aria-label="Search" maxlength="50">
                    </div>
                </div>
            </div>
            <app-loading *ngIf="spinIcon"></app-loading>
            <div class="card-body" *ngIf="!spinIcon">
                <div class="datatable dataTables_scrollBody">
                    <table class="table table-bordered table-hover" id="dataTable" width="100%" cellspacing="0">
                        <thead class="table-black">
                            <tr>
                                <td *ngFor="let item of headerBase;">{{item}}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let marca of marcas | tableFilter: filterName">
                                <td>{{marca.id_marca}}</td>
                                <td>{{marca.nombre}}</td>
                                <td>{{marca.fecha_registro | date:'fullDate'}}</td>
                                <td>
                                    <i class="fas fa-check-circle text-success mr-1" *ngIf="marca.activo == 1"></i>
                                    <i class="fas fa-minus-circle text-danger mr-1" *ngIf="marca.activo == 0"></i>
                                    {{marca.activo == 1 ? 'Activado' : 'Desactivado'}}
                                </td>
                                <td>{{marca.id_bsale}}</td>
                                <td class="text-center">
                                    <div class="badge badge-primary badge-pill">{{marca.cantidad_usuarios}} usuario(s)</div>
                                </td>
                                <td class="text-center">
                                    <button class="btn btn-datatable btn-icon btn-transparent-dark mr-2" [routerLink]="['/marcas/editar/', marca.id_marca]">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                    <button class="btn btn-datatable btn-icon btn-transparent-dark mr-2" (click)="deleteMarca(marca.id_marca, marca.nombre)" *ngIf="perfilUsuario >= 4">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                    <button class="btn btn-datatable btn-icon btn-transparent-dark" (click)="lockMarca(marca.id_marca, marca.nombre, 0)" *ngIf="marca.activo == 1">
                                        <i class="fas fa-ban" title="Desactivar"></i>
                                    </button>
                                    <button class="btn btn-datatable btn-icon btn-transparent-dark" (click)="lockMarca(marca.id_marca, marca.nombre, 1)" *ngIf="marca.activo == 0">
                                        <i class="far fa-check-circle" title="Activar"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr *ngFor="let usuario of usuarios | tableFilter: filterName: filterLastName">
                                <td>{{usuario.nombre}}</td>
                                <td>{{usuario.apellidos}}</td>
                                <td>{{usuario.email}}</td>
                                <td>
                                    <i class="fas fa-check-circle text-success mr-1" *ngIf="usuario.estado == 1"></i>
                                    <i class="fas fa-minus-circle text-danger mr-1" *ngIf="usuario.estado == 0"></i>
                                    {{(usuario.estado == 1) ? 'Activado' : 'Desactivado'}}
                                </td>
                                <td>{{usuario.fecha_registro | date:'fullDate'}}</td>
                                <td>
                                    <div class="badge badge-{{usuario.contrasena}} badge-pill">{{usuario.perfil}}</div>
                                </td>
                                <td class="text-center">
                                    <button class="btn btn-datatable btn-icon btn-transparent-dark mr-2" [routerLink]="['/usuarios/editar/', usuario.id_usuario]">
                                        <i class="fas fa-edit" title="Editar"></i>
                                    </button>
                                    <button class="btn btn-datatable btn-icon btn-transparent-dark mr-2" (click)="deleteUser(usuario.id_usuario, usuario.nombre + ' ' + usuario.apellido_paterno)">
                                        <i class="fas fa-trash" title="Eliminar"></i>
                                    </button>
                                    <button class="btn btn-datatable btn-icon btn-transparent-dark" (click)="lockUser(usuario.id_usuario, usuario.nombre + ' ' + usuario.apellido_paterno, 0)" *ngIf="usuario.estado == 1">
                                        <i class="fas fa-ban" title="Desactivar"></i>
                                    </button>
                                    <button class="btn btn-datatable btn-icon btn-transparent-dark" (click)="lockUser(usuario.id_usuario, usuario.nombre + ' ' + usuario.apellido_paterno, 1)" *ngIf="usuario.estado == 0">
                                        <i class="far fa-check-circle" title="Activar"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="card-footer bg-black border-top-0">
                    <div class="row">
                        <div class="col">
                            <span class="text-xs d-flex">
                                Total de Resultados{{cantResultados}}
                            </span>
                        </div>
                        <div class="col" align="right">
                            <button type="button" class="btn btn-sm btn-green" (click)="descargarExcel()">
                                <i class="fas fa-file-excel mr-2"></i>
                                Descargar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-inicio>