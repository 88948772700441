<app-inicio NavTitleGlobal="nucleo">
    <header class="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
        <div class="container-fluid">
            <div class="page-header-content">
                <div class="row align-items-center justify-content-between pt-3">
                    <div class="col-auto mb-3">
                        <h1 class="page-header-title">
                            <div class="page-header-icon">
                                <i class="fas fa-calendar-day"></i>
                            </div>
                            Agenda de Visitas
                        </h1>
                    </div>
                    <div class="col-auto mb-3 text-left" *ngIf="!loading">
                        <button id="btnAgregarVisita" class="btn btn-black btn-sm mr-2" type="button" (click)="calendario()" *ngIf="viewHistorial">
                            <i class="fa fa-calendar-alt mr-1"></i>
                            Calendario
                        </button>
                        <button id="btnAgregarVisita" class="btn btn-blue btn-sm mr-2" type="button" data-toggle="modal" data-target="#agendarHoraModal">
                            <i class="fa fa-plus mr-1"></i>
                            Agendar Hora
                        </button>
                        <button id="btnSuspenderVisitas" class="btn btn-red btn-sm mr-2" type="button" data-toggle="modal" data-target="#suspenderHoraModal" *ngIf="perfilUsuario >= 2">
                            <i class="fa fa-times mr-1"></i>
                            Suspender Horas
                        </button>
                        <button id="btnHistorialVisita" class="btn btn-black btn-sm" type="button" (click)="historial()" *ngIf="!viewHistorial">
                            <i class="fa fa-clipboard-list-check mr-1"></i>
                            Historial
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <div class="container mt-4">
        <app-loading *ngIf="loading"></app-loading>
        <div class="row text-center" *ngIf="!loading && !viewHistorial">
            <div class="col-md-4">
                <div class="btn-group">
                    <div class="btn btn-outline-black-cs btn-sm" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                        <i class="fas fa-chevron-left mr-2"></i>
                        Anterior
                    </div>
                    <div class="btn btn-black btn-sm" mwlCalendarToday [(viewDate)]="viewDate">
                        Actual
                    </div>
                    <div class="btn btn-outline-black-cs btn-sm" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                        <span class="mr-2">Siguiente</span>
                        <i class="fas fa-chevron-right"></i>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <h4>{{ viewDate | date:'fullDate' }}</h4>
            </div>
            <div class="col-md-4">
                <div class="btn-group">
                    <div class="btn btn-outline-black-cs btn-sm" (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
                        Mes
                    </div>
                    <div class="btn btn-outline-black-cs btn-sm" (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
                        Semana
                    </div>
                    <div class="btn btn-outline-black-cs btn-sm" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
                        Dia
                    </div>
                </div>
            </div>
        </div>
        <br *ngIf="!loading && !viewHistorial">
        <div [ngSwitch]="view" *ngIf="!loading && !viewHistorial">
            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" [excludeDays]="excludeDays" (dayClicked)="dayClicked($event.day)">
            </mwl-calendar-month-view>
            <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [dayStartHour]="9" [dayEndHour]="17" [excludeDays]="excludeDays">
            </mwl-calendar-week-view>
            <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [dayStartHour]="9" [dayEndHour]="17">
            </mwl-calendar-day-view>
        </div>
        <div class="card card-header-actions mb-4" *ngIf="viewHistorial && !loading">
            <div class="card-header">
                <div align="left">
                    <span class="text-black">Visitas</span>
                </div>
                <div class="row">
                    <div class="input-group input-group-joined input-group-solid input-group-sm col mr-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                        <input class="form-control form-control-sm" [(ngModel)]="filterVisita.marca" id="inputFiltroName" type="text" placeholder="Filtro por marca" aria-label="Search" maxlength="50">
                    </div>
                    <div class="input-group input-group-joined input-group-solid input-group-sm col mr-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                        <input class="form-control form-control-sm" [(ngModel)]="filterVisita.tipo" id="inputFiltroTipo" type="text" placeholder="Filtro por tipo" aria-label="Search" maxlength="50">
                    </div>
                    <div class="input-group input-group-joined input-group-solid input-group-sm col mr-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fa fa-hourglass-start"></i>
                            </span>
                        </div>
                        <dp-date-picker theme="dp-material" class="date-picker form-control form-control-sm" id="inputFiltroFechaIni" [(ngModel)]="rangoInicial" [config]="datePickerConfig2" placeholder="Filtrar desde" aria-label="Search" (change)="updateSearchFecha()"></dp-date-picker>
                    </div>
                    <div class="input-group input-group-joined input-group-solid input-group-sm col mr-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fa fa-hourglass-end"></i>
                            </span>
                        </div>
                        <dp-date-picker theme="dp-material" class="date-picker form-control form-control-sm" id="inputFiltroFechaFin" [(ngModel)]="rangoFinal" [config]="datePickerConfig2" placeholder="Filtrar hasta" aria-label="Search" (change)="updateSearchFecha()"></dp-date-picker>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="datatable dataTables_scrollBody">
                    <table class="table table-bordered table-hover" id="dataTable" width="100%" cellspacing="0">
                        <thead class="table-black">
                            <tr>
                                <th>N°</th>
                                <th>Tipo Visita</th>
                                <th>Marca</th>
                                <th>Fecha Visita</th>
                                <th>Hora Visita</th>
                                <th>Usuario</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let visita of historialVisitas | tableAgendaFilter: filterVisita.marca: filterVisita.tipo: filterVisita.fecha_visita; let i = index">
                                <td>{{i+1}}</td>
                                <td>{{visita.tipo}}</td>
                                <td>{{visita.marca}}</td>
                                <td>{{visita.fecha_visita | date:'fullDate'}}</td>
                                <td>{{visita.hora_visita}}</td>
                                <td>{{visita.usuario}}</td>
                                <td>{{visita.estado}}</td>
                            </tr>
                            <tr class="text-center" *ngIf="historialVisitas.length == 0">
                                <td colspan="7">Sin registro de visitas</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="card-footer bg-black border-top-0">
                    <div class="row">
                        <div class="col">
                            <span class="text-xs d-flex">
                                Total de Resultados: {{totalResultados}}
                            </span>
                        </div>
                        <div class="col" align="right">
                            <button type="button" class="btn btn-sm btn-green" (click)="descargarExcel()">
                                <i class="fas fa-file-excel mr-2"></i>
                                Descargar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Large modal -->
    <div class="modal fade" id="agendarHoraModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header bg-black">
                    <span class="modal-title text-white">
                        <i class="far fa-calendar-edit"></i>
                        {{tituloModal}}
                    </span>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true">×</span>
                    </button>
                </div>
                <form autocomplete="off" (ngSubmit)="sendNewVisita()" [formGroup]="formVisita">
                    <div class="modal-body">
                        <div class="form-row justify-content-center">
                            <div class="form-group col-md-8">
                                <label class="small mb-1" for="ddlTipoVisita">Tipo de Visita<span class="text-danger">*</span></label>
                                <select class="form-control {{validarCampo('id_tipo')}}" id="ddlTipoVisita" formControlName="id_tipo">
                                    <option value="1" selected>Reposición de Productos</option>
                                    <option value="2">Retiro de Productos</option>
                                </select>
                                <div class="invalid-feedback">Seleccione una opción</div>
                            </div>
                        </div>
                        <div class="form-row justify-content-center">
                            <div class="form-group col-md-8">
                                <label class="small mb-1" for="ddlSucursal">Seleccione Sucursal<span class="text-danger">*</span></label>
                                <select class="form-control {{validarCampo('id_sucursal')}}" id="ddlSucursal" formControlName="id_sucursal" (ngModelChange)="cambiarHoraVisita()">
                                    <option value="" selected>Seleccione Sucursal</option>
                                    <option value="{{sucursal.id_sucursal}}" *ngFor="let sucursal of sucursalesDisponibles">{{sucursal.nombre}}</option>
                                </select>
                                <div class="invalid-feedback">Seleccione una sucursal válida</div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label class="small mb-1" for="inputFecha">Fecha Visita<span class="text-danger">*</span></label>
                                <dp-date-picker theme="dp-material" class="date-picker form-control {{validarCampo('fecha_visita')}}" id="inputFecha" formControlName="fecha_visita" [config]="datePickerConfig" placeholder="Seleccione la fecha" (ngModelChange)="cambiarHoraVisita()"></dp-date-picker>
                                <div class="invalid-feedback">Seleccione una fecha válida</div>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="small mb-1" for="ddlHora">Hora de Visita<span class="text-danger">*</span></label>
                                <select class="form-control {{validarCampo('hora_visita')}}" id="ddlHora" formControlName="hora_visita" [disabled]="formVisita.controls.hora_visita.errors">
                                    <option value="" selected>Seleccione hora</option>
                                    <option value="{{hora}}" *ngFor="let hora of horasDisponibles">{{hora}}</option>
                                </select>
                                <div class="invalid-feedback">Seleccione una hora válida</div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label for="textareaComentario" class="small mb-1">Comentario</label>
                                <textarea class="form-control {{validarCampo('comentario')}}" id="textareaComentario" formControlName="comentario" placeholder="Ingrese un comentario a considerar en su visita"></textarea>
                                <div class="invalid-feedback">Comentario de 10 a 100 caracteres</div>
                            </div>
                        </div>
                        <small id="help" class="form-text text-muted"><b>NOTA:</b> Cada visita tiene una duración de máximo 30 minutos.</small>
                    </div>
                    <div class="modal-footer">
                        <button id="btnCancelarVisita" class="btn btn-outline-black-cs rounded-pill px-4 mr-2 my-1" type="button" data-dismiss="modal">
                            Cancelar
                        </button>
                        <button class="btn btn-black rounded-pill px-4 mr-2 my-1" type="submit" [disabled]="loadingForm">
                            <span *ngIf="!loadingForm">Guardar</span>
                            <span *ngIf="loadingForm">
                                Guardando
                                <i class="fas fa-sync fa-spin fa-lg"></i>
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal fade" id="suspenderHoraModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header bg-black">
                    <span class="modal-title text-white">
                        <i class="far fa-calendar-edit"></i>
                        Suspender Horas
                    </span>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true">×</span>
                    </button>
                </div>
                <form autocomplete="off" (ngSubmit)="cancelarHora()">
                    <div class="modal-body">
                        <div class="form-row justify-content-center">
                            <div class="form-group col-md-8">
                                <label class="small mb-1" for="inputFechaCH">Fecha de Cancelación<span class="text-danger">*</span></label>
                                <dp-date-picker theme="dp-material" class="date-picker form-control" id="inputFechaCH" name="inputFechaCH" [(ngModel)]="horaCancelar.fecha" [config]="datePickerConfig" placeholder="Seleccione la fecha"></dp-date-picker>
                                <div class="invalid-feedback">Seleccione una fecha válida</div>
                            </div>
                        </div>
                        <div class="form-row justify-content-center">
                            <div class="form-group col-md-8">
                                <label class="small mb-1" for="hrInicioCancelar">Hora Desde<span class="text-danger">*</span></label>
                                <select class="form-control" id="hrInicioCancelar" name="hrInicioCancelar" [(ngModel)]="horaCancelar.horaInicio">
                                    <option value="">Seleccione</option>
                                    <option value="09:00">09:00</option>
                                    <option value="10:00">10:00</option>
                                    <option value="11:00">11:00</option>
                                    <option value="12:00">12:00</option>
                                    <option value="13:00">13:00</option>
                                    <option value="14:00">14:00</option>
                                    <option value="15:00">15:00</option>
                                    <option value="16:00">16:00</option>
                                    <option value="17:00">17:00</option>
                                </select>
                                <div class="invalid-feedback">Seleccione una opción</div>
                            </div>
                        </div>
                        <div class="form-row justify-content-center">
                            <div class="form-group col-md-8">
                                <label class="small mb-1" for="hrFinCancelar">Hora Hasta<span class="text-danger">*</span></label>
                                <select class="form-control" id="hrFinCancelar" name="hrFinCancelar" [(ngModel)]="horaCancelar.horaFin">
                                    <option value="">Seleccione</option>
                                    <option value="09:00">09:00</option>
                                    <option value="10:00">10:00</option>
                                    <option value="11:00">11:00</option>
                                    <option value="12:00">12:00</option>
                                    <option value="13:00">13:00</option>
                                    <option value="14:00">14:00</option>
                                    <option value="15:00">15:00</option>
                                    <option value="16:00">16:00</option>
                                    <option value="17:00">17:00</option>
                                </select>
                                <div class="invalid-feedback">Seleccione una opción</div>
                            </div>
                        </div>
                        <div class="form-row justify-content-center">
                            <div class="form-group col-md-8">
                                <label class="small mb-1" for="ddlSucursalCH">Sucursal<span class="text-danger">*</span></label>
                                <select class="form-control" id="ddlSucursalCH" name="ddlSucursalCH" [(ngModel)]="horaCancelar.id_sucursal">
                                    <option value="">Seleccione</option>
                                    <option value="{{sucursal.id_sucursal}}" *ngFor="let sucursal of sucursalesDisponibles">{{sucursal.nombre}}</option>
                                </select>
                                <div class="invalid-feedback">Seleccione una sucursal válida</div>
                            </div>
                        </div>
                        <small id="help" class="form-text text-muted"><b>NOTA:</b> Si se suspende una visita con hora tomada, está quedará anulada y se le informará a la marca.</small>
                    </div>
                    <div class="modal-footer">
                        <button id="btnCancelarHoraCancelar" type="button" class="btn btn-outline-black-cs rounded-pill px-4 mr-2 my-1" data-dismiss="modal">
                            Cerrar
                        </button>
                        <button type="submit" class="btn btn-black rounded-pill px-4 mr-2 my-1" [disabled]="loadingForm">
                            <span *ngIf="!loadingForm">Anular</span>
                            <span *ngIf="loadingForm">
                                Anulando
                                <i class="fas fa-sync fa-spin fa-lg"></i>
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</app-inicio>