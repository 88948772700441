import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Usuario } from 'src/app/models/usuario.model';
import Swal from 'sweetalert2';
import { AgregarService } from '../agregar/agregar.service';
import { UsuarioSesion } from '../../../models/usuario.model';
import { environment } from 'src/environments/environment';
import { AccesoService } from '../../acceso/acceso.service';

@Component({
  selector: 'app-cuenta',
  templateUrl: './cuenta.component.html',
  styleUrls: ['./cuenta.component.scss']
})
export class CuentaComponent implements OnInit {
  loading: boolean = true;
  loadingForm: boolean = false;
  vistaCuenta: boolean = true;
  contrasenas: string[] = ["", ""];
  usuario: UsuarioSesion = new UsuarioSesion();
  url_imagen = environment.IMG_URL

  formUsuario: FormGroup = this.fb.group({
    id_usuario: [],
    nombre: [ , [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
    apellido_paterno: [ , [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
    apellido_materno: [ , [Validators.minLength(3), Validators.maxLength(25)]],
    fecha_registro: [],
    email: [ , [Validators.required, Validators.email, Validators.maxLength(100)]],
    telefono: [ , [Validators.minLength(7), Validators.maxLength(9)]],
    id_perfil: [1, [Validators.required]],
    perfil: [],
    activo: [],
    contrasena: [ , [Validators.minLength(6), Validators.maxLength(15)]],
    marcas: [],
    accesos: []
  });

  constructor(private fb: FormBuilder, private servicio: AgregarService, private servicioAcceso: AccesoService) { }

  ngOnInit(): void {
    this.loadCuentaUsuario();
    this.loading = false;
  }

  validarCampo(campo: string) {
    const controlTemp = this.formUsuario.controls[campo];
    if (controlTemp.errors && controlTemp.touched) {
      return 'is-invalid';
    } else if (!controlTemp.errors && controlTemp.touched && controlTemp.value != null) {
      return 'is-valid';
    }
  }

  cambiarVista() {
    this.vistaCuenta = !this.vistaCuenta;
  }

  updateAccount() {
    if (this.formUsuario.invalid) {
      this.formUsuario.markAllAsTouched();
      return;
    }

    this.loadingForm = true;
    let usuarioTemp: Usuario = this.formUsuario.value;
    this.servicio.updateCreateUsuario('update', usuarioTemp).subscribe(resp => {
      if (resp >= 1) {
        Swal.fire('¡Listo!', 'Su cuenta fue actualizada correctamente.', 'success');
      } else {
        Swal.fire('¡Oops!', 'Ocurrió un problema, inténtelo más tarde.', 'error');
      }
      this.loadingForm = false;
    });
  }

  updatePassword() {
    this.loadingForm = true;
    if ((this.contrasenas[0] != this.contrasenas[1]) || this.contrasenas[0].trim() == "") {
      Swal.fire('¡Oops!', 'Las contrasenas no coinciden o están vaciás', 'error');
      this.contrasenas = ["", ""];
      this.loadingForm = false;
      return;
    }
    const token = localStorage.getItem('token');
    this.servicioAcceso.changePassword(this.contrasenas[0], token).subscribe(resp => {
      if (resp == 1) {
        Swal.fire('¡Listo!', 'Su contraseña fue actualizada correctamente.', 'success');
      } else {
        Swal.fire('¡Oops!', 'Ocurrió un problema, inténtelo más tarde.', 'error');
      }
      this.loadingForm = false;
    });
  }

  loadCuentaUsuario() {
    const temp = localStorage.getItem('usuario');
    if (temp) {
      this.usuario = JSON.parse(temp);
      this.formUsuario.setValue(this.usuario);
    } else {
      Swal.fire('¡Oops!', 'No pudimos obtener la información de su cuenta.', 'error');
    }
  }
}
