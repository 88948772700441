import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { GestionService } from '../gestion.service';

@Component({
  selector: 'app-carga-masiva',
  templateUrl: './carga-masiva.component.html',
  styleUrls: ['./carga-masiva.component.scss']
})
export class CargaMasivaComponent implements OnInit {
  loading: boolean = true;
  loadingForm: boolean = false;
  nameFileLoadUsers: string = "Seleccione archivo";
  nameFileLoadMarcas: string = "Seleccione archivo";
  selectedFile: any;
  nameFileLoad: any;
  reporteCargaMasiva: any = [];

  constructor(private servicio: GestionService) { }

  ngOnInit(): void {
    this.loading = false;
  }

  onFileChanged(event, tipo: string) {
    const file = event.target.files[0];
    if (file.length === 0)
        return;

    const mimeType = file.type;
    if (mimeType.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') == null) {
      Swal.fire('Oops...', '¡Formato erróneo del archivo!', 'error');
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file); 
    reader.onload = () => {
      if (tipo == 'users') {
        this.nameFileLoadUsers = file.name;
      } else {
        this.nameFileLoadMarcas = file.name;
      }
      this.nameFileLoad = file.name;
      const archivoExcel: any = reader.result;
      this.selectedFile = {
        fileName: file.name,
        fileType: file.type,
        fileExtension: file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2),
        value: archivoExcel.split(',')[1]
      };
    }
  }

  guardarUsuarios() {
    if (this.selectedFile != null) {
      this.loadingForm = true;
      this.servicio.uploadUsuariosMasivo(this.selectedFile).subscribe(resp => {
        this.loadingForm = false;
        if (resp.length != 0) {
          this.reporteCargaMasiva = resp;
          document.getElementById('btnMostrarReporte').click();
        } else {
          Swal.fire('Error al cargar archivo', 'Vuelva a intentarlo más tarde o verifique el formato del archivo', 'error');
          return;
        }
      }, (error) => {
        this.loadingForm = false;
        console.log(error);
        Swal.fire('Oops...', 'Ocurrió un error inesperado, vuelva a intentalo', 'error');
      });
    } else {
      Swal.fire('Oops...', 'Debe seleccionar el archivo excel con los usuarios a registrar', 'error');
    }
  }
}
