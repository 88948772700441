export class Visita {
    id_visita: number;
    id_tipo: number;
    tipo: string;
    id_usuario: number;
    usuario: string;
    id_marca: string;
    marca: string;
    fecha_visita: string;
    hora_visita: string;
    comentario: string;
    id_sucursal: string;
    nombre_sucursal: string;
    id_estado: number;
    estado: number;
}

export class Sucursal {
    id_sucursal: number;
    nombre: string;
    direccion: string;
}