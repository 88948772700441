import { Injectable } from '@angular/core';
import { CanActivate, Route, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GeneralesService } from './generales.service';

@Injectable({
  providedIn: 'root'
})
export class NonAuthGuard  {

  constructor(private router: Router) {}

  canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
  ):
      | Observable<boolean | UrlTree>
      | Promise<boolean | UrlTree>
      | boolean
      | UrlTree {
      if (!localStorage.getItem('token')) {
          return true;
      }
      this.router.navigate(['/principal/agenda']);
      return false;
  }
  canActivateChild(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
  ):
      | Observable<boolean | UrlTree>
      | Promise<boolean | UrlTree>
      | boolean
      | UrlTree {
      return this.canActivate(next, state);
  }
}