import { Component, OnInit } from '@angular/core';
import { Documento } from 'src/app/models/listar.model';
import { Marca } from 'src/app/models/marca.model';
import Swal from 'sweetalert2';
import { ReportesService } from '../reportes.service';
import { GeneralesService } from '../../generales.service';
import { UsuarioSesion } from 'src/app/models/usuario.model';

@Component({
  selector: 'app-ventas',
  templateUrl: './ventas.component.html',
  styleUrls: ['./ventas.component.scss']
})
export class VentasComponent implements OnInit {

  loading: boolean = true;
  filter: Documento = new Documento();
  ventas: Documento[] = [];
  totalResultados: string = "0";
  rangoInicial: string;
  rangoFinal: string;
  filtroDeFecha: string;
  paginacion = 0;
  paginacionNext: boolean = true;
  productosSinTipo: boolean = false;
  perfilUsuario: number = Number(localStorage.getItem('acceso')[0]);
  usuario: UsuarioSesion = JSON.parse(localStorage.getItem('usuario'));
  public ventaMovimientoEditar: Documento = new Documento();

  //Contenedor de ventas seleccionadas
  documentosSeleccionadas: number[] = [];

  // Marcas registradas
  marcasRegistradas: Marca[] = [];
  marcaSeleccionada: string;

  datePickerConfig = {
    firstDayOfWeek: 'mo',
    format: "DD-MM-YYYY",
    locale: 'es'
  };

  activarSelector: boolean = false;

  constructor(private servicio: ReportesService, private servicioGeneral: GeneralesService) { }

  ngOnInit(): void {
    if (localStorage.getItem('marca_session')) {
      const idMarca = localStorage.getItem('marca_session');
      this.cleanFiltros();
      this.loadVentasMarca(idMarca);
    } else {
      Swal.fire('Seleccione una Marca', 'Debe tener una marca asignada para poder visualizar este módulo.', 'info');
    }
    const hoy: Date = new Date();
    this.filtroDeFecha = ' en el periodo ' + ((hoy.getMonth() + 1) > 9 ? (hoy.getMonth() + 1) : '0'+(hoy.getMonth() + 1)) + '-' + hoy.getFullYear();
  }

  loadVentasMarca(id: string) {
    if (this.perfilUsuario <= 1 && (id == '0' || id == null)) {
      Swal.fire('¡Sin Marca!', 'No tienes una marca asignada, comunícate con Casa Moda.', 'info');
      this.loading = false;
      return;
    }
    this.servicio.getDocumentoByMarca(id).subscribe(resp => {
      this.ventas = resp;
      this.nextAndPrevious(3);
      this.loading = false;
    });
  }

  obtenerDetalleVenta(venta: Documento) {
    if (!this.activarSelector) {
      venta.showMore = !venta.showMore;
      return;
    } else {
      const pos = this.documentosSeleccionadas.indexOf(venta.id_documento_detalle);
      if (venta.seleccionado) {
        this.documentosSeleccionadas.splice(pos, 1);
        venta.seleccionado = false;
      } else {
        if (pos == -1) {
          this.documentosSeleccionadas.push(venta.id_documento_detalle);
          venta.seleccionado = true;
        }
      }
    }
    venta.loadingDetalle = false;
  }

  cargarMovimientosFecha() {
    this.cleanFiltros();
    if (this.rangoInicial == null || this.rangoFinal == null) {
      Swal.fire('Error', 'Debe seleccionar ambas fechas del rango de búsqueda', 'error');
      return;
    }
    this.filtroDeFecha = ' en el rango seleccionado';
    this.loading = true;
    const idMarca = localStorage.getItem('marca_session');
    this.servicio.getDocumentoFechaByMarca(idMarca, this.rangoInicial, this.rangoFinal).subscribe(resp => {
      this.productosSinTipo = false;
      this.cleanPaginacion();
      this.ventas = resp;
      this.nextAndPrevious(3);
      this.loading = false;
    });
  }

  cargarMovimientosSinTipo() {
    this.loading = true;
    const idMarca = localStorage.getItem('marca_session');
    this.servicio.getDocumentoFechaByMarca(idMarca, this.rangoInicial, this.rangoFinal, 'Sin Tipo').subscribe(resp => {
      this.cleanPaginacion();
      this.ventas = resp;
      this.nextAndPrevious(3);
      this.loading = false;
      this.productosSinTipo = true;
    });
  }

  nextAndPrevious(pos: number) {
    if (pos == 1) {
      this.paginacion = this.paginacion + 10;
    } else if(pos == 0) {
      this.paginacion = this.paginacion - 10;
    }

    const restante = this.ventas.length - this.paginacion - 10;
    if (restante < 0) {
      this.paginacionNext = false;
    } else {
      this.paginacionNext = true;
    }
    
    this.totalResultados = `Mostrando ${(restante < 0) ? (restante * -1) : 10} de ${(restante > 0) ? restante : 0} restantes / ${this.ventas.length} registros`;
  }

  cleanPaginacion() {
    this.paginacion = 0;
    this.paginacionNext = true;
  }

  cleanFiltros() {
    this.filter.sucursal = '';
    this.filter.tipo_de_movimiento = '';
  }

  cambiarMarcaDelMovimiento() {
    if (this.marcaSeleccionada == null || this.marcaSeleccionada == '0') {
      Swal.fire('¡Oops!', 'Seleccione una marca', 'error');
      return;
    }
    const nombreMarca: string = this.marcasRegistradas.find(e => e.id_marca == this.marcaSeleccionada).nombre ?? 'Sin Tipo';
    this.servicio.setChangeMarcaMovimientos(this.marcaSeleccionada, nombreMarca, this.documentosSeleccionadas).subscribe(resp => {
      if (resp) {
        document.getElementById('btn-close-modal-change-marca').click();
        Swal.fire('¡Listo!', 'Cambio de marca realizada', 'success');
        this.documentosSeleccionadas = [];
        if (this.productosSinTipo) {
          this.cargarMovimientosSinTipo();
        } else {
          this.ngOnInit();
        }
      } else {
        Swal.fire('¡Oops!', 'Error vuelva a intentarlo', 'error');
      }
    });
  }

  loadMarcas(codificado = false) {
    this.servicio.getAllMarcas(codificado).subscribe(resp => {
      this.marcasRegistradas = resp;
    })
  }

  descargarExcel() {
    const ventasModelExport = [];
    let totalesNeto = 0, totalesIVA = 0, totalesTotal = 0;
    this.ventas.forEach(venta => {
      const ventaExportar = (({ id_documento, id_marca, vendedor, cliente, rut_cliente, tipo_documento, nro_documento, fecha_documento, lista_de_precios, otros_atributos, showMore, loadingDetalle, seleccionado, ...m }) => m) (venta);
      totalesNeto = totalesNeto + Number(ventaExportar.precio_neto_unitario);
      totalesIVA = totalesIVA + Number(ventaExportar.subtotal_impuestos);
      totalesTotal = totalesTotal + Number(ventaExportar.subtotal);
      const ventaMarca = {
        'N° Referencia': ventaExportar.id_documento_detalle,
        'Tipo de Movimiento': ventaExportar.tipo_de_movimiento,
        Sucursal: ventaExportar.sucursal_origen,
        'Fecha de Venta': ventaExportar.fecha_venta,
        'Nombre Marca': ventaExportar.tipo_de_producto_servicio,
        SKU: ventaExportar.sku,
        'Nombre Producto': ventaExportar.producto_servicio,
        Variante: ventaExportar.variante,
        Precio: Number(ventaExportar.precio_neto_unitario),
        Cantidad: Number(ventaExportar.cantidad),
        IVA: Number(ventaExportar.subtotal_impuestos),
        'Sub-Total': Number(ventaExportar.subtotal),
        'Método de Pago': ventaExportar.metodo_pago
      }
      ventasModelExport.push(ventaMarca);
    });
    ventasModelExport.push(['MONTO TOTAL NETO', totalesNeto]);
    ventasModelExport.push(['MONTO TOTAL IVA', totalesIVA]);
    ventasModelExport.push(['MONTO TOTAL', totalesTotal]);
    const nombreMarca = localStorage.getItem('marca_name');
    this.servicioGeneral.exportAsExcelFile(ventasModelExport, 'ventas_'+(nombreMarca ? nombreMarca : 'casamoda'));
    this.servicio.setReportDownloader(this.usuario.id_usuario ?? 0).subscribe(
      resp => {
        console.log(resp);
      }
    );
  }

  descargarExcelByBSALE(numberIdRequest: number = 0) {
    let url = '';

    Swal.fire({
      title: 'Generando Documento',
      text: 'Espere un momento por favor',
      timer: 5000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const idMarca = localStorage.getItem('marca_session') ?? '0';
        this.servicio.getRequestIdDownloaderBsaleFile('download_sales_report', idMarca, this.rangoInicial, this.rangoFinal, '', 0, numberIdRequest).subscribe(
          resp => {
            if (typeof resp == 'number') {
              numberIdRequest = resp;
            } else {
              url = resp;
            }
          }, error => {
            console.error(error);
          }
        );
      },
      willClose: () => {
        if (url === '' && numberIdRequest > 0) {
          this.descargarExcelByBSALE(numberIdRequest);
        } else if (url !== '') {
          window.open(url);
          Swal.fire('Descargando Excel', 'Se empezará a descargar el reporte Excel.', 'success');
        } else {
          Swal.fire('¡Ups!', 'Ocurrió un error al generar el reporte. ¡Vuelve a intentarlo!', 'error');
        }
      }
    });
  }

  changeActivarSelector() {
    this.activarSelector = !this.activarSelector;
  }

  editarMovimientoVenta(venta: Documento) {
    this.loadMarcas(true);
    venta.showMore = !venta.showMore;
    this.ventaMovimientoEditar = venta;
  }

  guardarMovimientoVenta() {
    let tipoDocumento = '';
    switch ( this.ventaMovimientoEditar.id_tipo_documento ) {
      case '1':
        tipoDocumento = 'BOLETA ELECTRÓNICA';
        break;

      case '5':
        tipoDocumento = 'FACTURA ELECTRÓNICA';
        break;

      case '2':
        tipoDocumento = 'NOTA DE CRÉDITO ELECTRÓNICA';
        break;

      case '17':
        tipoDocumento = 'NOTA DE DÉBITO ELECTRÓNICA';
        break;

      default:
        tipoDocumento = 'NO IDENTIFICADA';
        break;
    }
    const documentEdit = {
      id_documento_detalle: this.ventaMovimientoEditar.id_documento_detalle,
      id_tipo_documento: this.ventaMovimientoEditar.id_tipo_documento,
      tipo_documento: tipoDocumento,
      nro_documento: this.ventaMovimientoEditar.nro_documento,
      monto_transferencia: (this.ventaMovimientoEditar.metodo_pago == 'Transferencia Electronica' ? this.ventaMovimientoEditar.precio_neto_unitario : 0),
      monto_debito: (this.ventaMovimientoEditar.metodo_pago == 'Tarjeta Débito' ? this.ventaMovimientoEditar.precio_neto_unitario : 0),
      monto_credito: (this.ventaMovimientoEditar.metodo_pago == 'Tarjeta Crédito' ? this.ventaMovimientoEditar.precio_neto_unitario : 0),
      monto_devolucion: (this.ventaMovimientoEditar.metodo_pago == 'Nota Crédito Devolución' ? this.ventaMovimientoEditar.precio_neto_unitario : 0),
      monto_mercado_pago: (this.ventaMovimientoEditar.metodo_pago == 'Mercado Pago' ? this.ventaMovimientoEditar.precio_neto_unitario : 0),
      monto_gift_card: (this.ventaMovimientoEditar.metodo_pago == 'Gift Card' ? this.ventaMovimientoEditar.precio_neto_unitario : 0),
      monto_efectivo: (this.ventaMovimientoEditar.metodo_pago == 'Efectivo' ? this.ventaMovimientoEditar.precio_neto_unitario : 0),
      id_marca: this.ventaMovimientoEditar.id_marca,
      tipo_movimiento: this.ventaMovimientoEditar.tipo_de_movimiento,
      sucursal: this.ventaMovimientoEditar.sucursal,
      vendedor: this.ventaMovimientoEditar.vendedor,
      cliente: this.ventaMovimientoEditar.cliente,
      rut_cliente: this.ventaMovimientoEditar.rut_cliente,
      id_documento: this.ventaMovimientoEditar.id_documento,
      marca: this.ventaMovimientoEditar.tipo_de_producto_servicio,
      sku: this.ventaMovimientoEditar.sku,
      producto_servicio: this.ventaMovimientoEditar.producto_servicio,
      variante: this.ventaMovimientoEditar.variante,
      precio: this.ventaMovimientoEditar.precio_neto_unitario,
      cantidad: this.ventaMovimientoEditar.cantidad,
      iva: this.ventaMovimientoEditar.subtotal_impuestos,
      subtotal: this.ventaMovimientoEditar.subtotal
    }
    this.servicio.changeSalesDocument(documentEdit).subscribe(
      resp => {
        if (resp == 1) {
          document.getElementById('btn-close-modal-change-venta').click();
          Swal.fire('¡Listo!', 'Actualización aplicada correctamente.', 'success');
        } else {
          console.error(resp);
          Swal.fire('¡Ups!', 'Ocurrió un error al actualizar la venta. ¡Vuelve a intentarlo!', 'error');
        }
      }
    );
  }

  removeVentaMovimiento() {
    this.ventaMovimientoEditar = new Documento();
  }

  updateTipoProductoServicio() {
    const nombreMarca = this.marcasRegistradas.find(marca => marca.id_marca == this.ventaMovimientoEditar.id_marca).nombre;
    if (nombreMarca) {
      this.ventaMovimientoEditar.tipo_de_producto_servicio = nombreMarca ?? '';
    }
  }

  updateMontoTotal() {
    this.ventaMovimientoEditar.subtotal = Number(this.ventaMovimientoEditar.precio_neto_unitario) + Number(this.ventaMovimientoEditar.subtotal_impuestos)
  }
}
