import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableFilterReport'
})
export class TableFilterReportPipe implements PipeTransform {

  transform(items: any[], producto_servicio: string, tipo_de_movimiento: string, sucursal: string) {
    if (!items && !producto_servicio && !tipo_de_movimiento && !sucursal) {
      return items;
    }

    return items.filter(item => {
      if (producto_servicio && item.Producto.toLowerCase().indexOf(producto_servicio.toLowerCase()) === -1) {
        return false;
      }
      if (tipo_de_movimiento && item.TipoMovimiento.toLowerCase().indexOf(tipo_de_movimiento.toLowerCase()) === -1) {
        return false;
      }
      if (sucursal && item.Sucursal.toLowerCase().indexOf(sucursal.toLowerCase()) === -1) {
        return false;
      }
      return true;
    });
  }
}