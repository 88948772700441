<div id="layoutError">
    <div id="layoutError_content">
        <main>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-6">
                        <div class="text-center mt-4">
                            <img class="img-fluid p-4" src="assets/img/illustrations/404-error-secondary.svg" alt="" />
                            <p class="lead">Esta URL solicitada no se encontró en este servidor.</p>
                            <a class="text-arrow-icon text-black" routerLink="/">
                                <i class="fas fa-angle-double-left"></i>
                                &emsp;Regresar a Casa Moda
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
    <div id="layoutError_footer">
        <app-footer></app-footer>
    </div>
</div>