<app-inicio>
    <!-- Main page content-->
    <div class="container mt-4">
        <!-- Example DataTable for Dashboard Demo-->
        <div class="card card-header-actions mb-4">
            <div class="card-header">
                <div align="left">
                    <span class="text-black">
                        <i class="far fa-file-invoice mr-2"></i>
                        Documentos Emitidos
                    </span>
                </div>
            </div>
            <app-loading *ngIf="loading"></app-loading>
            <div class="card-body" *ngIf="!loading">
                <div class="datatable dataTables_scrollBody">
                    <table class="table table-bordered table-hover" id="dataTable" width="100%" cellspacing="0">
                        <thead class="table-black">
                            <tr>
                                <th colspan="2">N° Documento</th>
                                <th>Documento</th>
                                <th>Fecha Emisión</th>
                                <th>Total</th>
                                <th>PDF Documento</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="documentos.length == 0">
                                <td colspan="8" class="text-center">
                                    Sin registros de documentos
                                </td>
                            </tr>
                            <ng-template ngFor let-i="index" let-c="count" let-item [ngForOf]="documentos">
                                <tr class="clickable">
                                    <td class="text-center" (click)="obtenerDetalleDocumento(item)">
                                        <button class="btn btn-datatable btn-icon btn-transparent-dark">
                                            <i class="fas fa-expand" *ngIf="!item.showMore"></i>
                                            <i class="fas fa-compress-arrows-alt text-danger" *ngIf="item.showMore"></i>
                                        </button>
                                    </td>
                                    <td (click)="obtenerDetalleDocumento(item)">N° {{item.nro_documento}}</td>
                                    <td (click)="obtenerDetalleDocumento(item)">{{item.tipo_documento | titlecase}}</td>
                                    <td (click)="obtenerDetalleDocumento(item)">{{item.fecha_documento}}</td>
                                    <td (click)="obtenerDetalleDocumento(item)">{{item.monto_total | currency:'CLP'}}</td>
                                    <td class="text-center" *ngIf="item.url_documento">
                                        <a href="{{item.url_documento}}" target="_blank" style="text-decoration: none; color: gray;">
                                            <span class="mr-2">Ver documento</span>
                                            <i class="fas fa-file-pdf fa-lg text-red"></i>
                                        </a>
                                    </td>
                                    <td class="text-center" *ngIf="!item.url_documento">
                                        <span class="text-grey mr-2">No disponible</span>
                                        <i class="fas fa-file-pdf"></i>
                                    </td>
                                </tr>
                                <tr *ngIf="item.showMore">
                                    <td colspan="6" class="text-center">
                                        <i class="fa fa-sync fa-spin fa-2x" *ngIf="item.loadingDetalle"></i>
                                        <table class="table table-sm" *ngIf="!item.loadingDetalle" width="100%" cellspacing="0">
                                            <thead>
                                                <tr>
                                                    <th>Cargo/Servicio</th>
                                                    <th>Costo</th>
                                                    <th>IVA</th>
                                                    <th>SubTotal</th>
                                                </tr>
                                            </thead>
                                            <tbody class="notHover">
                                                <tr *ngFor="let subitem of item.documentos">
                                                    <td>{{subitem.producto_servicio}}</td>
                                                    <td>{{subitem.precio_neto_unitario | currency:'CLP'}}</td>
                                                    <td>{{subitem.subtotal_impuestos | currency:'CLP'}}</td>
                                                    <td>{{subitem.subtotal | currency:'CLP'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                </div>
                <div class="card-footer bg-black border-top-0">
                    <span class="text-xs d-flex">
                        Total de Resultados{{adicionalTitulo}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</app-inicio>