import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Usuario } from '../../models/usuario.model';
import { AccesoService } from './acceso.service';
import { ActivatedRoute, Router } from '@angular/router';
import { emailPattern } from '../validators/validadores';

@Component({
  selector: 'app-acceso',
  templateUrl: './acceso.component.html',
  styleUrls: ['./acceso.component.scss']
})
export class AccesoComponent implements OnInit {
  standBy: boolean = false;

  formLogin: FormGroup = this.fb.group({
    id_usuario: [],
    nombre: [],
    apellido_paterno: [],
    apellido_materno: [],
    fecha_registro: [],
    email: [ , [Validators.required, Validators.pattern(emailPattern), Validators.maxLength(100)]],
    telefono: [],
    id_perfil: [],
    perfil: [],
    activo: [],
    contrasena: [ , [Validators.required, Validators.minLength(6), Validators.maxLength(15)]],
    token: [],
    rememberUser: []
  });

  constructor(public fb: FormBuilder, private servicio: AccesoService, private routerModule: ActivatedRoute, private router: Router) {    
    if (localStorage.getItem('token')) {
      this.router.navigate(['/principal/agenda']);
    }
  }

  ngOnInit(): void {
    this.routerModule.params.subscribe(({token}) => {
      if (token) {
        this.loadNewPassword(token);
      }
    });
  }

  forgotpassword() {
    Swal.fire({
      title: 'Recuperar Contraseña',
      text: 'Ingrese su correo electrónico',
      input: 'email',
      showCancelButton: true,
      confirmButtonText: 'Recuperar',
      confirmButtonColor: '#000',
      cancelButtonText: 'Cancelar',
      validationMessage: 'Ingrese un correo electrónico válido',
      showLoaderOnConfirm: true,
      preConfirm: (email) => {
        Swal.fire({
          title: 'Validando información...',
          text: 'Estamos validando su cuenta de acceso.',
          didOpen: () => {
            Swal.showLoading();
          } 
        })
        this.servicio.forgotpassword(email).subscribe(resp => {
          if (resp) {
            Swal.fire('¡Listo!', 'Hemos enviado un correo electrónico, por favor siga las instrucciones adjuntas.', 'success');
          } else {
            Swal.fire('¡Oops!', 'No hemos encontrado su cuenta de acceso.', 'error');
          }
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  validarCampo(campo: string) {
    const controlTemp = this.formLogin.controls[campo];
    if (controlTemp.errors && controlTemp.touched) {
      return 'is-invalid';
    }
  }

  sendLogin() {
    if (this.formLogin.invalid) {
      this.formLogin.markAllAsTouched();
      return;
    }
    this.standBy = true;
    const usuario: Usuario = this.formLogin.value;
    this.servicio.login(usuario).subscribe(resp => {
      if (resp != 0) {
        if (resp == -1) {
          Swal.fire('Acceso Bloqueado', 'Su cuenta ha sido desactivada', 'error');
        } if(resp == -2) {
          Swal.fire('En Mantención', 'Estamos haciendo algunos reparos, por favor, intenta más tarde.', 'info');
        } else {
          localStorage.setItem('token', resp.toString());

          this.router.navigate(['/principal/agenda']);

          if (localStorage.getItem('token')) {
            location.reload();
          }
          this.standBy = false;
        }
      } else {
        this.standBy = false;
        Swal.fire('Accesos no válidos', 'Contraseña o correo electrónico erróneos', 'error');
      }
    }, error => {
      console.error('ERROR: ', error);
      this.standBy = false;
    });
  }

  loadNewPassword(token: string) {
    Swal.fire({
      title: 'Cambiar Contraseña',
      html:
        '<label class="small mb-1" for="swal-input1">Nueva Contraseña</label>'+
        '<input id="swal-input1" type="password" class="form-control" placeholder="Ingrese su nueva contraseña" maxlength="15">'+
        '<br>'+
        '<label class="small mb-1" for="swal-input2">Repita Contraseña</label>'+
        '<input id="swal-input2" type="password" class="form-control" placeholder="Repita su nueva contraseña" maxlength="15" >'+
        '<br>',
      focusConfirm: false,
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#000',
      preConfirm: () => {
        const passUno = (<HTMLInputElement>document.getElementById('swal-input1')).value;
        const passDos = (<HTMLInputElement>document.getElementById('swal-input2')).value;
        if (passUno != passDos || passUno == '') {
          Swal.showValidationMessage(
            'Las contraseñas ingresadas no coinciden.'
          );
        } else {
          this.servicio.changePassword(passUno, token).subscribe(resp => {
            if (resp == 1) {
              Swal.fire('¡Listo!', 'Su contraseña fue actualizada correctamente.', 'success');
            } else {
              Swal.fire('¡Oops!', 'Ocurrió un error, vuelva a intentarlo más tarde.', 'error');
            }
          });
        }
      }
    });
  }
}
