<nav class="topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white" id="sidenavAccordion">
    <!-- Navbar Brand-->
    <!-- * * Tip * * You can use text or an image for your navbar brand.-->
    <!-- * * * * * * When using an image, we recommend the SVG format.-->
    <!-- * * * * * * Dimensions: Maximum height: 32px, maximum width: 240px-->
    <a class="navbar-brand" routerLink="/" style="font-family: none !important;font-size: 23px !important;">&nbsp;C&nbsp;A&nbsp;S&nbsp;A&emsp;M&nbsp;O&nbsp;D&nbsp;A&emsp;</a>
    <!-- Sidenav Toggle Button-->
    <button class="btn btn-icon btn-transparent-dark order-1 order-lg-0 mr-lg-2" onclick="sidebarToggle()">
        <i class="fas fa-bars"></i>
    </button>
    <!-- Navbar Items-->
    <ul class="navbar-nav align-items-center ml-auto">
        <li class="nav-item dropdown no-caret d-none d-sm-block mr-3">
            <a class="nav-link dropdown-toggle" id="navbarDropdownDocs" href="javascript:void(0);" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" *ngIf="perfilUsuario != 3">
                <div class="font-weight-500">{{nombreMarcaSesion}}</div>
                <i class="fas fa-chevron-right dropdown-arrow"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right py-0 mr-sm-n15 mr-lg-0 o-hidden animated--fade-in-up" aria-labelledby="navbarDropdownDocs">
                <span class="titleContent text-center" *ngIf="perfilUsuario != 1 && perfilUsuario != 3" style="padding-bottom: 1rem;">
                    <span class="text-black">Herramienta Administrador</span>
                    <input type="text" class="form-control form-control-sm mt-2" id="inputTxtBuscaMarca" placeholder="Busca una Marca" [(ngModel)]="searchMarcaSesion" (keyup.enter)="teclaPresionada()" (input)="teclaPresionada()" autocomplete="off">
                </span>
                <span class="dropdown-item py-2 clickable" (click)="modeAdministrator()" [class.text-danger]="modoAdministrador" [class.text-green]="!modoAdministrador" *ngIf="perfilUsuario >= 2 && idMarcaSesion != '0' && idMarcaSesion != null">
                    <span class="mr-1" *ngIf="!modoAdministrador">
                        <i class="fas fa-toggle-on mr-2"></i>
                        Activar
                    </span>
                    <span class="mr-1" *ngIf="modoAdministrador">
                        <i class="fas fa-toggle-off mr-2"></i>
                        Desactivar
                    </span>
                    Administrador
                </span>
                <span class="dropdown-item py-2 clickable" *ngFor="let item of arraysearchMarca" (click)="selectedMarcaSesion(item.id_marca, item.nombre)">
                    {{item.nombre}}
                </span>
                <span class="dropdown-item py-2 text-center text-red" *ngIf="showAlertSearch" style="display:inline-block;">
                    <i class="fas fa-engine-warning mr-2"></i>
                    Sin resultado
                </span>
            </div>
        </li>
        <!-- Alerts Dropdown-->
        <li class="nav-item dropdown no-caret d-none d-sm-block mr-3 dropdown-notifications">
            <a class="btn btn-icon btn-transparent-dark dropdown-toggle" id="navbarDropdownAlerts" href="javascript:void(0);" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="far fa-bell fa-lg"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up" aria-labelledby="navbarDropdownAlerts">
                <h6 class="dropdown-header dropdown-notifications-header bg-black">
                    <i class="mr-2 fas fa-bell"></i>
                    Centro de notificaciones
                </h6>
                <!-- Example Alert 1-->
                <div class="dropdown-item dropdown-notifications-item" *ngFor="let item of notificaciones" (click)="loadNotificacion(item)">
                    <div class="dropdown-notifications-item-icon bg-{{item.tipo_notificacion}} text-white">
                        <i class="fa fa-engine-warning" *ngIf="item.tipo_notificacion == 'warning'"></i>
                        <i class="fa fa-check-circle" *ngIf="item.tipo_notificacion == 'success'"></i>
                        <i class="fa fa-info-circle" *ngIf="item.tipo_notificacion == 'info'"></i>
                        <i class="fa fa-exclamation-triangle" *ngIf="item.tipo_notificacion == 'error'"></i>
                    </div>
                    <div class="dropdown-notifications-item-content">
                        <div class="dropdown-notifications-item-content-details">{{item.fecha_registro | date:'fullDate'}}</div>
                        <div class="dropdown-notifications-item-content-text">{{item.cuerpo}}</div>
                    </div>
                </div>
                <a class="dropdown-item dropdown-notifications-footer" href="#!">Ver más</a>
            </div>
        </li>
        <div class="no-caret d-none d-sm-block mr-3 font-weight-500">{{usuarioSesion.nombre + ' ' + usuarioSesion.apellido_paterno}}</div>
        <!-- User Dropdown-->
        <li class="nav-item dropdown no-caret mr-3 mr-lg-0 dropdown-user">
            <div class="avatar avatar-online avatar-lg mr-1 dropdown-toggle" id="navbarDropdownUserImage" href="javascript:void(0);" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img class="avatar-img img-fluid" src="{{url_imagen}}">
            </div>
            <div class="dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up" aria-labelledby="navbarDropdownUserImage">
                <h6 class="dropdown-header d-flex align-items-center">
                    <img class="dropdown-user-img" src="{{url_imagen}}" />
                    <div class="dropdown-user-details">
                        <div class="dropdown-user-details-name">{{usuarioSesion.nombre + ' ' + usuarioSesion.apellido_paterno}}</div>
                        <div class="dropdown-user-details-email">{{usuarioSesion.email}}</div>
                    </div>
                </h6>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" routerLink="/cuenta">
                    <div class="dropdown-item-icon">
                        <i class="far fa-cogs"></i>
                    </div>
                    Cuenta
                </a>
                <span class="dropdown-item clickable" (click)="cerrarSesion()">
                    <div class="dropdown-item-icon">
                        <i class="far fa-sign-out-alt"></i>
                    </div>
                    Cerrar Sesión
                </span>
            </div>
        </li>
        
    </ul>
</nav>
<div id="layoutSidenav">
    <div id="layoutSidenav_nav">
        <nav class="sidenav shadow-right sidenav-light">
            <div class="sidenav-menu">
                <div class="nav accordion" id="accordionSidenav">
                    <!-- Sidenav Menu Heading (Account)-->
                    <!-- * * Note: * * Visible only on and above the sm breakpoint-->
                    <div class="sidenav-menu-heading d-sm-none">Cuenta</div>
                    <!-- Sidenav Link (Alerts)-->
                    <!-- * * Note: * * Visible only on and above the sm breakpoint-->
                    <a class="nav-link d-sm-none" href="javascript:void(0);" (click)="openNotifications()">
                        <div class="nav-link-icon">
                            <i class="far fa-bell"></i>
                        </div>
                        Notificaciones
                        <span class="badge badge-warning-soft text-warning ml-auto" *ngIf="cantidadNotificar > 0">¡{{cantidadNotificar}} Nuevas!</span>
                    </a>
                    <!-- Sidenav Link (Messages)-->
                    <!-- Sidenav Menu Heading (Core)-->
                    <div class="sidenav-menu-heading">Núcleo</div>
                    <!-- Sidenav Accordion (Dashboard)-->
                    <a [ngClass]="(NavTitleGlobal == 'nucleo') ? 'active' : 'collapsed'" class="nav-link" href="javascript:void(0);" data-toggle="collapse" data-target="#collapseDashboards" aria-expanded="false" aria-controls="collapseDashboards">
                        <div class="nav-link-icon">
                            <i class="far fa-analytics"></i>
                        </div>
                        Principal
                        <div class="sidenav-collapse-arrow">
                            <i class="fas fa-angle-down"></i>
                        </div>
                    </a>
                    <div routerLinkActive="show" class="collapse" id="collapseDashboards" data-parent="#accordionSidenav">
                        <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPages">
                            <a routerLinkActive="active" class="nav-link" routerLink="/principal/agenda">Toma de Hora</a>
                        </nav>
                    </div>
                    <!-- Sidenav Heading (UI Toolkit)-->
                    <div class="sidenav-menu-heading" *ngIf="perfilUsuario != 1 && perfilUsuario != 3">Administración</div>
                    <!-- Sidenav Accordion (Layout)-->
                    <a [ngClass]="(NavTitleGlobal == 'administracion') ? 'active' : 'collapsed'" class="nav-link" href="javascript:void(0);" data-toggle="collapse" data-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts" *ngIf="perfilUsuario != 1 && perfilUsuario != 3">
                        <div class="nav-link-icon">
                            <i class="far fa-users-cog"></i>
                        </div>
                        Gestión Marcas
                        <div class="sidenav-collapse-arrow">
                            <i class="fas fa-angle-down"></i>
                        </div>
                    </a>
                    <div routerLinkActive="show" class="collapse" id="collapseLayouts" data-parent="#accordionSidenav">
                        <nav class="sidenav-menu-nested nav">
                            <a routerLinkActive="active" class="nav-link" *ngIf="perfilUsuario != 1 && perfilUsuario != 3" routerLink="/marcas/agregar">Agregar Marca</a>
                            <a routerLinkActive="active" class="nav-link" *ngIf="perfilUsuario != 1 && perfilUsuario != 3" routerLink="/usuarios/agregar">Agregar Usuario</a>
                            <a routerLinkActive="active" class="nav-link" *ngIf="perfilUsuario != 1 && perfilUsuario != 3" routerLink="/listar">Administrar</a>
                        </nav>
                    </div>
                    <a routerLinkActive="active" class="nav-link" routerLink="/reporte/login" *ngIf="perfilUsuario != 1 && perfilUsuario != 3">
                        <div class="nav-link-icon">
                            <i class="far fa-table"></i>
                        </div>
                        Historial Login
                    </a>
                    <a routerLinkActive="active" class="nav-link" routerLink="/reporte/descargas" *ngIf="perfilUsuario != 1 && perfilUsuario != 3">
                        <div class="nav-link-icon">
                            <i class="far fa-table"></i>
                        </div>
                        Historial Descargas
                    </a>
                    <!-- Sidenav Heading (Addons)-->
                    <div class="sidenav-menu-heading" *ngIf="perfilUsuario != 1 && perfilUsuario != 3">Gestión Plataforma</div>
                    <a routerLinkActive="active" class="nav-link" routerLink="/gestion/masivo" *ngIf="perfilUsuario != 1 && perfilUsuario != 3">
                        <div class="nav-link-icon">
                            <i class="far fa-upload"></i>
                        </div>
                        Cargas Masivas
                    </a>
                    <a routerLinkActive="active" class="nav-link" routerLink="/gestion/configuracion" *ngIf="perfilUsuario >= 4">
                        <div class="nav-link-icon">
                            <i class="far fa-tools"></i>
                        </div>
                        Configuración
                    </a>
                </div>
            </div>
            <!-- Sidenav Footer-->
            <div class="sidenavFooter p-2" align="center">
                <img src="assets/img/logo-circulo.jpg" class="lift" alt="Logo Casa Moda" width="70">
            </div>
        </nav>
    </div>
    <div id="layoutSidenav_content" onclick="layoutSidenav_content()">
        <main>
            <ng-content></ng-content>
        </main>
        <app-footer></app-footer>
    </div>
</div>