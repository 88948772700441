import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { MultiDataSet, Label, Color } from 'ng2-charts';
import { Office } from '../../models/listar.model';
import { DashboardService } from './dashboard.service';
import { CantidadVentas, RankingGeneral, VisitaDay } from '../../models/dashboard.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  loading: boolean = true;
  textTimeControl: string = '2021-01-01';
  rangoFechas: string[] = [];
  rankingGeneral: RankingGeneral = new RankingGeneral();
  productoNoVendidos: string[] = [];
  vistaNoVendidos: boolean = false;
  masVendido: string = 'Sin información';
  menosVendido: string = 'Sin información';
  idMarcaSesion = localStorage.getItem('marca_session');
  perfilUsuario: number = Number(localStorage.getItem('acceso')[0]);
  cantidadVentasSucursal: CantidadVentas[] = [];
  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    layout: {
      padding: {
        left: 10,
        right: 25,
        top: 25,
        bottom: 0
      }
    },
    scales: {
      xAxes: [{
        time: {
          unit: 'month'
        },
        gridLines: {
          display: false,
          drawBorder: false
        },
        ticks: {
          maxTicksLimit: 6
        }
      }],
      yAxes: [{
        ticks: {
          maxTicksLimit: 5,
          padding: 10,
          // Include a dollar sign in the ticks
          callback: function(value, index, values) {
            return '$' + new Intl.NumberFormat('es').format(Number(value));
          }
        },
        gridLines: {
          color: 'rgb(234, 236, 244)',
          zeroLineColor: 'rgb(234, 236, 244)',
          drawBorder: false,
          borderDash: [2],
          zeroLineBorderDash: [2]
        }
      }]
    },
    tooltips: {
      titleMarginBottom: 10,
      titleFontColor: '#000',
      titleFontSize: 14,
      backgroundColor: 'rgb(255,255,255)',
      bodyFontColor: '#858796',
      borderColor: '#dddfeb',
      borderWidth: 1,
      xPadding: 15,
      yPadding: 15,
      displayColors: false,
      caretPadding: 10,
      callbacks: {
        label: function(tooltipItem, chart) {
          var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
          return datasetLabel + ': $' + new Intl.NumberFormat('es').format(Number(tooltipItem.yLabel));
        }
      }
    }
  };
  public barChartLabels: Label[] = [];
  public barChartColors: Color[] = [
    {
      backgroundColor: 'rgba(0, 97, 242, 1)',
      hoverBackgroundColor: 'rgba(0, 97, 242, 0.9)',
      borderColor: '#4e73df'
    }
  ];
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public barChartData: ChartDataSets[] = [
    { maxBarThickness: 25, data: [], label: 'Total' }
  ];

  public sucursales: Office[] = []

  // Doughnut
  public doughnutChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    tooltips: {
      backgroundColor: 'rgb(255,255,255)',
      bodyFontColor: '#858796',
      borderColor: '#dddfeb',
      borderWidth: 1,
      xPadding: 15,
      yPadding: 15,
      displayColors: true,
      caretPadding: 10,
      callbacks: {
        label: function(tooltipItem, chart) {
          const datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
          const datasetValue = chart.datasets[tooltipItem.datasetIndex].data || '';
          
          return '$' + new Intl.NumberFormat('es').format(Number(datasetValue[tooltipItem.index]));
        }
      }
    },
    cutoutPercentage: 80
  };
  public doughnutChartLabels: Label[] = [];
  public doughnutChartColors: Color[] = [
    {
      backgroundColor: [
        'rgba(0, 97, 242, 1)',
        'rgba(0, 172, 105, 1)',
        'rgba(232, 0, 108, 1)',
        'rgb(245, 159, 0, 1)'
      ],
      hoverBackgroundColor: [
        'rgba(0, 97, 242, 0.9)',
        'rgba(0, 172, 105, 0.9)',
        'rgba(232, 0, 108, 0.9)',
        'rgb(245, 159, 0, 0.9)'
      ],
      hoverBorderColor: 'rgba(234, 236, 244, 1)'
    },
  ];
  public doughnutChartData: MultiDataSet = [[]];
  public doughnutChartType: ChartType = 'doughnut';

  visitasDia: VisitaDay[] = [];
  barChartDataAdminMas: ChartDataSets[] = [
    { maxBarThickness: 25, data: [], label: 'Total' }
  ];
  barChartLabelsAdminMas: Label[] = [];
  barChartDataAdminMenos: ChartDataSets[] = [
    { maxBarThickness: 25, data: [], label: 'Total' }
  ];
  barChartLabelsAdminMenos: Label[] = [];

  mesPasadoAlSeleccionado = "";

  constructor(private service: DashboardService, private router: Router) {
    this.loading = true;
    if (this.perfilUsuario === 3) {
      this.router.navigate(['/principal/agenda']);
    }
  }

  ngOnInit(): void {
    this.loading = true;
    if (this.perfilUsuario === 3) {
      this.router.navigate(['/principal/agenda']);
    } else {
      if (this.idMarcaSesion == null) {
        setTimeout(() => {
          this.idMarcaSesion = localStorage.getItem('marca_session');
          this.loadAllData();
        }, 1000);
      } else {
        this.loadAllData();
      }
    }
  }

  loadAllData() {
    this.loadSucursales();
    this.loadFechasInput();
    if (this.idMarcaSesion != '0' && this.idMarcaSesion != null) {
      this.loadDataInicial();
    } 
    if (this.perfilUsuario >= 2) {
      this.loadDataAdministrator();
    }
  }

  loadSucursales() {
    const tempSucursales: Office[] = JSON.parse(localStorage.getItem('sucursales'));
    if (tempSucursales) {
      this.sucursales = tempSucursales.filter(scs => scs.id != 4);
    } else {
      setTimeout(() => {
        this.loadSucursales();
      }, 500);
    }
  }

  loadFechasInput() {
    let hoy = new Date()
    hoy = new Date(hoy.getFullYear(), hoy.getMonth(), 1);
    this.updatePerido(hoy.toString());
    this.rangoFechas.push(hoy.toString());
    for (let i = 0; i < 4; i++) {
      hoy.setMonth(hoy.getMonth() - 1)
      this.rangoFechas.push(hoy.toString());
    }
  }

  loadDataInicial() {
    this.loading = true;
    this.service.variacionVentasTotales(this.idMarcaSesion).subscribe(resp => {
      const titulos: Label[] = [];
      const valores: ChartDataSets[] = [{ maxBarThickness: 25, data: [], label: 'Total'}];

      resp.forEach(item => {
        titulos.push(item.periodo);
        valores[0].data.push(item.monto);
      });

      this.barChartData = valores;
      this.barChartLabels = titulos;
      this.loading = false;
    });
  }

  loadDataPeriodo() {
    this.loading = true;
    this.service.totalVentaSucursal(this.idMarcaSesion, this.textTimeControl).subscribe(resp => {
      const titulos: Label[] = [];
      const valores: MultiDataSet = [[]];

      resp.forEach(item => {
        titulos.push(item.sucursal);
        valores[0].push(item.monto);
      });

      this.doughnutChartLabels = titulos;
      this.doughnutChartData = valores;
    });
    this.service.rankingGeneral(this.idMarcaSesion, this.textTimeControl).subscribe(resp => {
      this.rankingGeneral = resp;
      if (resp.mas.length > 0) {        
        this.masVendido = resp.mas[0].producto;
      }

      if (resp.menos.length > 0) {        
        this.menosVendido = resp.menos[0].producto;
      }
    });
    this.service.cantidadVentasSucursal(this.idMarcaSesion, this.textTimeControl).subscribe(resp => {
      this.cantidadVentasSucursal = resp;
      this.loading = false;
    });
    this.service.productosNoVendidos(this.textTimeControl, this.idMarcaSesion).subscribe(resp => {
      if (resp.length > 0) {
        this.productoNoVendidos = resp.splice(0, 10);
      }
    });
  }

  updatePerido(fecha: string) {
    const temp: Date = new Date(fecha);
    const hoy: Date = new Date();
    this.mesPasadoAlSeleccionado = temp.getFullYear()+'-'+temp.getMonth()+'-01';

    this.textTimeControl = temp.getFullYear()+'-'+(temp.getMonth() < 10 ? '0'+(temp.getMonth()+1) : (temp.getMonth()+1))+'-'+hoy.getDate();    
    
    if (this.idMarcaSesion != '0') {
      this.loadDataPeriodo();
    } else {
      this.loadDataAdministrator()
    }
  }

  loadDataAdministrator() {
    this.loading = true;
    this.service.getVisitasDay().subscribe(resp => {
      this.visitasDia = resp.splice(0, 5);
      this.service.getTopVentasMarcas(this.textTimeControl).subscribe(resp => {
        let titulos: Label[] = [];
        let valores: ChartDataSets[] = [{ maxBarThickness: 25, data: [], label: 'Total'}];

        resp.mayor.forEach(item => {
          titulos.push(item.marca);
          valores[0].data.push(item.monto);
        });

        this.barChartDataAdminMas = valores;
        this.barChartLabelsAdminMas = titulos;

        titulos = [];
        valores = [{ maxBarThickness: 25, data: [], label: 'Total'}];

        resp.menor.forEach(item => {
          titulos.push(item.marca);
          valores[0].data.push(item.monto);
        });

        this.barChartDataAdminMenos = valores;
        this.barChartLabelsAdminMenos = titulos;
        this.loading = false;
      });
    });
  }

  filtrarAgendaDia(id: number) {
    this.service.getVisitasDay().subscribe(resp => {
      this.visitasDia = resp;
      if (id > 0) {
        this.visitasDia = this.visitasDia.filter(item => item.id_tipo_visita == id);
      }
    });
  }

  cambiarVista() {
    this.vistaNoVendidos = !this.vistaNoVendidos;
  }
}
