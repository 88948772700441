import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableAgendaFilter'
})
export class TableAgendaFilterPipe implements PipeTransform {

  transform(items: any[], marca: string, tipo: string, fecha: string) {
    if (!items || (!marca && !tipo && !fecha)) {
      return items;
    }

    let rangoUno;
    let rangoDos;
    if (fecha) {
      const rangoFecha = fecha.split(' ');
      rangoUno = new Date(rangoFecha[0]);
      rangoDos = new Date(rangoFecha[1]);
    }
    
    return items.filter(item => {
      if (marca && item.marca.toString().toLowerCase().indexOf(marca.toLowerCase()) === -1) {
        return false;
      }
      if (tipo && item.tipo.toLowerCase().indexOf(tipo.toLowerCase()) === -1) {
        return false;
      }
      if (fecha) {
        const fechaVisita = new Date(item.fecha_visita);
        if (fechaVisita >= rangoUno && fechaVisita <= rangoDos) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    });
  }
}