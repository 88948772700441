import { Component, OnInit } from '@angular/core';

import Swal from 'sweetalert2';
import * as moment from 'moment';

import { Documento } from 'src/app/models/listar.model';
import { ReportesService } from '../reportes.service';
import { GeneralesService } from '../../generales.service';
import { UsuarioSesion } from 'src/app/models/usuario.model';

@Component({
  selector: 'app-ventas-download',
  templateUrl: './ventas-download.component.html',
  styleUrls: ['./ventas-download.component.scss']
})
export class VentasDownloadComponent implements OnInit {

  loading: boolean = true;
  filter: Documento = new Documento();
  ventas: any[] = [];
  rangoInicial: string;
  rangoFinal: string;
  filtroDeFecha: string;
  perfilUsuario: number = Number(localStorage.getItem('acceso')[0]);
  usuario: UsuarioSesion = JSON.parse(localStorage.getItem('usuario'));

  datePickerConfig = {
    firstDayOfWeek: 'mo',
    format: 'YYYY-MM-DD',
    locale: 'es'
  };

  activarSelector: boolean = false;

  constructor(private servicio: ReportesService, private servicioGeneral: GeneralesService) { }

  ngOnInit(): void {
    if (localStorage.getItem('marca_session')) {
      this.cleanFiltros();
      this.loadVentasMarca();
    } else {
      Swal.fire('Seleccione una Marca', 'Debe tener una marca asignada para poder visualizar este módulo.', 'info');
    }
  }

  loadVentasMarca() {
    const idMarca = localStorage.getItem('marca_session');

    if (this.perfilUsuario <= 1 && (idMarca == '0' || idMarca == null)) {
      Swal.fire('¡Sin Marca!', 'No tienes una marca asignada, comunícate con Casa Moda.', 'info');
      this.loading = false;
      return;
    }

    const nombreMarca = localStorage.getItem('marca_name') ?? '';
    const fechaInicial = this.rangoInicial ? moment(this.rangoInicial).format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD');
    const fechaFinal = this.rangoFinal ? moment(this.rangoFinal).format('YYYY-MM-DD') : moment().endOf('month').format('YYYY-MM-DD');
    this.filtroDeFecha = ` en el periodo ${fechaInicial} al ${fechaFinal}`;

    this.servicio.getVentasByMarca(idMarca, nombreMarca, fechaInicial, fechaFinal, this.perfilUsuario).subscribe(resp => {
      this.ventas = resp;
      this.loading = false;
    }, error => {
      console.error("Error en el servicio.", error);
      // Agregar boton para recargar la pagina
      Swal.fire({
        title: 'Error',
        text: 'Hubo un error al cargar los datos.',
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Reintentar',
        cancelButtonText: 'OK',
      }).then((result) => {
        if (result.isConfirmed) {
          this.loadVentasMarca();
        } else {
          this.loading = false;
          Swal.close();
        }
      });
    });
  }

  cleanFiltros() {
    this.filter.sucursal = '';
    this.filter.tipo_de_movimiento = '';
  }

  obtenerDetalleVenta(venta: Documento) {
    venta.showMore = !venta.showMore;
  }

  descargarExcel() {
    const ventasModelExport = [];
    let totalesNeto = 0, totalesIVA = 0, totalesTotal = 0;

    this.ventas.forEach((venta) => {
      totalesNeto = totalesNeto + Number(venta.Precio);
      totalesIVA = totalesIVA + Number(venta.IVA);
      totalesTotal = totalesTotal + Number(venta.Total);

      const ventaMarca = {
        'Tipo de Movimiento': venta.TipoMovimiento,
        Sucursal: venta.Sucursal,
        'Fecha de Venta': venta.FechaVenta,
        'Nombre Marca': venta.Marca,
        SKU: venta.SKU,
        'Nombre Producto': venta.Producto,
        Variante: venta.Variante,
        Precio: Number(venta.Precio),
        Cantidad: Number(venta.Cantidad),
        IVA: Number(venta.IVA),
        'Sub-Total': Number(venta.Total),
        'Método de Pago': '-'
      }
      ventasModelExport.push(ventaMarca);
    });

    ventasModelExport.push(['MONTO TOTAL NETO', totalesNeto]);
    ventasModelExport.push(['MONTO TOTAL IVA', totalesIVA]);
    ventasModelExport.push(['MONTO TOTAL', totalesTotal]);

    const nombreMarca = localStorage.getItem('marca_name');
    this.servicioGeneral.exportAsExcelFile(ventasModelExport, 'ventas_'+(nombreMarca ? nombreMarca : 'casamoda'));
    this.servicio.setReportDownloader(this.usuario.id_usuario ?? 0).subscribe(
      resp => {
        console.log(resp);
      }
    );
  }
}
