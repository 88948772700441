import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralesService } from '../generales.service';
import { Notificacion, UsuarioSesion } from '../../models/usuario.model';
import { environment } from 'src/environments/environment';
import { Marca } from 'src/app/models/marca.model';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {
  modoAdministrador: boolean = false;
  searchMarcaSesion: string;
  arraysearchMarca: Marca[];
  showAlertSearch: boolean = false;
  @Input() NavTitle!: string;
  @Input() NavTitleGlobal!: string;
  usuarioSesion: UsuarioSesion = new UsuarioSesion();
  idMarcaSesion: string = localStorage.getItem('marca_session');
  perfilUsuario: number = Number(localStorage.getItem('acceso')[0]);
  url_imagen = environment.IMG_URL+this.usuarioSesion.marcas[0].url_imagen;
  notificaciones: Notificacion[] = [];
  nombreMarcaSesion: string = 'Cargando...';
  cantidadNotificar: number = 0;
  debouncer: Subject<string> = new Subject();

  constructor(public generalServicio: GeneralesService, private router: Router) {
    this.cargarUsuarioSesion();
  }

  ngOnInit(): void {
    this.generalServicio.loadSucursales();
    this.debouncer
    .pipe(debounceTime(300))
    .subscribe(valor => {
      this.showAlertSearch = true;
      if (valor != '') {
        this.searchMarcaByName(valor);
      }
      this.showAlertSearch = false;
    });
  }

  cargarUsuarioSesion() {
    const usertoken = localStorage.getItem('token');
    if (usertoken == null || usertoken == 'null') {
      this.router.navigate(['/']);
    } else {
      const usuario = localStorage.getItem('usuario');
      
      if (!usuario) {
        this.generalServicio.loadUsuarioSesion(usertoken).subscribe(resp => {
          if (resp.id_perfil == 1 && resp.marcas.length == 0) {
            localStorage.setItem('marca_name', 'NA');
          }
          if (resp.marcas.length == 0) {
            const marca: Marca = new Marca();
            resp.marcas = [marca];
          } else {
            if (resp.marcas[0].nombre) {
              localStorage.setItem('marca_name', resp.marcas[0].nombre);
              this.arraysearchMarca = resp.marcas;
            }
          }
          const marca_session: string = resp.marcas[0].id_marca ? resp.marcas[0].id_marca.toString() : "0";
          localStorage.setItem('marca_session', marca_session);
          localStorage.setItem('usuario', JSON.stringify(resp));
          this.usuarioSesion = resp;
        },
        () => {
          localStorage.clear();
          this.router.navigate(['/']);
        },
        () => {
          this.loadMarcaSesion();
          this.showNotificacion();
        });
      } else {
        this.usuarioSesion = JSON.parse(usuario);
        if (this.usuarioSesion.marcas.length == 0) {
          const marca: Marca = new Marca();
          this.usuarioSesion.marcas = [marca];
        } else {
          this.arraysearchMarca = this.usuarioSesion.marcas.filter(e => e.nombre);
        }
        this.loadMarcaSesion();
        this.showNotificacion();
      }
      this.url_imagen = environment.IMG_URL+this.usuarioSesion.marcas[0].url_imagen;
    }
    this.idMarcaSesion = localStorage.getItem('marca_session');
    if (this.perfilUsuario >= 2 && (this.idMarcaSesion == '0' || this.idMarcaSesion == null)) {
      this.modeAdministrator();
    }
  }

  cerrarSesion() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/']);
    location.reload();
  }
  
  selectedMarcaSesion(id: string, marca: string) {
    if (id == '0' || marca == '') {
      return;
    }
    this.searchMarcaSesion = '';
    this.nombreMarcaSesion = marca;
    localStorage.setItem('marca_name', marca);
    localStorage.setItem('marca_session', id);
    if (localStorage.getItem('marca_session') == id) {
      this.loadMarcaSesion();
      Swal.fire('¡Listo!', 'Hemos actualizado la marca en sesión.', 'success');
      this.router.navigate(['/']);
    } else {
      Swal.fire('Error', '¡Vuelva a intentarlo más tarde!', 'error');
    }
  }

  loadMarcaSesion() {
    const idMarca = localStorage.getItem('marca_session');
    const marca: Marca = this.usuarioSesion.marcas.find(item => item.id_marca == idMarca);
    if (marca) {
      this.nombreMarcaSesion = marca.nombre;
      this.url_imagen = environment.IMG_URL+marca.url_imagen;
    } else {
      this.nombreMarcaSesion = localStorage.getItem('marca_name');
      if (!this.nombreMarcaSesion && this.perfilUsuario >= 2) {
        this.nombreMarcaSesion = 'Modo Administrador';
      } else {
        if (localStorage.getItem('marca_name')) {
          this.nombreMarcaSesion = localStorage.getItem('marca_name');
        } else {
          this.nombreMarcaSesion = 'Sin Marca';
        }
      }
    }
  }

  showNotificacion() {
    this.generalServicio.getNotificacionUsuario(this.usuarioSesion.id_usuario).subscribe(resp => {
      this.notificaciones = resp;
      if (!resp) {
        return;
      }
      const notificar: Notificacion[] = resp.filter(item => item.activo == 1);
      this.cantidadNotificar = notificar.length;
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });

      if (notificar[0]) {
        this.loadNotificacion(notificar[0]);
      }
      if (notificar[1]) {
        setTimeout(() => {
          this.loadNotificacion(notificar[1]);
        }, 6000);
      }
      if (notificar[2]) {
        setTimeout(() => {
          this.loadNotificacion(notificar[2]);
        }, 12000);
      }
    });
  }

  loadNotificacion(notificacion: Notificacion) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });

    const alert: SweetAlertIcon = notificacion.tipo_notificacion;
    Toast.fire({
      icon: alert,
      title: notificacion.titulo,
      text: notificacion.cuerpo
    });
  }

  openNotifications() {
    document.getElementById('navbarDropdownAlerts').click();
  }

  teclaPresionada() {
    this.debouncer.next(this.searchMarcaSesion);
  }

  searchMarcaByName(nombre: string) {
    this.generalServicio.getMarcaByName(nombre).subscribe(resp => {
      this.arraysearchMarca = resp.splice(0, 3);
      if (this.arraysearchMarca.length == 0) {
        this.showAlertSearch = true;
      }
    });
  }

  modeAdministrator() {
    this.modoAdministrador = !this.modoAdministrador;
    if (this.modoAdministrador) {
      localStorage.removeItem('marca_name');
      localStorage.setItem('marca_session', '0');
      this.nombreMarcaSesion = 'Modo Administrador';
    } else {
      this.nombreMarcaSesion = localStorage.getItem('marca_name');
    }
  }
}
